/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */

import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import moment from 'moment'
import {
   VscCheck,
   VscCircleFilled,
   VscPerson,
   VscRefresh,
   VscTrash,
   VscWarning,
} from 'react-icons/vsc'
import { FcOk } from 'react-icons/fc'
import { useSortBy, useTable } from 'react-table'
import { flattenDeep } from 'lodash'
import { useNavigate } from 'react-router-dom'
import {
   Alert,
   Button,
   Card,
   Col,
   DatePicker,
   Form,
   Input,
   Modal,
   notification,
   PageHeader,
   Radio,
   Row,
   Space,
   Tooltip,
} from 'antd'

import ReactPaginate from 'react-paginate'
import type { RangePickerProps } from 'antd/es/date-picker'
import { fetchUsers, fetchUsersStats } from '../../../api/users-api'
import { Styles } from './styled'
import useDeleteUser from '../../../hooks/users/mutation/useDeleteUser'
import useRestoreUser from '../../../hooks/users/mutation/useRestoreUser'
import useUpdateUser from '../../../hooks/users/mutation/useUpdateUser'
import { LoadingScreen } from '../../../components/LoadingScreenComponent'
import { TableLayout } from '../../../components/TableLayout'
import useResetUser from '../../../hooks/users/mutation/useResetUser'
import useAmbassadressUser from '../../../hooks/users/mutation/useAmbassadressUser'
import useCertifiedUser from '../../../hooks/users/mutation/useCertifiedUser'
import { fetchReports, fetchWarnings } from '../../../api/report'
import { fetchBlocked } from '../../../api/block'

export const UserTable = () => {
   const [tableData, setTableData] = useState([]) as any
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [modalType, setModalType] = useState('')
   const [modalData, setModalData] = useState([]) as any
   const [modalPsuedo, setModalPseudo] = useState('')
   const [loader, setLoader] = useState(false)
   const [searchName, setSearchName] = useState('')
   const [pageNumber, setPageNumber] = useState(1) as any // for searching page
   const [sort, setSorting] = useState('createdAt')
   const [order, setOrder] = useState('desc')
   const [page, setPage] = React.useState(1)
   const [limit, setPageLimit] = React.useState(20)
   const [isDisabled, setIsDisabled] = useState(true)
   const [userProfile, setUserProfile] = useState({}) as any
   const [isConfirmedUsers, setisConfirmedUsers] = useState('')
   const [filter, setFilter] = useState({}) as any
   const [filterCount, setFilterCount] = useState(0)
   // for ban/delete user
   const [deleteUserId, setDeleteUserId] = useState('')
   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
   const [btnLoader, setBtnLoader] = useState(false)

   const deleteUser = useDeleteUser()
   const restoreUser = useRestoreUser()
   const updateUser = useUpdateUser()
   const resetUser = useResetUser()
   const ambassadressUser = useAmbassadressUser()
   const certifiedUser = useCertifiedUser()

   const [fieldDateValue, setFieldDateValue] = useState([]) as any
   const { RangePicker } = DatePicker
   let filterPack: any = {}
   let filterDate: any = {}

   // call users stats api
   const { data: userStatsData } = useQuery(['users-stats'], () => fetchUsersStats(), {
      keepPreviousData: true,
   })

   // modal fucntions - start
   const showModal = async (modType: any, modFilter: any, modPseudo: any) => {
      console.log(modType, modPseudo)
      setModalType(modType)
      if (modType && modPseudo && modPseudo !== '') {
         switch (modType) {
            case 'Reported': {
               const reported = await fetchReports(1, 20, modPseudo, '', modFilter)
               setModalData(reported.data)
               break
            }
            case 'Reporting': {
               const reporting = await fetchReports(1, 20, modPseudo, '', modFilter)
               setModalData(reporting.data)
               break
            }
            case 'Warning': {
               const warning = await fetchWarnings(modFilter)
               setModalData(warning.data)
               break
            }
            case 'Blocked By': {
               const blocked = await fetchBlocked(1, 20, modPseudo)
               setModalData(blocked.data)
               break
            }
            default:
               break
         }
      }
      setModalPseudo(modPseudo)
      setIsModalVisible(true)
      console.log('modal data', modalData)
   }

   const handleOk = () => {
      setIsModalVisible(false)
   }

   const handleCancel = () => {
      setIsModalVisible(false)
   }
   // modal functions - end

   const SuccessNotification = (message: string) => {
      notification.success({ message })
   }

   const handleUpdateUser = async ({ user, action }: { user: string; action: string }) => {
      try {
         console.log('handleUpdate User: ', user, action)
         const response = await updateUser.mutateAsync({ user, data: { action } })
         SuccessNotification(`User selfie ${action}`)
      } catch (err: any) {
         console.log(err.response)
         notification.error({
            message: err?.response?.error || err?.response?.message || err?.response || err.message,
         })
      }
   }

   const handleDeleteModal = async (userId: string) => {
      setDeleteUserId(userId)
      setIsDeleteModalOpen(true)
   }

   const handleDeleteUser = async (userId: string) => {
      try {
         setBtnLoader(true)
         const response = await deleteUser.mutateAsync(userId)
         if (response.data.success) {
            SuccessNotification('User Deleted Successfully')
            setIsDeleteModalOpen(false)
            setBtnLoader(false)
         }
      } catch (err) {
         console.log(err)
      }
   }

   const handleRestoreUser = async (userId: string) => {
      try {
         await restoreUser.mutateAsync(userId)
         SuccessNotification('User Restored Successfully')
      } catch (err) {
         console.log(err)
      }
   }

   const handleResetAttempts = async (id: string) => {
      try {
         await resetUser.mutateAsync({ id })
         SuccessNotification('ID Verification attempts has been reset')
      } catch (e) {
         console.error(e)
      }
   }

   const handleAmbassadressStatus = async (id: string) => {
      try {
         await ambassadressUser.mutateAsync({ id })
         SuccessNotification('User ambassadress status has been successfully updated')
      } catch (e) {
         console.error(e)
      }
   }

   const handleCertifiedStatus = async (id: string) => {
      try {
         await certifiedUser.mutateAsync({ id })
         SuccessNotification('User ambassadress status has been successfully updated')
      } catch (e) {
         console.error(e)
      }
   }

   const handleDisableButton = (attempts: number) => {
      if (attempts !== undefined) {
         return attempts <= 3
      }
      return true
   }

   useEffect(() => {
      if (fieldDateValue && fieldDateValue.length > 0 && fieldDateValue[0] && fieldDateValue[1]) {
         const startDate = `${moment
            .utc(fieldDateValue[0].toDate())
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastDate = `${moment
            .utc(fieldDateValue[1].toDate())
            .format('yyyy-MM-DD')}T23:59:59.999Z`
         filterPack = {
            ...filter,
            createdAt: {
               $gte: moment.utc(startDate),
               $lte: moment.utc(lastDate),
            },
         }
      } else {
         delete filter.createdAt
         filterPack = {
            ...filter,
         }
      }
      setFilter(filterPack)
      setPage(1)
   }, [fieldDateValue])

   const returnMomentDateRange = (start: any, finish: any) => {
      return [moment.utc(start, 'DD/MM/yyyy'), moment.utc(finish, 'DD/MM/yyyy')]
   }

   const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
      if (dates && dates[0] && dates[1]) {
         setFieldDateValue(returnMomentDateRange(dates[0], dates[1]))
      } else {
         setFieldDateValue([null, null])
      }
   }

   // table instance start
   const TableInstance = ({ tbData }: any) => {
      const [columns, data] = useMemo(() => {
         const columnsData = [
            {
               Header: 'Pseudo',
               accessor: 'username',
               Cell: (cell: any) => (
                  <div>
                     {cell.row.original.userType === 'Model' ? (
                        <p style={{ color: '#FF1493', fontWeight: 'bolder' }}>
                           {cell.row.original?.username || cell.row.original?.email}
                        </p>
                     ) : (
                        <p style={{ color: '#0000CD', fontWeight: 'bolder' }}>
                           {cell.row.original?.username || cell.row.original?.email}
                        </p>
                     )}
                  </div>
               ),
            },
            {
               Header: 'ID',
               accessor: '_id',
            },
            {
               Header: 'Media ban',
               accessor: 'listOfMediaBans',
               Cell: (cell: any) => (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     <button
                        onClick={() =>
                           showModal(
                              'Medias Ban',
                              cell.row.values.listOfMediaBans,
                              cell.row.values.pseudo
                           )
                        }>
                        {cell.row.values.mediasBanCount}
                     </button>
                  </div>
               ),
            },
            {
               Header: 'Reported',
               accessor: 'num_reported',
               Cell: (cell: any) => (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     <button
                        onClick={() =>
                           showModal(
                              'Reported',
                              'to',
                              cell.row.values.pseudo
                                 ? cell.row.values.pseudo
                                 : cell.row.values.username
                           )
                        }>
                        {cell.row.values.num_reported}
                     </button>
                  </div>
               ),
            },
            {
               Header: 'Blocked',
               accessor: 'num_blocked',
               Cell: (cell: any) => (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     <button
                        onClick={() =>
                           showModal(
                              'Blocked By',
                              cell.row.values._id,
                              cell.row.values.pseudo
                                 ? cell.row.values.pseudo
                                 : cell.row.values.username
                           )
                        }>
                        {cell.row.values.num_blocked}
                     </button>
                  </div>
               ),
            },
            {
               Header: 'Reporting',
               accessor: 'num_reports',
               Cell: (cell: any) => (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     <button
                        onClick={() =>
                           showModal(
                              'Reporting',
                              'from',
                              cell.row.values.pseudo
                                 ? cell.row.values.pseudo
                                 : cell.row.values.username
                           )
                        }>
                        {cell.row.values.num_reports}
                     </button>
                  </div>
               ),
            },
            {
               Header: 'Warning',
               accessor: 'num_warning',
               Cell: (cell: any) => (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     <button
                        onClick={() =>
                           showModal(
                              'Warning',
                              cell.row.values._id,
                              cell.row.values.pseudo
                                 ? cell.row.values.pseudo
                                 : cell.row.values.username
                           )
                        }>
                        {cell.row.values.num_warning}
                     </button>
                  </div>
               ),
            },
            {
               Header: 'Followers',
               accessor: 'num_followers',
               Cell: (cell: any) => (
                  <div
                     style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                     }}>
                     {cell.row.original.activeFollowers > 0 ? cell.row.original.activeFollowers : 0}
                  </div>
               ),
            },
            {
               Header: 'Subscribers',
               accessor: 'num_subscribers',
               Cell: (cell: any) => (
                  <div
                     style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                     }}>
                     {cell.row.original.activeSubscribers > 0
                        ? cell.row.original.activeSubscribers
                        : 0}
                  </div>
               ),
            },
            {
               Header: 'ID Check',
               accessor: 'idCheckDetails.idImageVerified',
               Cell: (cell: any) => (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     {/* check status if verified */}
                     {cell.row.original.idCheckDetails.idImageVerified ? (
                        <FcOk />
                     ) : cell.row.original.userType !== 'Model' ? (
                        <div />
                     ) : (
                        <VscCircleFilled style={{ color: 'grey', fontSize: '24px' }} />
                     )}
                  </div>
               ),
            },
            {
               Header: 'Selfie Check',
               accessor: 'idCheckDetails.verified',
               Cell: (cell: any) => (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     {/* check status if verified */}
                     {cell.row.original.idCheckDetails.verified ? (
                        <FcOk />
                     ) : !cell.row.original?.idCheckDetails?.idImageVerified ? (
                        <div />
                     ) : (
                        <VscCircleFilled style={{ color: 'orange', fontSize: '24px' }} />
                     )}
                  </div>
               ),
            },
            {
               Header: 'Ambassadress Check',
               accessor: 'ambassadress',
               Cell: (cell: any) => (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     {/* check if user is ambassadress */}
                     {cell.row.original.ambassadress ? (
                        <FcOk />
                     ) : cell.row.original.userType !== 'Model' ? (
                        <div />
                     ) : (
                        <VscCircleFilled style={{ color: 'grey', fontSize: '24px' }} />
                     )}
                  </div>
               ),
            },
            {
               Header: 'Certified Check',
               accessor: 'certified',
               Cell: (cell: any) => (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     {/* check if user is certified */}
                     {cell.row.original.certified ? (
                        <FcOk />
                     ) : cell.row.original.userType !== 'Model' ? (
                        <div />
                     ) : (
                        <VscCircleFilled style={{ color: 'grey', fontSize: '24px' }} />
                     )}
                  </div>
               ),
            },
            {
               Header: 'Mails',
               accessor: 'email',
            },
            {
               Header: 'Created at Date',
               accessor: 'createdAt',
               Cell: (cell: any) => {
                  return (
                     <div>
                        <p>{moment.utc(cell.row.original?.createdAt).format('DD/MM/yyyy')}</p>
                     </div>
                  )
               },
            },
            {
               width: 350,
               Header: 'Actions',
               accessor: 'actions',
               Cell: (cell: any) => (
                  <div style={{ minWidth: 150 }}>
                     {!cell.row.values.accountSoftDeleted ? (
                        <Tooltip title="Delete user">
                           <Button
                              onClick={() => handleDeleteModal(cell.row.values._id)}
                              icon={<VscTrash />}
                              style={{ borderStyle: 'none' }}
                           />
                        </Tooltip>
                     ) : (
                        <Tooltip title="Restore user">
                           <Button
                              onClick={() => handleRestoreUser(cell.row.values._id)}
                              icon={<VscRefresh />}
                              style={{ borderStyle: 'none' }}
                           />
                        </Tooltip>
                     )}
                     <Tooltip title="Send warning email">
                        <VscWarning />
                     </Tooltip>
                     <Tooltip title="Reset ID Validation Attempts">
                        <Button
                           onClick={() => handleResetAttempts(cell.row.values._id)}
                           icon={<VscRefresh />}
                           style={{ borderStyle: 'none' }}
                           disabled={handleDisableButton(
                              cell.row.original.idCheckDetails?.verificationAttempts
                           )}
                        />
                     </Tooltip>
                     <Tooltip title="Update Ambassadress Status">
                        <Button
                           onClick={() => handleAmbassadressStatus(cell.row.values._id)}
                           icon={<VscPerson />}
                           style={{ borderStyle: 'none' }}
                           disabled={cell.row.original.userType !== 'Model'}
                        />
                     </Tooltip>
                     <Tooltip title="Update Certified Status">
                        <Button
                           onClick={() => handleCertifiedStatus(cell.row.values._id)}
                           icon={<VscCheck />}
                           style={{ borderStyle: 'none' }}
                           disabled={cell.row.original.userType !== 'Model'}
                        />
                     </Tooltip>
                  </div>
               ),
            },
            {
               // to check if user is soft deleted
               Header: 'Is Deleted',
               accessor: 'accountSoftDeleted',
            },
            // {
            //    // hidden
            //    Header: 'Media Ban Data',
            //    accessor: 'listOfMediaBans',
            // },
            {
               // hidden
               Header: 'Reported Data',
               accessor: 'reported',
            },
            {
               // hidden
               Header: 'Reporting Data',
               accessor: 'reporting',
            },
            {
               // hidden
               Header: 'Warnings Data',
               accessor: 'warnings',
            },
         ]
         return [columnsData, tableData]
      }, [tableData])

      const tableInstance = useTable({
         columns,
         data,
         initialState: {
            hiddenColumns: [
               'accountSoftDeleted',
               'listOfMediaBans',
               'reported',
               'reporting',
               'warnings',
            ],
         },
      })

      return (
         <TableLayout
            {...tableInstance}
            setSortField={setSorting}
            sortField={sort}
            sort={order}
            setSort={setOrder}
            page="user-page"
            className="user-table-container"
         />
      )
   }
   // table instance end

   // call via api folder - start

   const { isLoading, status, isError, error, data, refetch, isPreviousData } = useQuery(
      ['users', page, limit],
      () => fetchUsers(page, limit, searchName, sort, order, filter),
      { keepPreviousData: true }
   )

   useEffect(() => {
      setLoader(true)

      async function refetchData() {
         await refetch()
         setLoader(false)
      }

      refetchData()
   }, [order, sort, filterCount, page, filter])

   const navigate = useNavigate()

   if (error) {
      navigate('/live-medias')
   }

   // api call end

   // effect whenever data is updated
   useEffect(() => {
      const users = flattenDeep(
         data?.data.map((feetyUser: any, index: any) => {
            return {
               ...feetyUser,
               pseudo: feetyUser?.username || feetyUser?.email,
               isDeleted: feetyUser.accountSoftDeleted,
            }
         })
      )

      if (data) setTableData((prev: any) => [...users])
      console.log('useeffect')
      console.log('data: ', data)
   }, [data])

   // for searching
   const handleSubmit = (values: any) => {
      console.log('values of form: ', values)
      setSearchName(values.searchName)
      setLoader(true)

      async function refetchData() {
         await refetch()
         setLoader(false)
      }

      refetchData()
   }

   // for search page
   const handleSubmitPage = (values: any) => {
      setPage(parseInt(values.searchPage, 10))
   }

   // if input for search is empty, trig
   // ger submit
   useEffect(() => {
      if (searchName === '') {
         handleSubmit('')
      }
   }, [searchName])

   // pagination functions - start
   const handlePageClick = async (pgData: any) => {
      setPage(pgData.selected + 1)

      async function refetchData() {
         await refetch()
         setLoader(false)
      }

      refetchData()
   }

   const setConfirmedUsers = async (isConfirmed: any) => {
      setisConfirmedUsers(isConfirmed)
      if (fieldDateValue && fieldDateValue[0] && fieldDateValue[1]) {
         const startDate = `${moment
            .utc(fieldDateValue[0].toDate())
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastDate = `${moment
            .utc(fieldDateValue[1].toDate())
            .format('yyyy-MM-DD')}T23:59:59.999Z`
         filterDate = {
            createdAt: {
               $gte: moment.utc(startDate),
               $lte: moment.utc(lastDate),
            },
         }
      } else {
         delete filterDate.createdAt
      }
      if (isConfirmed === '') {
         setFilter({ ...filterDate })
      } else {
         setFilter({ ...filterDate, confirmed: isConfirmed === 'confirmed' })
      }
      setFilterCount(filterCount + 1)
   }

   const handleFilterModelsLovers = async (typeFilter: any) => {
      if (fieldDateValue && fieldDateValue[0] && fieldDateValue[1]) {
         const startDate = `${moment
            .utc(fieldDateValue[0].toDate())
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastDate = `${moment
            .utc(fieldDateValue[1].toDate())
            .format('yyyy-MM-DD')}T23:59:59.999Z`
         filterDate = {
            createdAt: {
               $gte: moment.utc(startDate),
               $lte: moment.utc(lastDate),
            },
         }
      } else {
         delete filterDate.createdAt
      }
      switch (typeFilter) {
         case 'active-models':
            setFilter({
               ...filterDate,
               userType: 'Model',
               confirmed: true,
               suppress: false,
               accountSoftDeleted: false,
            })
            break
         case 'active-lovers':
            setFilter({
               ...filterDate,
               userType: 'Lover',
               confirmed: true,
               suppress: false,
               accountSoftDeleted: false,
            })
            break
         case 'id-check-users':
            setFilter({ ...filterDate, 'idCheckDetails.verified': true })
            break
         case 'deleted-models':
            setFilter({
               ...filterDate,
               userType: 'Model',
               $or: [{ suppress: true }, { accountSoftDeleted: true }],
            })
            break
         case 'deleted-lovers':
            setFilter({
               ...filterDate,
               userType: 'Lover',
               $or: [{ suppress: true }, { accountSoftDeleted: true }],
            })
            break
         default:
            setFilter({ ...filterDate })
            break
      }
      setPage(1)
      setFilterCount(filterCount + 1)
   }

   return (
      <>
         <div>
            <PageHeader className="site-page-header" title="Users" />

            <div className="user-stats-wrapper">
               <Space size={[8, 16]} wrap align="center">
                  <Card
                     style={{ width: 200, cursor: 'pointer' }}
                     bodyStyle={{ padding: 15 }}
                     onClick={() => handleFilterModelsLovers('active-lovers')}>
                     <h2 style={{ marginBottom: '0', fontWeight: 600 }}>
                        {userStatsData?.totalActiveLover}
                     </h2>
                     <span>Active FeetLovers</span>
                  </Card>
                  <Card
                     style={{ width: 200, cursor: 'pointer' }}
                     bodyStyle={{ padding: 15 }}
                     onClick={() => handleFilterModelsLovers('active-models')}>
                     <h2 style={{ marginBottom: '0', fontWeight: 600 }}>
                        {userStatsData?.totalActiveModel}
                     </h2>
                     <span>Active FeetModels</span>
                  </Card>
                  <Card
                     style={{ width: 200, cursor: 'pointer' }}
                     bodyStyle={{ padding: 15 }}
                     onClick={() => handleFilterModelsLovers('id-check-users')}>
                     <h2 style={{ marginBottom: '0', fontWeight: 600 }}>
                        {userStatsData?.totalVerifiedIdcheck}
                     </h2>
                     <span>Accounts ID Checked</span>
                  </Card>
                  <Card
                     style={{ width: 200, cursor: 'pointer' }}
                     bodyStyle={{ padding: 15 }}
                     onClick={() => handleFilterModelsLovers('deleted-lovers')}>
                     <h2 style={{ marginBottom: '0', fontWeight: 600 }}>
                        {userStatsData?.totalSuppressedLovers}
                     </h2>
                     <span>Deleted FeetLovers</span>
                  </Card>
                  <Card
                     style={{ width: 200, cursor: 'pointer' }}
                     bodyStyle={{ padding: 15 }}
                     onClick={() => handleFilterModelsLovers('deleted-models')}>
                     <h2 style={{ marginBottom: '0', fontWeight: 600 }}>
                        {userStatsData?.totalSuppressedModels}
                     </h2>
                     <span>Deleted FeetModels</span>
                  </Card>
               </Space>
            </div>

            <div
               className="d-flex user-options-container"
               style={{
                  padding: '20px 0',
               }}>
               {/* search div - start */}
               <div>
                  <Row justify="space-around">
                     <Col flex="auto">
                        <Form
                           // onFinish={handleSubmitDate}
                           layout="inline"
                           style={{ marginBottom: '50px' }}
                           className="date-filter-container">
                           <Form.Item
                              label="Filter Dates:"
                              name="filterDate"
                              style={{ marginRight: '10px' }}>
                              <RangePicker
                                 value={fieldDateValue}
                                 ranges={{
                                    Today: [moment(), moment()],
                                    'This Month': [
                                       moment().startOf('month'),
                                       moment().endOf('month'),
                                    ],
                                 }}
                                 onChange={onChange}
                                 allowClear
                              />
                           </Form.Item>
                        </Form>
                     </Col>
                  </Row>
               </div>
               <div>
                  <Form className="search-form" onFinish={handleSubmit} layout="inline">
                     <Form.Item label="Search" name="searchName" style={{ marginRight: '10px' }}>
                        <Input
                           placeholder="search..."
                           value={searchName}
                           onChange={(e) => setSearchName(e.target.value)}
                           allowClear
                        />
                     </Form.Item>
                     <Form.Item>
                        <Button type="primary" htmlType="submit">
                           Search
                        </Button>
                     </Form.Item>
                  </Form>
               </div>
               {/* search div - end */}
               {/* selections and bulk actions container - start */}
               <div
                  className="mod-media-select-actions"
                  style={{
                     marginRight: '10em',
                  }}>
                  <Radio.Group
                     value={isConfirmedUsers}
                     onChange={(e) => setConfirmedUsers(e.target.value)}>
                     <Radio.Button value="">All Users</Radio.Button>
                     <Radio.Button value="confirmed">Confirmed</Radio.Button>
                     <Radio.Button value="unconfirmed">Unconfirmed</Radio.Button>
                  </Radio.Group>
               </div>
            </div>

            {/* overlayed loader div start */}
            {loader && <LoadingScreen />}
            {/* overlayed loader div end */}

            <Styles>
               <TableInstance tableData={tableData} />
            </Styles>

            {/* PAGINATION START */}
            {data && data?.totalPages ? (
               <div
                  className="pagination-container"
                  style={{ display: 'flex', justifyContent: 'center' }}>
                  <Form
                     onFinish={handleSubmitPage}
                     layout="inline"
                     style={{ alignItems: 'center' }}>
                     <Form.Item name="searchPage" style={{ marginRight: '10px', width: 100 }}>
                        <Input
                           placeholder="search page..."
                           value={pageNumber}
                           type="number"
                           max={data.totalPages}
                           min={1}
                           onChange={(e) => setPageNumber(e.target.value)}
                           allowClear
                        />
                     </Form.Item>
                     <Form.Item>
                        <Button type="primary" htmlType="submit">
                           Search Page
                        </Button>
                     </Form.Item>
                  </Form>
                  <ReactPaginate
                     forcePage={page - 1}
                     pageCount={data?.totalPages}
                     breakLabel="..."
                     previousLabel="<<"
                     nextLabel=">>"
                     containerClassName="pagination"
                     previousLinkClassName="pagination__link"
                     nextLinkClassName="pagination__link"
                     disabledClassName="pagination__link--disabled"
                     activeClassName="pagination__link--active"
                     marginPagesDisplayed={1}
                     pageRangeDisplayed={1}
                     onPageChange={handlePageClick}
                  />
               </div>
            ) : (
               ''
            )}
            {/* PAGINATION END */}

            {/* modal component - end */}
            <Modal
               title={<h1>{modalType}</h1>}
               visible={isModalVisible}
               onCancel={handleCancel}
               width={700}
               footer={[
                  <Button key="back" onClick={handleOk}>
                     Close
                  </Button>,
               ]}>
               <div>
                  <h4>
                     <strong>{modalPsuedo}</strong>
                  </h4>
               </div>{' '}
               <br />
               {modalData.length === 0 ? (
                  <h3>No data available</h3>
               ) : (
                  <div>
                     <table className="modalReportTable" style={{ width: '100%' }}>
                        <tr style={{ textAlign: 'left' }}>
                           <th>Date</th>
                           <th>Pseudo</th>
                           {modalType === 'Medias Ban' && <th>Numéro médias</th>}
                           {modalType !== 'Warning' && <th>Type</th>}
                        </tr>
                        {/* MEDIAS DATA START */}
                        {modalType === 'Medias Ban' &&
                           modalData.map((report: any) => (
                              <tr key={report._id}>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {moment(report?.createdAt).format('DD/MM/yyyy')}
                                    </p>
                                 </td>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {report.banBy.username}
                                    </p>
                                 </td>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {Object.prototype.hasOwnProperty.call(report, 'publication')
                                          ? report.publication.fileId
                                          : report.message._id}
                                    </p>
                                 </td>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {Object.prototype.hasOwnProperty.call(report, 'publication')
                                          ? 'publication'
                                          : 'message'}
                                    </p>
                                 </td>
                              </tr>
                           ))}
                        {/* MEDIAS DATA END */}

                        {/* REPORTED DATA START */}
                        {modalType === 'Reported' &&
                           modalData.map((report: any) => (
                              <tr key={report._id}>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {moment(report?.createdAt).format('DD/MM/yyyy')}
                                    </p>
                                 </td>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {report.from?.username}
                                    </p>
                                 </td>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {report.type}
                                    </p>
                                 </td>
                              </tr>
                           ))}
                        {/* REPORTED DATA END */}

                        {/* REPORTING DATA END */}
                        {modalType === 'Reporting' &&
                           modalData.map((report: any) => (
                              <tr key={report._id}>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {moment(report?.createdAt).format('DD/MM/yyyy')}
                                    </p>
                                 </td>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {report.to.username}
                                    </p>
                                 </td>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {report.type}
                                    </p>
                                 </td>
                              </tr>
                           ))}
                        {/* REPORTING DATA END */}

                        {/* WARNING DATA START */}
                        {modalType === 'Warning' &&
                           modalData.map((report: any) => (
                              <tr key={report._id}>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {moment(report?.createdAt).format('DD/MM/yyyy')}
                                    </p>
                                 </td>
                              </tr>
                           ))}
                        {/* WARNING DATA END */}

                        {/* USER BLOCKED START */}
                        {modalType === 'Blocked By' &&
                           modalData.map((block: any) => (
                              <tr key={block._id}>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {moment(block?.createdAt).format('DD/MM/yyyy')}
                                    </p>
                                 </td>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {block.blocker?.username}
                                    </p>
                                 </td>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {block.blocker?.userType}
                                    </p>
                                 </td>
                              </tr>
                           ))}
                        {/* USER BLOCKED END */}
                        {/* USER FOLLOWED START */}
                        {modalType === 'Followers' &&
                           modalData.map((follower: any) => (
                              <tr key={follower._id}>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {moment(follower?.createdAt).format('DD/MM/yyyy')}
                                    </p>
                                 </td>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {follower.followingUser?.username}
                                    </p>
                                 </td>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {follower.followingUser?.userType}
                                    </p>
                                 </td>
                              </tr>
                           ))}
                        {/* USER FOLLOWED END */}
                        {/* USER SUBSCRIBED START */}
                        {modalType === 'Subscribers' &&
                           modalData.map((subscriber: any) => (
                              <tr key={subscriber._id}>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {moment(subscriber?.createdAt).format('DD/MM/yyyy')}
                                    </p>
                                 </td>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {subscriber.subscriber?.username}
                                    </p>
                                 </td>
                                 <td
                                    className="block"
                                    style={{ textAlign: 'left', marginRight: '6px' }}>
                                    <p style={{ textAlign: 'left', marginRight: '15px' }}>
                                       {subscriber.subscriber?.userType}
                                    </p>
                                 </td>
                              </tr>
                           ))}
                     </table>
                  </div>
               )}
            </Modal>
            {/* modal component - end */}

            {/* modal delete media confirmation - start */}
            <Modal
               title="Delete user"
               visible={isDeleteModalOpen}
               onOk={() => setIsDeleteModalOpen(false)}
               onCancel={() => setIsDeleteModalOpen(false)}
               footer={[
                  <Button
                     key="backDelete"
                     type="primary"
                     loading={btnLoader}
                     onClick={() => handleDeleteUser(deleteUserId)}
                     style={{ background: 'green', color: 'white', borderStyle: 'none' }}>
                     Proceed
                  </Button>,
                  <Button
                     key="back"
                     onClick={() => setIsDeleteModalOpen(false)}
                     type="primary"
                     style={{ background: 'red', color: 'white', borderStyle: 'none' }}>
                     Cancel
                  </Button>,
               ]}>
               <Alert
                  message="Warning"
                  description="You are about to delete this user. This will be permanently deleted."
                  type="warning"
                  showIcon
               />
            </Modal>
            {/* modal delete media confirmation - end */}
         </div>
      </>
   )
}
