import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import ReactPlayer from 'react-player'
import { RiPlayFill, RiPauseFill } from 'react-icons/ri'

import { Card, Image, Button } from 'antd'
import { getVideoFile } from '../../api/media'

export type Props = {
   playsinline?: boolean
   light?: true
   onPause?: boolean
   onClick?: boolean
   playing?: boolean
   fsUrl?: any
   controls?: true
   pageType?: string
}

export const VideoPlayer: React.FC<Props> = React.forwardRef((props, ref) => {
   const { playsinline, light, onPause, onClick, playing, fsUrl, controls, pageType, ...rest } =
      props

   const [play, setPlay] = useState(playing)
   const [url, setUrl] = useState('')
   const [loading, setLoading] = useState(false)

   const handleGetVideoFile = async () => {
      const response = await getVideoFile(fsUrl)
      const objectURL = URL.createObjectURL(response)

      setUrl(objectURL)

      console.log('response component', objectURL)
      setUrl(objectURL)
   }

   useEffect(() => {
      const fileUrl = fsUrl.split('/')
      const { length } = fileUrl
      const encryptedFilename = fileUrl[length - 1]
      if (fileUrl.indexOf('storage.googleapis.com') > -1) {
         setUrl(fsUrl)
      } else if (
         pageType &&
         pageType.includes('custom') &&
         fileUrl.indexOf('storage.googleapis.com') < -1
      ) {
         setUrl(`${process.env.REACT_APP_API_URL}/admin/medias/message_media/${encryptedFilename}`)
      } else {
         setUrl(
            `${process.env.REACT_APP_API_URL}/admin/medias/publication_media/${encryptedFilename}`
         )
      }
   }, [fsUrl, pageType])

   return (
      <div style={{ position: 'relative', paddingTop: '56.25%' }}>
         <ReactPlayer
            style={{
               position: 'absolute',
               top: 0,
               left: 0,
               backgroundColor: 'black',

               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
            }}
            controls
            url={url}
            width="100%"
            height="100%"
            onPause={() => setPlay(false)}
            // onClick={() => setPlay((prev) => !prev)}
            playing={play}
            {...rest}
         />
      </div>
   )
})
