import { useMutation, useQueryClient } from 'react-query'
import { axiosInstance } from '../../../utils/axios-base'

export default function useResetUser() {
   const queryClient = useQueryClient()
   return useMutation(
      async ({ id }: { id: string }) => {
         return axiosInstance.put(`/admin/feety-users/validation-attempts/${id}`)
      },
      {
         onSuccess: () => {
            queryClient.invalidateQueries('users')
         },
         onMutate: (user) => user,
         onError: (error, variables, context: any) => {
            console.log(`Rolling back with and ID: ${context?.id}`)
         },
         onSettled: () => {
            queryClient.invalidateQueries('users')
         },
      }
   )
}
