import React from 'react'

import { BaseTable } from '../BaseTable'

export const BusinessPage = () => {
   return (
      <div className="content-wrapper">
         <BaseTable />
      </div>
   )
}
