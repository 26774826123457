/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */

import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { flattenDeep } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { Button, Col, DatePicker, Form, Input, Menu, notification, PageHeader, Row } from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import { Styles } from './styled'
import { LoadingScreen } from '../../../components/LoadingScreenComponent'
import { PurchasesTableInstance } from '../BusinessPage/Feetlover/PurchaseTable'
import useBusiness from '../../../hooks/business/useBusiness'
import { SpentTicklesTableInstance } from '../BusinessPage/Feetlover/SpentTicklesTable'
import { PendingTableInstance } from '../BusinessPage/WalletFeetgirl/PendingTable'
import useRefundPayment from '../../../hooks/business/mutation/useRefundPayment'
import { fetchProductList } from '../../../api/business-api'
import { GainedTableInstance } from '../BusinessPage/WalletFeetgirl/ValidEarningTable'
import { HistoryTableInstance } from '../BusinessPage/BankTransfer/History'
import { RequestTableInstance } from '../BusinessPage/BankTransfer/Request'
import { CustomMediaDispute } from '../BusinessPage/CustomMedia/CustomMediaDispute'
import useTransferRequest from '../../../hooks/business/mutation/useTransferRequest'
import { RefundHistoryTableInstance } from '../BusinessPage/RefundHistory/RefundHistoryTable'
import useFreezeCustomMediaTickles from '../../../hooks/business/mutation/useFreezeCustomMediaTickles'
import useUnFreezeCustomMediaTickles from '../../../hooks/business/mutation/useUnfreezeCustomMediaTickles'

export const BaseTable = () => {
   const [keyQuery, setKeyQuery] = useState('purchases')
   const [productCodes, setProductCode] = useState([]) as any
   const [fieldDateValue, setFieldDateValue] = useState([]) as any
   const [selected, setSelectedTab] = useState('Purchases')

   const {
      data,
      refetch,
      error,
      tableData,
      setTableData,
      filterData,
      setFilterData,
      setLoader,
      loader,
      searchName,
      setSearchName,
      setPage,
      page,
      setPageType,
      pageType,
      setHasFilter,
      setSortField,
      sortField,
      sort,
      setSort,
      totalErrors,
      totals,
      totalRefetch,
      totalDataRows,
      setTotalDataRows,
      totalDataAmount,
      setTotalDataAmount,
      totalDataTickles,
      setTotalDataTickles,
      pageNumber,
      setPageNumber,
   } = useBusiness(keyQuery)
   // call via api folder - start

   const { RangePicker } = DatePicker
   let filterPack: any = {}
   let filterDate: any = {}

   const refundPayment = useRefundPayment()
   const transferRequest = useTransferRequest()
   const freezeCustomMediaTickles = useFreezeCustomMediaTickles()
   const unfreezeCustomMediaTickles = useUnFreezeCustomMediaTickles()
   const navigate = useNavigate()

   if (error) {
      navigate('/')
   }

   const handleSelectedTab = (tab: string) => {
      setSelectedTab(tab)
   }

   const { data: productData } = useQuery(
      ['product-code-filters', Number(100)],
      () => fetchProductList(Number(100)),
      { keepPreviousData: true }
   )

   useEffect(() => {
      const codes = flattenDeep(
         productData?.data.map((product: any, index: any) => {
            return {
               ...product,
            }
         })
      )

      if (productData) setProductCode((prev: any) => [...codes])
   }, [productData])

   useEffect(() => {
      if (totals) {
         if (totals.totalData) setTotalDataRows(totals.totalData)
         else setTotalDataRows(0)
         if (totals.totalAmount) setTotalDataAmount(totals.totalAmount)
         else if (totals.totalAmountEuro) setTotalDataAmount(totals.totalAmountEuro)
         else if (totals.totalAmountWithdrawn) setTotalDataAmount(totals.totalAmountWithdrawn)
         else setTotalDataAmount(0)
         if (totals.totalAmountTickles) setTotalDataTickles(totals.totalAmountTickles)
         else setTotalDataTickles(0)
      }
   }, [totals])
   // api call end

   // effect whenever data is updated
   useEffect(() => {
      const users = flattenDeep(
         data?.data.map((feetyUser: any, index: any) => {
            return {
               ...feetyUser,
               id: feetyUser._id,
               idCustom:
                  feetyUser.message && feetyUser.message.type === 'Custom-Media'
                     ? feetyUser.message._id
                     : null,
            }
         })
      )

      if (data) setTableData((prev: any) => [...users])
   }, [data])

   // for searching
   const handleSubmit = (values: any) => {
      setSearchName(values.searchName)
      setLoader(true)

      async function refetchData() {
         await refetch()
         await totalRefetch()
         setLoader(false)
      }

      refetchData()
   }

   // for search page
   const handleSubmitPage = (values: any) => {
      setPage(parseInt(values.searchPage, 10))
   }

   const handleSubmitDate = () => {
      if (fieldDateValue && fieldDateValue.length > 0 && fieldDateValue[0] && fieldDateValue[1]) {
         const startDate = `${moment
            .utc(fieldDateValue[0].toDate())
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastDate = `${moment
            .utc(fieldDateValue[1].toDate())
            .format('yyyy-MM-DD')}T23:59:59.999Z`
         if (filterData && filterData?.status) {
            filterPack = {
               status: filterData.status,
               createdAt: {
                  $gte: moment.utc(startDate),
                  $lte: moment.utc(lastDate),
               },
            }
         } else if (
            filterData &&
            (filterData?.datePaymentWithdrawn === null || filterData?.datePaymentWithdrawn)
         ) {
            filterPack = {
               datePaymentWithdrawn: filterData.datePaymentWithdrawn,
               createdAt: {
                  $gte: moment.utc(startDate),
                  $lte: moment.utc(lastDate),
               },
            }
         } else {
            filterPack = {
               createdAt: {
                  $gte: moment.utc(startDate),
                  $lte: moment.utc(lastDate),
               },
            }
         }
      } else if (filterData && filterData?.status) {
         filterPack = {
            status: filterData.status,
         }
      } else if (
         filterData &&
         (filterData?.datePaymentWithdrawn === null || filterData?.datePaymentWithdrawn)
      ) {
         filterPack = {
            datePaymentWithdrawn: filterData.datePaymentWithdrawn,
         }
      } else {
         filterPack = {}
      }
      setFilterData(filterPack)
      setPage(1)
   }

   useEffect(() => {
      if (fieldDateValue && fieldDateValue.length > 0 && fieldDateValue[0] && fieldDateValue[1]) {
         const startDate = `${moment
            .utc(fieldDateValue[0].toDate())
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastDate = `${moment
            .utc(fieldDateValue[1].toDate())
            .format('yyyy-MM-DD')}T23:59:59.999Z`
         filterPack = {
            ...filterData,
            createdAt: {
               $gte: moment.utc(startDate),
               $lte: moment.utc(lastDate),
            },
         }
      } else {
         delete filterData.createdAt
         filterPack = {
            ...filterData,
         }
      }
      setFilterData(filterPack)
      setPage(1)
   }, [fieldDateValue])

   // if input for search is empty, trigger submit
   useEffect(() => {
      if (searchName === '') {
         handleSubmit('')
      }
   }, [searchName])

   // pagination functions - start
   const handlePageClick = async (pgData: any) => {
      setPage(pgData.selected + 1)

      async function refetchData() {
         await refetch()
         setLoader(false)
      }

      refetchData()
   }

   useEffect(() => {
      setLoader(true)
      setFieldDateValue(fieldDateValue)
      setPage(1)
      async function refetchData() {
         await refetch()
         setLoader(false)
      }

      refetchData()
   }, [pageType, keyQuery])

   useEffect(() => {
      setLoader(true)

      async function refetchData() {
         await refetch()
         await totalRefetch()
         setLoader(false)
      }

      refetchData()
   }, [sortField, sort, page, filterData])

   const returnMomentDateRange = (start: any, finish: any) => {
      return [moment.utc(start, 'DD/MM/yyyy'), moment.utc(finish, 'DD/MM/yyyy')]
   }

   const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
      if (dates && dates[0] && dates[1]) {
         setFieldDateValue(returnMomentDateRange(dates[0], dates[1]))
      } else {
         setFieldDateValue([null, null])
      }
   }

   const successfulRefundNotification = () => {
      notification.success({
         message: 'Payment refunded',
      })
   }

   const successfulTransferNotification = () => {
      notification.success({
         message: 'Transfer requested successfully',
      })
   }

   const errorNotification = () => {
      notification.error({
         message: 'Error. Something went wrong',
      })
   }

   const handleChangePageType = (value: string) => {
      setTableData([])
      if (fieldDateValue && fieldDateValue[0] && fieldDateValue[1]) {
         const startDate = `${moment
            .utc(fieldDateValue[0].toDate())
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastDate = `${moment
            .utc(fieldDateValue[1].toDate())
            .format('yyyy-MM-DD')}T23:59:59.999Z`
         filterDate = {
            createdAt: {
               $gte: moment.utc(startDate),
               $lte: moment.utc(lastDate),
            },
         }
      } else {
         delete filterDate.createdAt
      }
      let newFilterDate: any = {}
      if (value === 'valid-earnings') {
         const startVDate = `${moment
            .utc(moment('2021-03-01', 'yyyy-MM-DD'))
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastVDate = `${moment.utc(new Date()).format('yyyy-MM-DD')}T23:59:59.999Z`
         if (filterDate && filterDate.length > 0) {
            newFilterDate = filterDate
         } else {
            newFilterDate = {
               createdAt: {
                  $gte: moment.utc(startVDate),
                  $lte: moment.utc(lastVDate),
               },
            }
         }
      }
      switch (value) {
         case 'purchase-list':
            setPageType(['/feet-lover', value])
            setPage(1)
            setFilterData({ ...filterDate })
            setKeyQuery('purchases')
            break
         case 'spent-tickles':
            setPageType(['/feet-lover', value])
            setPage(1)
            setHasFilter(true)
            setFilterData({ ...filterDate })
            setKeyQuery('spentTickles')
            setFieldDateValue(fieldDateValue)
            break
         case 'pending-list':
            setPageType(['/wallet-feet-girl', value])
            setPage(1)
            setKeyQuery('pendingTickles')
            setFilterData({ status: 'pending', ...filterDate })
            break
         case 'valid-earnings':
            setPageType(['/wallet-feet-girl', value])
            setPage(1)
            setKeyQuery('gainedTickles')
            setFilterData({
               status: { $in: ['gain', 'withdrawn'] },
               ...newFilterDate,
            })
            break
         case 'request-list':
            setPageType(['/bank-transfer-request', value])
            setPage(1)
            setKeyQuery('requests')
            setFilterData({ datePaymentWithdrawn: null, ...filterDate })
            break
         case 'history-list':
            setPageType(['/bank-transfer-request', value])
            setPage(1)
            setKeyQuery('history')
            setFilterData({ datePaymentWithdrawn: { $ne: null }, ...filterDate })
            break
         case 'custom-media':
            setPageType(['/custom-media', value])
            setPage(1)
            setFilterData({ type: 'Custom-Media-Image', ...filterDate })
            setKeyQuery('customMedia')
            break
         case 'refund-history':
            setPageType(['/refund-history', value])
            setPage(1)
            setFilterData({ status: 'refunded', ...filterDate })
            setKeyQuery('refundHistory')
            break
         default:
            setPageType(['/feet-lover', '/purchase-list'])
            setPage(1)
            setKeyQuery('purchases')
            setFilterData({ ...filterDate })
            break
      }
   }

   const handleFreezeCustomMedia = async (paymentId: string) => {
      try {
         setLoader(true)
         await freezeCustomMediaTickles.mutateAsync(paymentId)
         await refetch()
         await totalRefetch()
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
      } finally {
         setLoader(false)
      }
   }

   const handleUnFreezeCustomMedia = async (paymentId: string) => {
      try {
         setLoader(true)
         const response = await unfreezeCustomMediaTickles.mutateAsync(paymentId)
         notification.success({
            message: 'Unfroze Custom media',
         })
         await refetch()
         await totalRefetch()
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
      } finally {
         setLoader(false)
      }
   }

   const handleRefundPayment = async (paymentId: string) => {
      try {
         setLoader(true)
         const response = await refundPayment.mutateAsync(paymentId)

         if (response.data) {
            successfulRefundNotification()
            refetch()
            setLoader(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
      }
   }

   const handleTransferRequest = async (paymentId: string) => {
      try {
         setLoader(true)
         const response = await transferRequest.mutateAsync(paymentId)

         if (response.data) {
            successfulTransferNotification()
            refetch()
            setLoader(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
      }
   }

   return (
      <>
         <div>
            <PageHeader className="site-page-header" title={`Business - ${selected}`} />
            {/* search div - start */}
            <div>
               <Row justify="space-around">
                  <Col flex="auto">
                     <Form
                        // onFinish={handleSubmitDate}
                        layout="inline"
                        style={{ marginBottom: '50px' }}
                        className="date-filter-container">
                        <Form.Item
                           label="Filter Dates:"
                           name="filterDate"
                           style={{ marginRight: '10px' }}>
                           <RangePicker
                              value={fieldDateValue}
                              ranges={{
                                 Today: [moment(), moment()],
                                 'This Month': [moment().startOf('month'), moment().endOf('month')],
                              }}
                              onChange={onChange}
                              allowClear
                           />
                        </Form.Item>
                     </Form>
                  </Col>
               </Row>
               <Row justify="space-around">
                  <Col flex="500px">
                     <Form className="search-form" onFinish={handleSubmit} layout="inline">
                        <Form.Item label="Search" name="searchName" style={{ marginRight: '10px' }}>
                           <Input
                              placeholder="search..."
                              value={searchName}
                              onChange={(e) => setSearchName(e.target.value)}
                              allowClear
                           />
                        </Form.Item>
                        <Form.Item>
                           <Button type="primary" htmlType="submit">
                              Search
                           </Button>
                        </Form.Item>
                     </Form>
                  </Col>
                  <Col flex="auto">
                     <div className="wall-menu-container">
                        <Menu
                           mode="horizontal"
                           defaultSelectedKeys={pageType}
                           className="menu-flex-end">
                           <Menu.SubMenu key="/feet-lover" title="Feetlover">
                              <Menu.Item
                                 key="/purchase-list"
                                 onClick={() => {
                                    handleChangePageType('purchase-list')
                                    handleSelectedTab('FeetLover - Purchases')
                                 }}>
                                 Purchases
                              </Menu.Item>
                              <Menu.Item
                                 key="/spent-tickles"
                                 onClick={() => {
                                    handleChangePageType('spent-tickles')
                                    handleSelectedTab('FeetLover - Spent Tickles')
                                 }}>
                                 Spent Tickles
                              </Menu.Item>
                           </Menu.SubMenu>
                           <Menu.SubMenu key="/wallet-feet-girl" title="Wallet FeetGirl">
                              <Menu.Item
                                 key="/pending-list"
                                 onClick={() => {
                                    handleChangePageType('pending-list')
                                    handleSelectedTab('Wallet FeetGirl - Pending')
                                 }}>
                                 Pending
                              </Menu.Item>
                              <Menu.Item
                                 key="/valid-earnings"
                                 onClick={() => {
                                    handleChangePageType('valid-earnings')
                                    handleSelectedTab('Wallet FeetGirl - Validated earnings')
                                 }}>
                                 Validated earnings
                              </Menu.Item>
                           </Menu.SubMenu>
                           <Menu.Item key="/custom-dispute">
                              <button
                                 type="button"
                                 style={{
                                    borderStyle: 'none',
                                    backgroundColor: 'white',
                                    height: '15px',
                                    cursor: 'pointer',
                                 }}
                                 onClick={() => {
                                    handleChangePageType('custom-media')
                                    handleSelectedTab('Custom dispute')
                                 }}
                                 // onClick={() => handleChangeMediaStatus('approved,rejected')}
                              >
                                 Custom dispute
                              </button>
                           </Menu.Item>
                           <Menu.Item key="/refund-history">
                              <button
                                 type="button"
                                 style={{
                                    borderStyle: 'none',
                                    backgroundColor: 'white',
                                    height: '15px',
                                    cursor: 'pointer',
                                 }}
                                 onClick={() => {
                                    handleChangePageType('refund-history')
                                    handleSelectedTab('Refund history')
                                 }}>
                                 Refund history
                              </button>
                           </Menu.Item>
                           <Menu.SubMenu key="/bank-transfer-request" title="Bank transfer request">
                              <Menu.Item
                                 key="/request-list"
                                 onClick={() => {
                                    handleChangePageType('request-list')
                                    handleSelectedTab('Bank transfer request - Request')
                                 }}>
                                 Request
                              </Menu.Item>
                              <Menu.Item
                                 key="/history-list"
                                 onClick={() => {
                                    handleChangePageType('history-list')
                                    handleSelectedTab('Bank transfer request - History')
                                 }}>
                                 History
                              </Menu.Item>
                           </Menu.SubMenu>
                           <Menu.Item key="/bank-account-deleted">
                              <button
                                 type="button"
                                 style={{
                                    borderStyle: 'none',
                                    backgroundColor: 'white',
                                    height: '15px',
                                    cursor: 'pointer',
                                 }}
                                 onClick={() => {
                                    // handleChangeMediaStatus('approved,rejected')
                                    handleSelectedTab('Business account deleted')
                                 }}>
                                 Business account deleted
                              </button>
                           </Menu.Item>
                        </Menu>
                     </div>
                  </Col>
               </Row>
            </div>
            {/* search div - end */}

            {/* overlayed loader div start */}
            {loader && <LoadingScreen />}
            {/* overlayed loader div end */}

            <Styles>
               {pageType.toString().includes('purchase-list') ? (
                  <PurchasesTableInstance
                     tbData={tableData}
                     setFilterData={setFilterData}
                     productCodeFilters={productCodes}
                     handleRefundPayment={handleRefundPayment}
                     setSortField={setSortField}
                     sortField={sortField}
                     sort={sort}
                     setSort={setSort}
                     fieldDateValue={fieldDateValue}
                     totalsRowData={totalDataRows}
                     totalsAmountData={totalDataAmount}
                  />
               ) : null}
               {pageType.toString().includes('spent-tickles') ? (
                  <SpentTicklesTableInstance
                     tbData={tableData}
                     setFilterData={setFilterData}
                     setHasFilter={setHasFilter}
                     setSortField={setSortField}
                     sortField={sortField}
                     sort={sort}
                     handleUnFreezeCustomMedia={handleUnFreezeCustomMedia}
                     handleFreezeCustomMedia={handleFreezeCustomMedia}
                     setSort={setSort}
                     fieldDateValue={fieldDateValue}
                     totalsRowData={totalDataRows}
                     totalsAmountData={totalDataAmount}
                  />
               ) : null}
               {pageType.toString().includes('pending-list') ? (
                  <PendingTableInstance
                     tbData={tableData}
                     setFilterData={setFilterData}
                     setSortField={setSortField}
                     sortField={sortField}
                     sort={sort}
                     setSort={setSort}
                     fieldDateValue={fieldDateValue}
                     totalsRowData={totalDataRows}
                     totalsAmountData={totalDataAmount}
                     totalsTicklesData={totalDataTickles}
                  />
               ) : null}
               {pageType.toString().includes('valid-earnings') ? (
                  <GainedTableInstance
                     tbData={tableData}
                     setFilterData={setFilterData}
                     setSortField={setSortField}
                     sortField={sortField}
                     sort={sort}
                     setSort={setSort}
                     fieldDateValue={fieldDateValue}
                     totalsRowData={totalDataRows}
                     totalsAmountData={totalDataAmount}
                     totalsTicklesData={totalDataTickles}
                  />
               ) : null}
               {pageType.toString().includes('custom-media') ? (
                  <CustomMediaDispute
                     tbData={tableData}
                     setFilterData={setFilterData}
                     fieldDateValue={fieldDateValue}
                  />
               ) : null}
               {pageType.toString().includes('request-list') ? (
                  <RequestTableInstance
                     tbData={tableData}
                     setFilterData={setFilterData}
                     handleTransferRequest={handleTransferRequest}
                     fieldDateValue={fieldDateValue}
                  />
               ) : null}
               {pageType.toString().includes('history-list') ? (
                  <HistoryTableInstance
                     tbData={tableData}
                     setFilterData={setFilterData}
                     handleTransferRequest={handleTransferRequest}
                     fieldDateValue={fieldDateValue}
                     totalsAmountData={totalDataAmount}
                  />
               ) : null}
               {pageType.toString().includes('refund-history') ? (
                  <RefundHistoryTableInstance
                     tbData={tableData}
                     productCodeFilters={productCodes}
                     setFilterData={setFilterData}
                     fieldDateValue={fieldDateValue}
                     setSortField={setSortField}
                     sortField={sortField}
                     sort={sort}
                     setSort={setSort}
                     totalsRowData={totalDataRows}
                     totalsAmountData={totalDataAmount}
                  />
               ) : null}
            </Styles>

            {/* PAGINATION START */}
            {data && data?.totalPages ? (
               <div
                  className="pagination-container"
                  style={{ display: 'flex', justifyContent: 'center' }}>
                  <Form
                     onFinish={handleSubmitPage}
                     layout="inline"
                     style={{ alignItems: 'center' }}>
                     <Form.Item name="searchPage" style={{ marginRight: '10px', width: 100 }}>
                        <Input
                           placeholder="search page..."
                           value={pageNumber}
                           type="number"
                           max={data.totalPages}
                           min={1}
                           onChange={(e) => setPageNumber(e.target.value)}
                           allowClear
                        />
                     </Form.Item>
                     <Form.Item>
                        <Button type="primary" htmlType="submit">
                           Search Page
                        </Button>
                     </Form.Item>
                  </Form>
                  <ReactPaginate
                     pageCount={data?.totalPages}
                     breakLabel="..."
                     previousLabel="<<"
                     nextLabel=">>"
                     containerClassName="pagination"
                     previousLinkClassName="pagination__link"
                     nextLinkClassName="pagination__link"
                     disabledClassName="pagination__link--disabled"
                     activeClassName="pagination__link--active"
                     marginPagesDisplayed={1}
                     pageRangeDisplayed={1}
                     onPageChange={handlePageClick}
                     forcePage={page - 1}
                  />
               </div>
            ) : (
               ''
            )}
            {/* PAGINATION END */}
         </div>
      </>
   )
}
