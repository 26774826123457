import { axiosInstance } from '../utils/axios-base'

export async function fetchBlocked(pageNum = 1, pageLimit = 10, searchName = '') {
   const response = await axiosInstance.get(`/admin/blocks/list`, {
      params: {
         page: pageNum,
         limit: pageLimit,
         q: searchName,
         sortBy: 'createdAt',
         orderBy: 'desc',
      },
   })
   return response.data
}
