import styled from 'styled-components'

export const Styles = styled.div`
   padding: 1rem;

   table {
      border-spacing: 0;
      /* border: 1px solid black; */
      width: 100%;

      tr {
         :last-child {
            td {
               border-bottom: 0;
            }
         }
      }

      th,
      td {
         margin: 0;
         padding: 0.5rem;
         text-align: center;
         /* border-bottom: 1px solid black;
border-right: 1px solid black; */

         :last-child {
            border-right: 0;
         }
      }
   }

   .pagination {
      padding: 0.5rem;
   }

   .listFooter tr {
      background-color: #020a27;
      color: white;
   }

   .listFooter td :first-child {
      margin-bottom: 5px;
   }

   .listFooter td span p {
      border: 1px solid;
      border-radius: 15px;
      background: white;
      width: 100%;
      color: #020a27;
      text-align: center;
   }

   .filterBox {
      border-radius: 15px;
      background-color: #d9f3ff;
   }
`
