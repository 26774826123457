import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Card, Checkbox, Modal, Tag } from 'antd'
import { RiArrowRightLine, RiDeviceRecoverLine, RiVidiconFill } from 'react-icons/ri'
import moment from 'moment'
import { MessageMedia } from '../MessageMedia/MessageMedia'
import { fallBackImageBase64 } from '../../pages/ModeratedMedias/Photos/constant'
import { CustomVideoThumbnail } from '../VideoPlayer/VideoThumbnail'

const { Meta } = Card

interface ImageListProps {
   data: any
   isModalVisible: boolean
   handleOk: () => void
   setOpenCloseModal: Dispatch<SetStateAction<boolean>>
   medias: any
   backgroundColor: string
   fnBulkApprove: (selectedIds: any) => void
   fnSingleApprove: (id: string) => void
   setSelectedMediasArray: Dispatch<SetStateAction<[]>>
   selectedMediasArray: any
   mediaStatus: string
   showVideoModal: (fsUrl: any) => void
   showVideoThumb: boolean
   handleRecoverMedia: (mediaId: string) => void
   showDeleteModal: (id: string, selectedIds: [], type: string) => void
}

const ImageListModal = (props: ImageListProps) => {
   const [isCheckAll, setIsCheckAll] = useState(false)
   const [displayBulkActions, setDisplayBulkActions] = useState(false)
   const [selectMediaId, setSelectMediaId] = useState('')

   const [isSelectAllMedia, setIsSelectAllMedia] = useState(false)
   const [isUnselect, setIsUnselect] = useState(false)
   const [isChecked, setIsChecked] = useState(false)

   const bulkActionsListener = () => {
      if (props.selectedMediasArray.length > 0) {
         setDisplayBulkActions(true)
      } else {
         setDisplayBulkActions(false)
      }
   }

   const checkedMedia = (target: any, mediaId: any) => {
      const arr = props.selectedMediasArray
      if (target.checked) {
         console.log('1')
         arr.push(mediaId)
      } else {
         console.log('2')
         const arrIndex = arr.indexOf(mediaId)
         arr.splice(arrIndex, 1)
      }
      props.setSelectedMediasArray(arr)

      setSelectMediaId(mediaId)
      if (target.checked) {
         setIsChecked(true)
         console.log('aaa')
      } else {
         setIsChecked(false)
         console.log('bbbb')
      }

      bulkActionsListener()
   }

   // select all button
   const selectAllMedia = () => {
      setIsSelectAllMedia(true)
      // eslint-disable-next-line no-underscore-dangle
      let arr = props.medias.map((media: any) => media._id)
      arr = Array.from(new Set(arr))
      props.setSelectedMediasArray(arr)
   }

   // unselect all button
   const unSelectAllMedia = () => {
      setIsUnselect(true)
      setIsCheckAll(false)
      props.setSelectedMediasArray([])
      bulkActionsListener()
   }

   // effect for select all or unselect all
   useEffect(() => {
      // let array = selectedMediasArray
      if (isSelectAllMedia === true) {
         setIsCheckAll(true)
         bulkActionsListener()
         selectAllMedia()
         setIsSelectAllMedia(false)
         console.log('1 effect')
      }
      if (isUnselect === true) {
         unSelectAllMedia()
         setIsUnselect(false)
         console.log('2 effect')
      }

      console.log('array', props.selectedMediasArray)
   }, [props.selectedMediasArray])

   const handleCancel = () => {
      props.setOpenCloseModal(false)
      props.setSelectedMediasArray([])
   }

   return (
      <Modal
         title="Custom media files"
         visible={props.isModalVisible}
         onOk={props.handleOk}
         onCancel={handleCancel}
         style={{ display: 'flex', justifyContent: 'center' }}
         footer={
            displayBulkActions && props.mediaStatus === 'pending'
               ? [
                    <Button
                       key="approve"
                       type="primary"
                       onClick={() => props.fnBulkApprove(props.selectedMediasArray)}
                       style={{ background: 'green', color: 'white', borderStyle: 'none' }}>
                       Approve Selected
                    </Button>,
                    <Button
                       key="reject"
                       onClick={() => props.showDeleteModal('', props.selectedMediasArray, 'all')}
                       type="primary"
                       style={{ background: 'red', color: 'white', borderStyle: 'none' }}>
                       Reject Selected
                    </Button>,
                 ]
               : []
         }>
         <div>
            <div>
               <div
                  style={{
                     display: 'grid',
                     gridAutoFlow: 'column',
                     gridColumnGap: '10px',
                     justifyContent: 'center',
                  }}>
                  <div>
                     {props.data && props.data?.type?.includes('Custom') && (
                        <Meta title={props.data?.from?.username} />
                     )}
                  </div>
                  <div>
                     <RiArrowRightLine
                        style={{
                           position: 'relative',
                           fontSize: '24px',
                        }}
                     />
                  </div>
                  <div>
                     {props.data && props.data?.type?.includes('Custom') && (
                        <Meta title={props.data?.to?.username} />
                     )}
                  </div>
               </div>
               <div>
                  <div
                     style={{
                        display: 'flex',
                        justifyContent: 'center',
                     }}>
                     {props.data && props.data?.type?.includes('Custom') && (
                        <Meta title={moment(props.data?.createdAt).format('DD/MM/yyyy, HH:mm')} />
                     )}
                     <br />
                     <br />
                  </div>
                  <div
                     style={{
                        display: 'grid',
                        gridAutoFlow: 'column',
                        gridColumnGap: '10px',
                        justifyContent: 'center',
                     }}>
                     <div>
                        <Meta title="ID: " />
                     </div>
                     <div>
                        {/* eslint-disable-next-line no-underscore-dangle */}
                        <Meta title={props.data._id} />
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {props.mediaStatus && props.mediaStatus === 'pending' && (
            <div
               className="d-flex"
               style={{
                  padding: '20px 0',
               }}>
               <div
                  className="mod-media-select-actions"
                  style={{
                     marginRight: '10em',
                  }}>
                  <h4>
                     <strong>Select Options</strong>
                  </h4>
                  <Button
                     type="primary"
                     style={{
                        marginRight: '10px',
                     }}
                     onClick={() => selectAllMedia()}>
                     Select All
                  </Button>

                  <Button onClick={() => unSelectAllMedia()}>Unselect All</Button>
               </div>
            </div>
         )}
         <div
            style={{
               display: 'grid',
               gridTemplateColumns: 'auto auto auto auto',
               padding: '10px',
            }}>
            {props.medias &&
               props.medias.length > 0 &&
               props.medias.map((media: any, index: string) => (
                  <div className="moderation-card-wrapper">
                     <Card
                        className="media-card"
                        bordered={false}
                        style={{
                           objectFit: 'cover',
                           width: 250,
                           height: 'auto',
                           verticalAlign: 'middle',
                           margin: '10px 20px',
                           background: props.backgroundColor,
                        }}>
                        {props.mediaStatus === 'pending' && (
                           <Checkbox
                              // eslint-disable-next-line no-underscore-dangle
                              key={media._id}
                              id={`checkbox-${index}`}
                              className="card-checkbox"
                              style={{ float: 'right', transform: 'scale(1.5)' }}
                              // eslint-disable-next-line no-underscore-dangle
                              onClick={(e) => checkedMedia(e.target, media._id)}
                              // eslint-disable-next-line no-underscore-dangle
                              checked={props.selectedMediasArray.includes(media._id)}
                           />
                        )}
                        {media && media?.status === 'pending' && <Tag color="blue">Pending</Tag>}
                        {media && media?.status === 'rejected' && <Tag color="red">Rejected</Tag>}
                        {media && media?.status === 'approved' && <Tag color="green">Approved</Tag>}
                        <div style={{ padding: '10px 0', position: 'relative' }}>
                           {media &&
                              media?.customMediaType &&
                              media?.customMediaType?.includes('video') && (
                                 <div
                                    className="video-container"
                                    onClick={() => props.showVideoModal(media?.content)}
                                    onKeyDown={() => props.showVideoModal(media?.content)}
                                    aria-hidden="true">
                                    <RiVidiconFill
                                       style={{
                                          color: 'white',
                                          fontSize: '30px',
                                          position: 'absolute',
                                          left: '5px',
                                          cursor: 'pointer',
                                       }}
                                    />
                                    {!props.showVideoThumb ? (
                                       <div className="video-loader">video loading...</div>
                                    ) : (
                                       <CustomVideoThumbnail
                                          fsUrl={media?.content}
                                          pageType="custom-media"
                                       />
                                    )}
                                 </div>
                              )}

                           {media && !media?.customMediaType && media?.type?.includes('Image') && (
                              <MessageMedia
                                 fallback={fallBackImageBase64}
                                 content={media?.content}
                              />
                           )}

                           {media &&
                              media?.customMediaType &&
                              media?.customMediaType?.includes('image') && (
                                 <MessageMedia
                                    fallback={fallBackImageBase64}
                                    content={media?.content}
                                 />
                              )}

                           {props.mediaStatus !== 'pending' && (
                              <RiDeviceRecoverLine
                                 // eslint-disable-next-line no-underscore-dangle
                                 onClick={() => props.handleRecoverMedia(media._id)}
                                 style={{
                                    background: '#f2f2f2',
                                    color: '#e5195f',
                                    position: 'absolute',
                                    bottom: '18px',
                                    right: '10px',
                                    fontSize: '26px',
                                    cursor: 'pointer',
                                    padding: '4px',
                                    borderRadius: '2px',
                                 }}
                              />
                           )}
                        </div>
                        {props.mediaStatus === 'pending' && (
                           <div
                              className="mod-media-indiv-actions media-actions"
                              style={{ marginTop: '7px' }}>
                              <Button
                                 type="primary"
                                 // eslint-disable-next-line no-underscore-dangle
                                 onClick={() => props.fnSingleApprove(media._id)}
                                 style={{
                                    marginRight: '15px',
                                    background: 'green',
                                    color: 'white',
                                    borderStyle: 'none',
                                    width: '6em',
                                 }}>
                                 Approve
                              </Button>
                              <Button
                                 // eslint-disable-next-line no-underscore-dangle
                                 onClick={() => props.showDeleteModal(media._id, [], 'one')}
                                 style={{
                                    background: 'red',
                                    color: 'white',
                                    borderStyle: 'none',
                                    float: 'right',
                                    width: '6em',
                                 }}>
                                 Reject
                              </Button>
                           </div>
                        )}
                     </Card>
                  </div>
               ))}
         </div>
      </Modal>
   )
}

export default ImageListModal
