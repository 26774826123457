import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import moment from 'moment/moment'
import { Button, Select, Tooltip } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useFilters, useTable } from 'react-table'
import { TableLayout } from '../../../../../components/TableLayout'

export const PendingTableInstance = ({
   tbData,
   setFilterData,
   setSortField,
   sortField,
   sort,
   setSort,
   fieldDateValue,
   totalsRowData,
   totalsAmountData,
   totalsTicklesData,
}: any) => {
   let filterPack: any = {}
   let packMessageValue: any[] = []
   let packPrivateValue: any[] = []
   let packTypeValue: any[] = []
   const [filterTypeValues, setFilterTypeValues] = useState([]) as any
   const [checked, setChecked] = useState(true)

   const optionCodeValues: any[] = [
      { label: 'Abonnement', value: 'Abonnement' },
      { label: 'Custom', value: 'Custom' },
      { label: 'Tips public wall', value: 'Tips public wall' },
      { label: 'Tips private wall', value: 'Tips private wall' },
      { label: 'Tips messagerie', value: 'Tips messagerie' },
   ]

   const onChange = (checkedValues: CheckboxValueType[], type: string) => {
      setChecked(false)
      setFilterTypeValues([...checkedValues])
      if (checkedValues.length > 0) {
         if (type === 'type') {
            checkedValues.map((item) => {
               if (item.toString().includes('private')) {
                  packPrivateValue.push(true)
               } else if (item.toString().includes('public')) {
                  packPrivateValue.push(false)
               } else if (item.toString().includes('messagerie')) {
                  packMessageValue.push('Tickles')
               } else if (item.toString().includes('Custom')) {
                  packMessageValue.push('Custom-Media')
               } else if (item.toString().includes('Abonnement')) {
                  packTypeValue.push('subscriptions')
               }
               return null
            })

            filterPack = {
               $or: [
                  { 'message.type': { $in: [...packMessageValue] } },
                  { 'publication.private': { $in: [...packPrivateValue] } },
                  { type: { $in: [...packTypeValue] } },
               ],
               status: { $in: 'pending' },
            }
         }
      } else {
         filterPack = { status: { $in: 'pending' } }
         packPrivateValue = []
         packMessageValue = []
         packTypeValue = []
         setChecked(true)
         setFilterTypeValues([])
      }

      if (fieldDateValue && fieldDateValue[0] && fieldDateValue[1]) {
         const startDate = `${moment
            .utc(fieldDateValue[0].toDate())
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastDate = `${moment
            .utc(fieldDateValue[1].toDate())
            .format('yyyy-MM-DD')}T23:59:59.999Z`
         filterPack.createdAt = {
            $gte: moment.utc(startDate),
            $lte: moment.utc(lastDate),
         }
      } else {
         delete filterPack.createdAt
      }

      setFilterData(filterPack)
   }

   const handleClear = () => {
      filterPack = { status: { $in: 'pending' } }
      packMessageValue = []
      if (fieldDateValue && fieldDateValue[0] && fieldDateValue[1]) {
         const startDate = `${moment
            .utc(fieldDateValue[0].toDate())
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastDate = `${moment
            .utc(fieldDateValue[1].toDate())
            .format('yyyy-MM-DD')}T23:59:59.999Z`
         filterPack.createdAt = {
            $gte: moment.utc(startDate),
            $lte: moment.utc(lastDate),
         }
      } else {
         delete filterPack.createdAt
      }
      setFilterData(filterPack)
      setChecked(true)
      setFilterTypeValues([])
   }

   const handleTypeOfSell = (rowData: any) => {
      let results = ''
      switch (rowData.type) {
         case 'tips chat':
            results = 'Tips messagerie'
            break
         case 'tips custom media':
            results = rowData.message.type
            break
         case 'tips public wall':
            if (rowData.publication.type.includes('image')) results = 'Tips public photo'
            else if (rowData.publication.type.includes('video')) results = 'Tips public video'
            else results = ''
            break
         case 'tips private wall':
            if (rowData.publication.type.includes('image')) results = 'Tips private photo'
            else if (rowData.publication.type.includes('video')) results = 'Tips private video'
            else results = ''
            break
         case 'subscriptions':
            results = 'Abonnement'
            break
         case 'tickles':
            results = 'Tickles'
            break
         default:
            break
      }
      return results
   }
   const handleCalculations = (data: any) => {
      const total =
         data.original?.type && data.original?.type.includes('subscriptions')
            ? (data.original?.amount &&
                 Number(
                    data.original?.amount.toString()
                    //   .slice(0, data.original?.amount.toString().indexOf('.') + 3)
                 )) ||
              0.0
            : (data.original?.tickles &&
                 Number(
                    (data.original?.tickles * 0.00656).toString()
                    //   .slice(0, (data.original?.tickles * 0.00656).toString().indexOf('.') + 3)
                 )) ||
              0.0

      return Number(total.toString())
   }
   const [columns, data, totalsRow, totalAmount, totalTickles] = useMemo(() => {
      const columnsData = [
         {
            Header: 'Pseudo Feetgirl',
            accessor: 'feetModel.username',
            Footer: (column: any) => (
               <span>
                  <span style={{ fontSize: '12px', display: 'flex' }}>Total</span>
               </span>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     <p style={{ color: '#FF1493', fontWeight: 'bolder' }}>
                        {cell.row.original?.feetModel?.username}
                     </p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Mail Feetgirl',
            accessor: 'feetModel.email',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.feetModel?.email}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Pseudo Feetlover',
            accessor: 'feetLover.username',
            Footer: (column: any) => (
               <div>
                  <span>
                     <br />
                     <p>
                        {
                           column.rows.filter(
                              (item: any) =>
                                 (item.original?.feetLover?.username &&
                                    item.original?.feetLover?.username !== '') ||
                                 (item.original?.feetLover?.email &&
                                    item.original?.feetLover?.email !== '')
                           ).length
                        }
                     </p>
                  </span>
                  <span>
                     <p>{totalsRow}</p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     <p style={{ color: '#0000CD', fontWeight: 'bolder' }}>
                        {cell.row.original?.feetLover?.username ||
                           cell.row.original?.feetLover?.email}
                     </p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'ID sell',
            accessor: 'idcounter',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.id}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Amount in €',
            accessor: 'tickles',
            Footer: (column: any) => (
               <div>
                  <span>
                     <br />
                     <p>
                        {(
                           Math.round(
                              _.sum(_.map(column.rows, (d) => handleCalculations(d))) * 100
                           ) / 100
                        )
                           .toFixed(2)
                           .toLocaleString()}
                        &euro;
                     </p>
                  </span>
                  <span>
                     <p>
                        {Number((Math.round(totalAmount * 100) / 100).toFixed(2)).toLocaleString()}
                        &euro;
                     </p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>
                        {handleCalculations(cell.row).toFixed(2).toString()}
                        &euro;
                     </p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Type of sell',
            accessor: 'countertype',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{handleTypeOfSell(cell.row.original)}</p>
                  </div>
               )
            },
            Filter: (filter: any) => {
               return (
                  <>
                     <Select
                        mode="multiple"
                        allowClear
                        style={{
                           width: '100%',
                        }}
                        placeholder="Please select"
                        onChange={(checkedValue) => onChange(checkedValue, 'type')}
                        options={optionCodeValues}
                        defaultValue={filterTypeValues}
                        onClear={() => handleClear()}
                     />
                  </>
               )
            },
         },
         {
            Header: 'ID custom',
            accessor: 'message._id',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.idCustom}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Count 15days subscription',
            accessor: 'counter15days',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{null}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Amount Tickles',
            accessor: 'counter.tickles',
            Footer: (column: any) => (
               <div>
                  <span>
                     <br />
                     <p>
                        {_.sum(_.map(column.rows, (d) => d.original?.tickles || 0))
                           .toLocaleString()
                           .replaceAll(',', "'")}
                     </p>
                  </span>
                  <span>
                     <p>{Number(totalTickles.toFixed(2)).toLocaleString().replaceAll(',', "'")}</p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.tickles || 0}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Date of sell',
            accessor: 'createdAt',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.createdAt).format('DD/MM/yyyy')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Time of sell',
            accessor: 'timeofsell',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.createdAt).format('HH:mm:ss')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Status custom sent/not sent',
            accessor: 'statuscounter',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{null}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Count validated earnings',
            accessor: 'validatedearningscounter',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{null}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
      ]
      return [columnsData, tbData, totalsRowData, totalsAmountData, totalsTicklesData]
   }, [tbData, totalsRowData, totalsAmountData, totalsTicklesData])

   const tableInstance = useTable(
      {
         columns,
         data,
         initialState: {
            hiddenColumns: [
               'accountSoftDeleted',
               'listOfMediaBans',
               'reported',
               'reporting',
               'warnings',
            ],
         },
      },
      useFilters
   )

   return (
      <TableLayout
         {...tableInstance}
         setSortField={setSortField}
         sortField={sortField}
         sort={sort}
         setSort={setSort}
      />
   )
}
