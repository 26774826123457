import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import moment from 'moment/moment'
import { Checkbox, Select } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useFilters, useSortBy, useTable } from 'react-table'
import { TableLayout } from '../../../../../components/TableLayout'

export const RefundHistoryTableInstance = ({
   tbData,
   productCodeFilters,
   setFilterData,
   fieldDateValue,
   setSortField,
   sortField,
   sort,
   setSort,
   totalsRowData,
   totalsAmountData,
}: any) => {
   let filterPack: any = {}
   const [checkCodeState, setCheckCodeState] = useState<CheckboxValueType[]>([]) as any
   const [filterCodeValues, setFilterCodeValues] = useState<CheckboxValueType[]>([]) as any
   const [optionCodeValues, setOptionCodeValues] = useState([]) as any
   const [checked, setChecked] = useState(true)

   const handleChangeIDPack = (checkedValues: any) => {
      setChecked(false)
      setCheckCodeState([...checkedValues])

      if (checkedValues.length > 0) {
         filterPack = { 'product.code': { $in: [...checkedValues] }, status: 'refunded' }
      } else filterPack = { status: 'refunded' }

      if (fieldDateValue && fieldDateValue[0] && fieldDateValue[1]) {
         const startDate = `${moment
            .utc(fieldDateValue[0].toDate())
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastDate = `${moment
            .utc(fieldDateValue[1].toDate())
            .format('yyyy-MM-DD')}T23:59:59.999Z`
         filterPack.createdAt = {
            $gte: moment.utc(startDate),
            $lte: moment.utc(lastDate),
         }
      } else {
         delete filterPack.createdAt
      }

      setFilterData(filterPack)
   }

   const handleClear = () => {
      filterPack = { status: 'refunded' }

      if (fieldDateValue && fieldDateValue[0] && fieldDateValue[1]) {
         const startDate = `${moment
            .utc(fieldDateValue[0].toDate())
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastDate = `${moment
            .utc(fieldDateValue[1].toDate())
            .format('yyyy-MM-DD')}T23:59:59.999Z`
         filterPack.createdAt = {
            $gte: moment.utc(startDate),
            $lte: moment.utc(lastDate),
         }
      } else {
         delete filterPack.createdAt
      }

      setFilterData(filterPack)
      setChecked(true)
      setCheckCodeState([])
   }

   useEffect(() => {
      setFilterCodeValues([...checkCodeState])
   }, [checkCodeState])

   useEffect(() => {
      const option: any[] = []
      productCodeFilters
         .filter((itm: any) => itm?.active === true && itm?.code)
         .map((rowItem: any) => {
            option.push({
               label: rowItem?.code,
               value: rowItem?.code,
            })
            return null
         })
      setOptionCodeValues(option)
   }, [productCodeFilters])

   const [columns, dataTable, totalsRow, totalAmount] = useMemo(() => {
      const columnsData = [
         {
            Header: 'Pseudo Feetlover',
            accessor: 'user.username',
            Footer: (column: any) => (
               <div>
                  <span>
                     <span style={{ fontSize: '12px', display: 'flex' }}>Total</span>
                     <p>
                        {
                           column.rows.filter(
                              (item: any) =>
                                 (item.original?.user?.username &&
                                    item.original?.user?.username !== '') ||
                                 (item.original?.user?.email && item.original?.user?.email !== '')
                           ).length
                        }
                     </p>
                  </span>
                  <span>
                     <p>{totalsRow}</p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     {cell.row.original?.user && cell.row.original?.user?.username ? (
                        <p style={{ color: '#0000CD', fontWeight: 'bolder' }}>
                           {cell.row.original?.user?.username}
                        </p>
                     ) : null}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Mail Feetlover',
            accessor: 'user.email',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.user?.email}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'ID Tickles pack ID Subscription',
            accessor: 'counter.product.code',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.product?.code}</p>
                  </div>
               )
            },
            Filter: (filter: any) => {
               return (
                  <>
                     <Select
                        mode="multiple"
                        allowClear
                        style={{
                           width: '100%',
                        }}
                        placeholder="Please select"
                        onChange={(checkedValue) => handleChangeIDPack(checkedValue)}
                        options={optionCodeValues}
                        defaultValue={filterCodeValues}
                        onClear={() => handleClear()}
                     />
                  </>
               )
            },
         },
         {
            Header: 'Pseudo Feetgirl subscribed to',
            accessor: 'details.model.username',
            Cell: (cell: any) => {
               return (
                  <div>
                     {cell.row.original?.details && cell.row.original?.details.model?.username ? (
                        <p style={{ color: '#FF1493', fontWeight: 'bolder' }}>
                           {cell.row.original?.details.model.username}
                        </p>
                     ) : null}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Amount in €',
            accessor: 'product.amount',
            Footer: (column: any) => (
               <div>
                  <span>
                     <br />
                     <p>
                        {_.sum(_.map(column.rows, (d) => d.original?.product?.amount))
                           .toLocaleString()
                           .replaceAll(',', "'")}
                        &euro;
                     </p>
                  </span>
                  <span>
                     <p>
                        {Number(totalAmount.toFixed(2)).toLocaleString().replaceAll(',', "'")}
                        &euro;
                     </p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.product?.amount} &euro;</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Date of purchase',
            accessor: 'createdAt',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.createdAt).format('DD/MM/yyyy')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Time of purchase',
            accessor: 'timepurchase',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.createdAt).format('HH:mm:ss')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Date refund',
            accessor: 'updatedAt',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.updatedAt).format('DD/MM/yyyy')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Time refund',
            accessor: 'timerefund',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.updatedAt).format('HH:mm:ss')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            // to check if user is soft deleted
            Header: 'Is Deleted',
            accessor: 'accountSoftDeleted',
         },
         {
            // hidden
            Header: 'Media Ban Data',
            accessor: 'listOfMediaBans',
         },
         {
            // hidden
            Header: 'Reported Data',
            accessor: 'reported',
         },
         {
            // hidden
            Header: 'Reporting Data',
            accessor: 'reporting',
         },
         {
            // hidden
            Header: 'Warnings Data',
            accessor: 'warnings',
         },
      ]
      return [columnsData, tbData, totalsRowData, totalsAmountData]
   }, [tbData, totalsRowData, totalsAmountData])

   const tableInstance = useTable(
      {
         columns,
         data: dataTable,
         initialState: {
            hiddenColumns: [
               'accountSoftDeleted',
               'listOfMediaBans',
               'reported',
               'reporting',
               'warnings',
            ],
         },
      },
      useFilters
   )

   return (
      <TableLayout
         {...tableInstance}
         setSortField={setSortField}
         sortField={sortField}
         sort={sort}
         setSort={setSort}
      />
   )
}
