import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import moment from 'moment/moment'
import { Button } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useFilters, useSortBy, useTable } from 'react-table'
import { TableLayout } from '../../../../../components/TableLayout'

export const HistoryTableInstance = ({
   tbData,
   setFilterData,
   handleTransferRequest,
   totalsAmountData,
}: any) => {
   const filterPack: any = {}
   const [filterCodeValues, setFilterCodeValues] = useState<CheckboxValueType[]>([]) as any
   const [filterRefundValues, setFilterRefundValues] = useState([]) as any

   const [columns, dataTable, totalAmount] = useMemo(() => {
      const columnsData = [
         {
            Header: 'Pseudo Feetgirl',
            accessor: 'pseudofeetgirl',
            Cell: (cell: any) => {
               return (
                  <div>
                     {cell.row.original?.user && cell.row.original?.user?.username ? (
                        <p style={{ color: '#FF1493', fontWeight: 'bolder' }}>
                           {cell.row.original?.user?.username}
                        </p>
                     ) : null}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Name Feetgirl',
            accessor: 'namefeetgirl',
            Cell: (cell: any) => {
               return (
                  <div>
                     {cell.row.original?.bankDetails &&
                     cell.row.original?.bankDetails?.firstName ? (
                        <p>{cell.row.original?.bankDetails?.firstName}</p>
                     ) : null}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Surname Feetgirl',
            accessor: 'surnamefeetgirl',
            Cell: (cell: any) => {
               return (
                  <div>
                     {cell.row.original?.bankDetails && cell.row.original?.bankDetails?.lastName ? (
                        <p>{cell.row.original?.bankDetails?.lastName}</p>
                     ) : null}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Country',
            accessor: 'country',
            Cell: (cell: any) => {
               return (
                  <div>
                     {cell.row.original?.user && cell.row.original?.user?.nationality ? (
                        <p>{cell.row.original?.user?.nationality}</p>
                     ) : null}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'IBAN',
            accessor: 'iban',
            Cell: (cell: any) => {
               return (
                  <div>
                     {cell.row.original?.bankDetails && cell.row.original?.bankDetails?.iban ? (
                        <p>{cell.row.original?.bankDetails.iban}</p>
                     ) : (
                        ''
                     )}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'BIC',
            accessor: 'bic',
            Cell: (cell: any) => {
               return (
                  <div>
                     {cell.row.original?.bankDetails &&
                     cell.row.original?.bankDetails?.bic_swift ? (
                        <p>{cell.row.original?.bankDetails?.bic_swift}</p>
                     ) : null}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Amount in €',
            accessor: 'amount',
            Footer: (column: any) => (
               <div>
                  <span>
                     <br />
                     <p>
                        {Number(_.sum(_.map(column.rows, (d) => d.original?.amount)).toFixed(2))
                           .toLocaleString()
                           .replaceAll(',', "'")}
                        &euro;
                     </p>
                  </span>
                  <span>
                     <p>
                        {Number(totalAmount.toFixed(2)).toLocaleString().replaceAll(',', "'")}&euro;
                     </p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     {/* <p>{cell.row.original?.amount} &euro;</p> */}
                     <p>
                        {cell.row.original?.amount
                           ? (cell.row.original?.amount)
                                .toLocaleString('en-US', {
                                   maximumFractionDigits: 2,
                                   minimumFractionDigits: 2,
                                })
                                .replaceAll(',', "'")
                           : 0.0}
                        &euro;
                     </p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Date of request',
            accessor: 'dateofrequest',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.datedRequested).format('DD/MM/yyyy')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Time of request',
            accessor: 'timeofrequest',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.datedRequested).format('HH:mm')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Mail Feetgirl',
            accessor: 'mail.feetgirl',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.user?.email}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Date of Send',
            accessor: 'dateofsend',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>
                        {moment.utc(cell.row.original?.datePaymentWithdrawn).format('DD/MM/yyyy')}
                     </p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Time of Send',
            accessor: 'timeofsend',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.datePaymentWithdrawn).format('HH:mm')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            width: 300,
            Header: 'Action : Canceled',
            accessor: 'actions',
            Cell: (cell: any) => (
               <div>
                  <Button
                     // onClick={() => handleTransferRequest(cell.row.original?.id)}
                     type="default"
                     // disabled={cell.row.original?.product?.code.includes('ABO')}
                     style={{ background: '#A8FFA6' }}
                     shape="round">
                     Canceled
                  </Button>
               </div>
            ),
            disableFilters: true,
         },
         {
            // to check if user is soft deleted
            Header: 'Is Deleted',
            accessor: 'accountSoftDeleted',
         },
         {
            // hidden
            Header: 'Media Ban Data',
            accessor: 'listOfMediaBans',
         },
         {
            // hidden
            Header: 'Reported Data',
            accessor: 'reported',
         },
         {
            // hidden
            Header: 'Reporting Data',
            accessor: 'reporting',
         },
         {
            // hidden
            Header: 'Warnings Data',
            accessor: 'warnings',
         },
      ]
      return [columnsData, tbData, totalsAmountData]
   }, [tbData, totalsAmountData])

   const tableInstance = useTable(
      {
         columns,
         data: dataTable,
         initialState: {
            hiddenColumns: [
               'accountSoftDeleted',
               'listOfMediaBans',
               'reported',
               'reporting',
               'warnings',
            ],
         },
      },
      useSortBy
   )

   return <TableLayout {...tableInstance} />
}
