/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

const ReadMore = ({ children }: any) => {
   const text = children.description || ' '

   const [isReadMore, setIsReadMore] = React.useState(true)

   const toggleReadMore = () => {
      setIsReadMore(!isReadMore)
   }

   return (
      <div>
         <p
            style={{
               display: 'inline',
            }}>
            {isReadMore && text.length > 30 ? `${text.slice(0, 30)}...` : text}
            <span
               onClick={toggleReadMore}
               style={{
                  cursor: 'pointer',
                  fontSize: '18px',
                  color: 'gray',
               }}>
               {isReadMore && text.length > 30 && <span style={{ color: 'black' }}>suite</span>}
               {!isReadMore && text.length > 30 && (
                  <span style={{ color: 'black', marginLeft: '10px' }}>less</span>
               )}
            </span>
         </p>
      </div>
   )
}

export default ReadMore
