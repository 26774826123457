/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
import React, { useMemo, useState, useEffect, useRef, createRef } from 'react'
import { useQuery } from 'react-query'
import ReactPlayer from 'react-player'
import moment from 'moment'
import {
   Card,
   Image,
   Spin,
   Button,
   Input,
   Alert,
   Select,
   Form,
   Menu,
   Modal,
   notification,
   PageHeader,
   Checkbox,
   Row,
   Col,
   Tag,
} from 'antd'
import { RiVidiconFill, RiArrowRightLine } from 'react-icons/ri'
import { Link, Outlet } from 'react-router-dom'
import ReactPaginate from 'react-paginate'

import { fetchLiveMedias } from '../../../api/media'
import { fallBackImageBase64 } from './constant'
import ReadMore from '../../../components/ReadMore'
import useBanMedia from '../../../hooks/media/mutation/useBanMedia'
import useApproveMedia from '../../../hooks/media/mutation/useApproveMedia'
import useApproveAllMedia from '../../../hooks/media/mutation/useApproveAllMedia'
import { VideoPlayer } from '../../../components/VideoPlayer/VideoPlayer'
import { CustomVideoThumbnail } from '../../../components/VideoPlayer/VideoThumbnail'
import { LoadingScreen } from '../../../components/LoadingScreenComponent'

import { LocalStorage } from '../../../services'
import useBanAllMedia from '../../../hooks/media/mutation/useBanAllMedia'
import { ModeratorActions } from '../common/moderator.enum'

const { Meta } = Card
const { Option } = Select

export interface IFormInput {
   searchName: string
   mediaType: string
}

export const ModeratedVideosPage = () => {
   // for useQuery
   const [page, setPage] = React.useState(1)
   const [limit, setPageLimit] = React.useState(10)
   const [searchData, setSearchData] = useState([]) as any
   const [mediaStatus, setMediaStatus] = useState('pending')
   const [mediaFileType, setMediaFileType] = useState('video')
   const [sortOrder, setSortOrder] = useState('') // sort order set to blank for ascending order

   // for search
   const [searchName, setSearchName] = useState('')
   const [pageNumber, setPageNumber] = useState(1) as any // for searching page
   const [mediaType, setMediaType] = useState('all')
   const [loader, setLoader] = useState(false)

   // for modals
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [isVideoModalVisible, setIsVideoModalVisible] = useState(false)
   const [deleteMediaId, setDeleteMediaId] = useState('')
   const [deleteMediaType, setDeleteMediaType] = useState('')
   const [btnLoader, setBtnLoader] = useState(false)
   const [videoFileName, setVideoFileName] = useState('')

   // for video thumbnail
   const [showVideoThumb, setShowVideoThumb] = useState(false)

   // for checkboxes
   const [selectedMediasArray, setSelectedMediasArray] = useState([]) as any
   const [isCheckAll, setIsCheckAll] = useState(false)
   const [isCheck, setIsCheck] = useState([]) as any
   const [displayBulkActions, setDisplayBulkActions] = useState(false)
   const [selectMediaId, setSelectMediaId] = useState('')

   const [isSelectAllMedia, setIsSelectAllMedia] = useState(false)
   const [isUnselect, setIsUnselect] = useState(false)
   const [isChecked, setIsChecked] = useState(false)

   const [backgroundColor, setBackgroundColor] = React.useState('#e9e8e8')

   // for react-player
   const [play, setPlay] = useState(false)

   // approve or reject (ban) media
   const banMedia = useBanMedia()
   const approveMedia = useApproveMedia()
   const approveAllMedia = useApproveAllMedia()
   const banAllMedia = useBanAllMedia()

   const { isLoading, status, isError, error, data, isFetching, isPreviousData, refetch } =
      useQuery(
         ['live-medias', page, limit],
         () =>
            fetchLiveMedias(
               page,
               limit,
               searchName,
               mediaType,
               mediaStatus,
               sortOrder,
               mediaFileType
            ),
         {
            enabled: false,
         }
      )

   useEffect(() => {
      ;(async () => {
         setLoader(true)
         await refetch()
         setLoader(false)
      })()
   }, [page, limit, searchName, mediaType, mediaStatus, sortOrder, mediaFileType])

   // modal fucntions - start
   // confirmation modal
   const showModal = (selectedMediaId: string, selectedMediaType: string) => {
      setDeleteMediaId(selectedMediaId)
      setDeleteMediaType(selectedMediaType)
      setIsModalVisible(true)
   }

   // video modal
   const showVideoModal = (media: any) => {
      const fileUrl =
         media?.url && media?.url.toString().includes('/')
            ? media?.url.split('/')
            : media?.content.split('/')
      if (fileUrl.indexOf('storage.googleapis.com') > -1) {
         setVideoFileName(
            media?.url && media?.url.toString().includes('/') ? media?.url : media?.content
         )
      } else {
         setVideoFileName(media?.fsUrl)
      }
      setPlay(true)
      setIsVideoModalVisible(true)
   }

   const handleChangeMediaType = async (_mediaType: any) => {
      setMediaType(_mediaType)
      setPage(1)
   }

   const handleOk = () => {
      setIsModalVisible(false)
   }

   const handleCancel = () => {
      setIsModalVisible(false)
   }

   const handleCancelVideo = async () => {
      await setPlay(false)
      setVideoFileName('')
      setIsVideoModalVisible(false)
   }
   // modal functions - end

   // notification success approve
   const successNotificationApprove = () => {
      notification.success({
         message: 'Media approved',
      })
   }

   // notification success reject
   const successNotificationReject = () => {
      notification.success({
         message: 'Media rejected',
      })
   }

   // notification error
   const errorNotification = () => {
      notification.error({
         message: 'Error. Something went wrong',
      })
   }

   const handleBanMedia = async (mediaId: string) => {
      try {
         setBtnLoader(true)

         setLoader(true)
         const response = await banMedia.mutateAsync(mediaId)
         console.log('response', response)
         if (response.data === 'success') {
            successNotificationReject()
            refetch()
            setLoader(false)
            setIsModalVisible(false)
            setBtnLoader(false)
         }
      } catch (err) {
         console.error(err)
         // errorNotification()
         successNotificationReject()
         refetch()
         setLoader(false)
         setIsModalVisible(false)
         setBtnLoader(false)
      } finally {
         setLoader(false)
         setIsModalVisible(false)
         setBtnLoader(false)
      }
   }

   const handleApproveMedia = async (mediaId: string) => {
      try {
         setLoader(true)
         const response = await approveMedia.mutateAsync(mediaId)

         console.log('approved media response: ', response)
         if (response.status === 200) {
            successNotificationApprove()
            refetch()
            setLoader(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
      }
   }

   // bulk approval
   const handleApproveAllMedia = async () => {
      try {
         setLoader(true)
         const response = await approveAllMedia.mutateAsync(selectedMediasArray)

         console.log('approved all media response: ', response)
         if (response.status === 200) {
            successNotificationApprove()
            refetch()
            setLoader(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
      }
   }

   // bulk ban - TO DO
   // const handleBanAllMedia = async (mediaIdArray: []) => {}

   const handleSubmit = (values: any) => {
      console.log('values of form: ', values)
      setLoader(true)
      async function refetchData() {
         await refetch()
         setLoader(false)
      }
      refetchData()
   }

   // for search page
   const handleSubmitPage = (values: any) => {
      setPage(parseInt(values.searchPage, 10))
   }

   // if input for search is empty, trigger submit
   useEffect(() => {
      if (searchName === '') {
         handleSubmit('')
      }
   }, [searchName])

   const delayComponentRender = () => {
      setTimeout(() => setShowVideoThumb(true), 2500)
   }

   // if mediatype is changed (private=true or private=false) trigger refetch
   useEffect(() => {
      //    setLoader(true)
      //    async function refetchData() {
      //       await refetch()
      //       setLoader(false)
      //    }
      delayComponentRender()
      //    refetchData()
      //    console.log('mediatype: ', mediaType)
   }, [mediaType])

   // pagination functions - start
   const handlePageClick = async (pgData: any) => {
      setPage(pgData.selected + 1)
      async function refetchData() {
         await refetch()
         setLoader(false)
      }
      refetchData()
   }

   // bulk actions display listener
   const bulkActionsListener = () => {
      if (selectedMediasArray.length > 0) {
         setDisplayBulkActions(true)
      } else {
         setDisplayBulkActions(false)
      }
   }

   // checkbox function - change card bg to blue on check
   const checkedMedia = (target: any, mediaId: any) => {
      const arr = selectedMediasArray
      if (target.checked) {
         console.log('1')
         arr.push(mediaId)
      } else {
         console.log('2')
         const arrIndex = arr.indexOf(mediaId)
         arr.splice(arrIndex, 1)
      }
      setSelectedMediasArray(arr)

      setSelectMediaId(mediaId)
      if (target.checked) {
         setIsChecked(true)
         console.log('aaa')
      } else {
         setIsChecked(false)
         console.log('bbbb')
      }

      bulkActionsListener()
   }

   // select all button
   const selectAllMedia = () => {
      setIsSelectAllMedia(true)
      let arr = data.data.map((media: any) => media._id)
      arr = Array.from(new Set(arr))
      setSelectedMediasArray(arr)
   }

   const unSelectAllMedia = () => {
      setIsUnselect(true)
      setIsCheckAll(false)
      setSelectedMediasArray([])
      bulkActionsListener()
   }

   const handleRejectAllMedia = async () => {
      try {
         setLoader(true)
         const response = await banAllMedia.mutateAsync(selectedMediasArray)
         await setSelectedMediasArray([])

         console.log('approved all media response: ', response)
         if (response.status === 200) {
            successNotificationReject()
            refetch()
            setLoader(false)
            setIsModalVisible(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
         setIsModalVisible(false)
      } finally {
         await unSelectAllMedia()
         setIsModalVisible(false)
      }
   }

   // effect for select all or unselect all
   useEffect(() => {
      // let array = selectedMediasArray
      if (isSelectAllMedia === true) {
         setIsCheckAll(true)
         bulkActionsListener()
         selectAllMedia()
         setIsSelectAllMedia(false)
         console.log('1 effect')
      }
      if (isUnselect === true) {
         unSelectAllMedia()
         setIsUnselect(false)
         console.log('2 effect')
      }

      console.log('array', selectedMediasArray)
   }, [selectedMediasArray])

   const handleCardActions = (media: any) => {
      const cardActions = Object.values(ModeratorActions).map((ctx: string) => {
         return (
            <Button
               onClick={() => {
                  if (ctx.toLowerCase() === 'approve') {
                     handleApproveMedia(media._id)
                  } else {
                     showModal(media._id, 'one')
                  }
               }}
               style={{
                  background: ctx.toLowerCase() === 'reject' ? 'red' : 'green',
                  color: 'white',
                  borderStyle: 'none',
                  width: '100%',
               }}
               key={media._id}>
               {ctx}
            </Button>
         )
      })
      // to remove the switch item
      cardActions.splice(1, 1)
      return cardActions
   }

   return (
      <>
         <div className="content-wrapper">
            <PageHeader className="site-page-header" title="Videos" />
            <Row justify="space-around">
               <Col flex="500px">
                  {/* search div - start */}
                  <Form className="search-form" onFinish={handleSubmit} layout="inline">
                     <Form.Item label="Search" name="searchName" style={{ marginRight: '10px' }}>
                        <Input
                           placeholder="search..."
                           value={searchName}
                           onChange={(e) => setSearchName(e.target.value)}
                           allowClear
                        />
                     </Form.Item>
                     <Form.Item>
                        <Button type="primary" htmlType="submit">
                           Search
                        </Button>
                     </Form.Item>
                  </Form>
                  {/* search div - end */}
               </Col>
               <Col flex="auto">
                  {/* sub menu items - start */}
                  <div className="wall-menu-container">
                     <Menu
                        mode="horizontal"
                        defaultSelectedKeys={['/all-medias']}
                        className="menu-flex-end">
                        <Menu.Item key="/all-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('all')}>
                              All Wall
                           </button>
                        </Menu.Item>
                        <Menu.Item key="/private-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('private')}>
                              Private Wall
                           </button>
                        </Menu.Item>
                        <Menu.Item key="/chat-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('chat')}>
                              Chat
                           </button>
                        </Menu.Item>
                     </Menu>
                  </div>
                  {/* sub menu items - end */}
               </Col>
            </Row>

            {/* selections and bulk actions container - start */}
            <div
               className="d-flex select-options-container"
               style={{
                  padding: '20px 0',
               }}>
               <div
                  className="mod-media-select-actions"
                  style={{
                     marginRight: '10em',
                  }}>
                  <h4>
                     <strong>Select Options</strong>
                  </h4>
                  <Button
                     type="primary"
                     style={{
                        marginRight: '15px',
                     }}
                     onClick={() => selectAllMedia()}>
                     Select All
                  </Button>
                  <Button onClick={() => unSelectAllMedia()}>Unselect All</Button>
               </div>
               {displayBulkActions && (
                  <div className="mod-media-bulk-actions-div">
                     <h4>
                        <strong>Bulk Actions</strong>
                     </h4>
                     <Button
                        type="primary"
                        onClick={() => handleApproveAllMedia()}
                        style={{
                           marginRight: '15px',
                           background: 'green',
                           color: 'white',
                           borderStyle: 'none',
                        }}>
                        Approve Selected
                     </Button>
                     <Button
                        style={{ background: 'red', color: 'white', borderStyle: 'none' }}
                        onClick={() => showModal('', 'all')}>
                        Reject Selected
                     </Button>
                  </div>
               )}
            </div>
            {/* selections and bulk actions container - end */}

            {loader && <LoadingScreen />}

            {(!isLoading || !loader) && (
               <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                  {data &&
                     data.data.map((media: any, index: any) => (
                        <div key={media._id} id={index} className="moderation-card-wrapper">
                           <Card
                              className="media-card"
                              bordered={false}
                              style={{
                                 width: 250,
                                 margin: '10px 20px',
                                 background: backgroundColor,
                              }}
                              actions={handleCardActions(media)}>
                              <div>
                                 {media && media?.type?.includes('Files') ? (
                                    <>
                                       <div
                                          style={{
                                             display: 'grid',
                                             gridAutoFlow: 'column',
                                             gridColumnGap: '10px',
                                             justifyContent: 'center',
                                             alignItems: 'center',
                                             marginBottom: '10px',
                                          }}>
                                          <div>
                                             <Meta
                                                title={
                                                   media?.from?.username || media?.user?.username
                                                }
                                             />
                                          </div>
                                          <div
                                             style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                             }}>
                                             <RiArrowRightLine
                                                style={{
                                                   position: 'relative',
                                                   fontSize: '24px',
                                                }}
                                             />
                                          </div>
                                          <div>
                                             <Meta title={media?.to?.username} />
                                          </div>
                                       </div>
                                       <div
                                          style={{
                                             display: 'flex',
                                             justifyContent: 'center',
                                          }}>
                                          <Meta
                                             description={moment(media?.createdAt).format(
                                                'DD/MM/yyyy, HH:mm'
                                             )}
                                          />
                                       </div>
                                    </>
                                 ) : (
                                    <Meta
                                       title={media?.user?.username}
                                       description={moment(media?.createdAt).format(
                                          'DD/MM/yyyy, HH:mm'
                                       )}
                                    />
                                 )}
                                 <br />

                                 {media && media?.convoId && <Tag color="blue">Chat</Tag>}
                                 {media && media?.private && <Tag color="red">Private</Tag>}
                                 <Checkbox
                                    className="card-checkbox"
                                    style={{ float: 'right', transform: 'scale(1.5)' }}
                                    // onChange={(e) =>
                                    //    checkedMedia(e.target, elementsRef.current[index])
                                    // }
                                    onClick={(e) => checkedMedia(e.target, media._id)}
                                    checked={selectedMediasArray.includes(media._id)}
                                 />
                              </div>

                              <div
                                 style={{ margin: '10px 0', position: 'relative' }}
                                 className="video-card-wrapper">
                                 {media &&
                                    (media?.type?.includes('video') ||
                                       media?.type?.includes('Files')) && (
                                       <div
                                          className="video-container"
                                          onClick={() => showVideoModal(media)}
                                          onKeyDown={() => showVideoModal(media)}
                                          aria-hidden="true">
                                          <RiVidiconFill
                                             style={{
                                                color: 'white',
                                                fontSize: '30px',
                                                position: 'absolute',
                                                left: '5px',
                                                cursor: 'pointer',
                                             }}
                                          />
                                          {!showVideoThumb ? (
                                             <div className="video-loader">video loading...</div>
                                          ) : (
                                             <CustomVideoThumbnail
                                                fsUrl={
                                                   media?.thumbnail_url || media?.thumbnail_content
                                                      ? media?.thumbnail_url ||
                                                        media?.thumbnail_content
                                                      : media?.url
                                                }
                                             />
                                          )}
                                       </div>
                                    )}
                              </div>
                              <Meta description={<ReadMore>{media}</ReadMore>} />
                           </Card>
                        </div>
                     ))}
               </div>
            )}

            {/* PAGINATION START */}
            {data && data?.totalPages ? (
               <div
                  className="pagination-container"
                  style={{ display: 'flex', justifyContent: 'center' }}>
                  <Form
                     onFinish={handleSubmitPage}
                     layout="inline"
                     style={{ alignItems: 'center' }}>
                     <Form.Item name="searchPage" style={{ marginRight: '10px', width: 100 }}>
                        <Input
                           placeholder="search page..."
                           value={pageNumber}
                           type="number"
                           max={data.totalPages}
                           min={1}
                           onChange={(e) => setPageNumber(e.target.value)}
                           allowClear
                        />
                     </Form.Item>
                     <Form.Item>
                        <Button type="primary" htmlType="submit">
                           Search Page
                        </Button>
                     </Form.Item>
                  </Form>
                  <ReactPaginate
                     forcePage={page - 1}
                     pageCount={data?.totalPages}
                     breakLabel="..."
                     previousLabel="<<"
                     nextLabel=">>"
                     containerClassName="pagination"
                     previousLinkClassName="pagination__link"
                     nextLinkClassName="pagination__link"
                     disabledClassName="pagination__link--disabled"
                     activeClassName="pagination__link--active"
                     marginPagesDisplayed={1}
                     pageRangeDisplayed={1}
                     onPageChange={handlePageClick}
                  />
               </div>
            ) : (
               ''
            )}
            {/* PAGINATION END */}

            {/* modal delete media confirmation - start */}
            <Modal
               title="Reject media"
               visible={isModalVisible}
               onOk={handleOk}
               onCancel={handleCancel}
               footer={[
                  <Button
                     key="backOk"
                     type="primary"
                     loading={btnLoader}
                     onClick={() => {
                        if (deleteMediaType === 'all') {
                           handleRejectAllMedia()
                        } else {
                           handleBanMedia(deleteMediaId)
                        }
                     }}
                     style={{ background: 'green', color: 'white', borderStyle: 'none' }}>
                     Proceed
                  </Button>,
                  <Button
                     key="backCancel"
                     onClick={handleOk}
                     type="primary"
                     style={{ background: 'red', color: 'white', borderStyle: 'none' }}>
                     Cancel
                  </Button>,
               ]}>
               <Alert
                  message="Warning"
                  description="You are about to reject selected media."
                  type="warning"
                  showIcon
               />
            </Modal>
            {/* modal delete media confirmation - end */}

            {/* modal video player - start */}
            <Modal
               title="Video"
               width={1000}
               visible={isVideoModalVisible}
               onCancel={handleCancelVideo}
               footer={[<Button onClick={handleCancelVideo}>Close</Button>]}>
               <VideoPlayer fsUrl={videoFileName} playing={play} />
            </Modal>
            {/* modal video player - end */}
         </div>
      </>
   )
}
