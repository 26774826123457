import React, { useState, useEffect } from 'react'

import { Card, Image, Spin } from 'antd'
import { fetchLiveMedias, getMessageFileFromFileSystem } from '../../api/media'

export type Props = {
   src?: string
   fallback?: string
   placeholder?: string
   width?: number
   height?: number
   content: string
   needPreview?: boolean
}

export const MessageMedia: React.FC<Props> = React.forwardRef((props, ref) => {
   const { src, fallback, placeholder, content = '', needPreview = true, ...rest } = props

   const [messageContentUrl, setMessageContentUrl] = useState('')

   // const handleGetFileFromMessages = async () => {
   //    const fileUrl = content.split('/')
   //    const { length } = fileUrl
   //    const encryptedFilename = fileUrl[length - 1]

   //    const response = await getMessageFileFromFileSystem(encryptedFilename)

   //    const reader = new FileReader()
   //    reader.readAsDataURL(response.data)

   //    reader.onload = function () {
   //       const imageDataUrl = reader.result
   //       console.log('imageDataUrl', imageDataUrl)
   //       // setMessageContentUrl(reader.result);
   //    }

   // }

   useEffect(() => {
      // handleGetFileFromMessages()
      const fileUrl = content.split('/')
      const { length } = fileUrl
      const encryptedFilename = fileUrl[length - 1]

      if (fileUrl.indexOf('storage.googleapis.com') > -1) {
         setMessageContentUrl(content)
      } else {
         setMessageContentUrl(
            `${process.env.REACT_APP_API_URL}/admin/medias/message_media/${encryptedFilename}`
         )
      }
   }, [content])

   return (
      <div className="img-wrapper">
         <Image
            src={messageContentUrl}
            fallback={fallback}
            placeholder={
               <div
                  style={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: '100%',
                  }}>
                  <Spin size="small" />
               </div>
            }
            preview={needPreview}
            {...rest}
         />
      </div>
   )
})
