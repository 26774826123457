/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import ReactPlayer from 'react-player'
import moment from 'moment'
import {
   Card,
   Image,
   Pagination,
   Spin,
   Input,
   Switch,
   Button,
   Select,
   Form,
   Menu,
   notification,
   Modal,
   PageHeader,
   Row,
   Col,
   Alert,
} from 'antd'
import {
   RiDeviceRecoverLine,
   RiVidiconFill,
   RiDeleteBinLine,
   RiArrowRightLine,
} from 'react-icons/ri'
import { Link, Outlet } from 'react-router-dom'
import ReactPaginate from 'react-paginate'

import { fetchDeletedMedias } from '../../api/media'
import { fallBackImageBase64 } from './constant'
import ReadMore from '../../components/ReadMore'

import useRecoverMedia from '../../hooks/media/mutation/useRecoverMedia'
import { MessageMedia } from '../../components/MessageMedia/MessageMedia'
import { VideoPlayer } from '../../components/VideoPlayer/VideoPlayer'
import { CustomVideoThumbnail } from '../../components/VideoPlayer/VideoThumbnail'
import { LoadingScreen } from '../../components/LoadingScreenComponent'
import useDeleteMedia from '../../hooks/media/mutation/useDeleteMedia'

import { axiosInstance } from '../../utils/axios-base'
import { LocalStorage } from '../../services'

const { Meta } = Card
const { Option } = Select

/* TODO:

 filter:
   - all medias
   - report
   - private medias

*/

export const DeletedMediasPage = () => {
   // for useQuery
   const [page, setPage] = React.useState(1)
   const [limit, setPageLimit] = React.useState(10)
   const [mediaType, setMediaType] = useState('public')

   // for search
   const [searchName, setSearchName] = useState('')
   const [pageNumber, setPageNumber] = useState(1) as any // for searching page

   // for modals
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [isVideoModalVisible, setIsVideoModalVisible] = useState(false)
   const [loader, setLoader] = useState(false)

   const [play, setPlay] = useState(false)

   // for video
   const [videoFileName, setVideoFileName] = useState('')
   const [showVideoThumb, setShowVideoThumb] = useState(false)

   // for recover media function
   const recoverMedia = useRecoverMedia()

   // for ban/delete media
   const [deleteMediaId, setDeleteMediaId] = useState('')
   const deleteMedia = useDeleteMedia()
   const [btnLoader, setBtnLoader] = useState(false)

   // to check user role, hide ban button if epoch user
   const userRole = LocalStorage.getRole()

   const videoFormats = ['.mp4', '.mov', '.wmv', '.avi', '.ogg', '.webm', '.mkv', '.mpeg']
   const imageFormats = ['.jpeg', '.png', '.jpg', '.svg']

   // populate data
   const { isLoading, isError, error, data, refetch } = useQuery(
      ['deleted-medias', page, limit],
      () => fetchDeletedMedias(page, limit, searchName, mediaType),
      { enabled: true }
   )

   // notification success
   const successNotification = (successMessage: string) => {
      notification.success({
         message: successMessage,
      })
   }

   // notification error
   const errorNotification = () => {
      notification.error({
         message: 'Error. Something went wrong',
      })
   }

   const handleSetPlay = (val: any) => {
      console.log('handleSetPlay: ', val)
      setPlay(!val)
   }

   // video modal
   const showVideoModal = async (media: any) => {
      const fileUrl = media?.url?.split('/') || media?.content
      if (fileUrl.indexOf('storage.googleapis.com') > -1) {
         setVideoFileName(media?.url || media?.content)
      } else {
         setVideoFileName(media?.fsUrl || media?.content)
      }
      setPlay(true)
      await setIsVideoModalVisible(true)
   }

   const handleCancelVideo = async () => {
      await setPlay(false)
      setVideoFileName('')
      setIsVideoModalVisible(false)
   }

   const handleRecoverMedia = async (mediaId: string) => {
      try {
         setLoader(true)
         const response = await recoverMedia.mutateAsync(mediaId)

         if (response.data === 'success') {
            successNotification('Media recovered')
            refetch()
            setLoader(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
      }
   }

   // for search
   const handleSubmit = (values: any) => {
      setLoader(true)
      async function refetchData() {
         await refetch()
         setLoader(false)
      }
      refetchData()
   }

   // for search page
   const handleSubmitPage = (values: any) => {
      console.log('values of form: ', values)
      setPage(parseInt(values.searchPage, 10))
   }

   // if input for search is empty, trigger submit
   useEffect(() => {
      if (searchName === '') {
         handleSubmit('')
      }
   }, [searchName])

   const delayComponentRender = () => {
      setTimeout(() => setShowVideoThumb(true), 2500)
   }

   // if mediatype is changed (private=true or private=false) trigger refetch
   useEffect(() => {
      setLoader(true)
      async function refetchData() {
         delayComponentRender()
         await refetch()
         setLoader(false)
      }
      refetchData()
   }, [page, mediaType])

   const handleChangeMediaType = async (_mediaType: any) => {
      // await setMediaType(_mediaType)
      // setLoader(true)
      // await setPage(1)
      // async function refetchData() {
      //    await refetch()
      //    setLoader(false)
      // }
      // refetchData()
      setMediaType(_mediaType)
      setPage(1)
   }

   // pagination functions - start
   const handlePageClick = async (pgData: any) => {
      setPage(pgData.selected + 1)
      async function refetchData() {
         await refetch()
         setLoader(false)
      }
      refetchData()
   }

   // confirmation modal
   const showModal = (selectedMediaId: string) => {
      setDeleteMediaId(selectedMediaId)
      setIsModalVisible(true)
   }

   const handleOk = () => {
      setIsModalVisible(false)
   }

   const handleCancel = () => {
      setIsModalVisible(false)
   }

   const handleDeleteMedia = async (mediaId: string) => {
      try {
         setBtnLoader(true)
         const response = await deleteMedia.mutateAsync(mediaId)
         if (response.data.success) {
            successNotification('Media deleted')
            setIsModalVisible(false)
            setBtnLoader(false)
         }
      } catch (err) {
         console.error(err)
      }

      async function refetchData() {
         await refetch()
      }
      refetchData()
   }

   const handleMediaUrl = (media: any) => {
      let mediaUrl = ''
      const fileUrl = media?.url ? media?.url?.split('/') : media?.content?.split('/')
      if (fileUrl.indexOf('storage.googleapis.com') > -1) {
         mediaUrl = media?.url || media?.content
      } else {
         mediaUrl = `${process.env.REACT_APP_API_URL}/admin/medias/filename/${media.filename}`
      }
      return mediaUrl
   }

   const handleCreatedAtDate = (media: any) => {
      let resultDate = moment(media?.createdAt).format('DD/MM/yyyy, HH:mm')
      if (mediaType && mediaType.includes('chat') && media?.message && media?.message?.createdAt)
         resultDate = moment(media?.message?.createdAt).format('DD/MM/yyyy, HH:mm')
      else if (media?.publication && media?.publication?.createdAt)
         resultDate = moment(media?.publication?.createdAt).format('DD/MM/yyyy, HH:mm')
      return resultDate
   }

   return (
      <>
         {isLoading && <p>Loading...</p>}
         {isError && <span>Error: {error}</span>}
         <div className="content-wrapper">
            <PageHeader className="site-page-header" title="Deleted Medias" />
            <Row justify="space-around" style={{ marginBottom: '20px' }}>
               <Col flex="500px">
                  {/* search div - start */}
                  <Form className="search-form" onFinish={handleSubmit} layout="inline">
                     <Form.Item label="Search" name="searchName" style={{ marginRight: '10px' }}>
                        <Input
                           placeholder="search..."
                           value={searchName}
                           onChange={(e) => setSearchName(e.target.value)}
                           allowClear
                        />
                     </Form.Item>
                     <Form.Item>
                        <Button type="primary" htmlType="submit">
                           Search
                        </Button>
                     </Form.Item>
                  </Form>
                  {/* search div - end */}
               </Col>
               <Col flex="auto">
                  <div className="wall-menu-container">
                     <Menu
                        mode="horizontal"
                        defaultSelectedKeys={['/live-medias']}
                        className="menu-flex-end">
                        <Menu.Item key="/live-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('public')}>
                              Public Wall
                           </button>
                        </Menu.Item>
                        <Menu.Item key="/private-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('private')}>
                              Private Wall
                           </button>
                        </Menu.Item>
                        <Menu.Item key="/chat-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('chat')}>
                              Chat
                           </button>
                        </Menu.Item>
                     </Menu>
                  </div>
               </Col>
            </Row>

            {loader && <LoadingScreen />}

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
               {data &&
                  data.data?.map((media: any) => (
                     <div key={media._id}>
                        <Card
                           className="media-card-container"
                           bordered={false}
                           style={{ width: 250, margin: '0 20px' }}>
                           {/* TODO: need to handle videos or audio file */}
                           {media && media?.message?.convoId ? (
                              <>
                                 <div
                                    style={{
                                       display: 'grid',
                                       gridAutoFlow: 'column',
                                       gridColumnGap: '10px',
                                       justifyContent: 'center',
                                       alignItems: 'center',
                                       marginBottom: '10px',
                                    }}>
                                    <div>
                                       <Meta
                                          title={
                                             media?.message?.from?.username ||
                                             media?.message?.user?.username
                                          }
                                       />
                                    </div>
                                    <div
                                       style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                       }}>
                                       <RiArrowRightLine
                                          style={{
                                             position: 'relative',
                                             fontSize: '24px',
                                          }}
                                       />
                                    </div>
                                    <div>
                                       <Meta title={media?.message?.to?.username} />
                                    </div>
                                 </div>
                                 <div
                                    style={{
                                       display: 'flex',
                                       justifyContent: 'center',
                                    }}>
                                    <Meta description={handleCreatedAtDate(media)} />
                                 </div>
                              </>
                           ) : (
                              <Meta
                                 title={media?.user?.username}
                                 description={handleCreatedAtDate(media)}
                              />
                           )}
                           <div style={{ padding: '10px 0', position: 'relative' }}>
                              {/* if media is video */}
                              {media &&
                                 (media?.publication?.type?.includes('video') ||
                                    media?.message?.type?.includes('video')) && (
                                    <div
                                       className="video-container"
                                       onClick={() =>
                                          showVideoModal(media?.publication || media?.message)
                                       }
                                       aria-hidden="true">
                                       <RiVidiconFill
                                          style={{
                                             color: 'white',
                                             fontSize: '30px',
                                             position: 'absolute',
                                             left: '5px',
                                             cursor: 'pointer',
                                          }}
                                       />
                                       {}
                                       {!showVideoThumb ? (
                                          <div className="video-loader">video loading...</div>
                                       ) : (
                                          <CustomVideoThumbnail
                                             fsUrl={
                                                media?.publication?.thumbnail_url ||
                                                media?.publication?.thumbnail_content
                                                   ? media?.publication?.thumbnail_url ||
                                                     media?.publication?.thumbnail_content
                                                   : media?.publication?.url
                                             }
                                          />
                                       )}
                                    </div>
                                 )}

                              {/* if media is an image */}
                              {media &&
                                 (media?.publication?.type?.includes('image') ||
                                    media?.message?.type?.includes('image') ||
                                    media?.message?.type?.includes('Custom-Media-Image')) && (
                                    <div className="img-wrapper">
                                       <Image
                                          src={handleMediaUrl(media?.publication || media?.message)}
                                          fallback={fallBackImageBase64}
                                          placeholder={
                                             <div
                                                style={{
                                                   display: 'flex',
                                                   justifyContent: 'center',
                                                   alignItems: 'center',
                                                   height: '100%',
                                                }}>
                                                <Spin size="small" />
                                             </div>
                                          }
                                       />
                                    </div>
                                 )}

                              {/* if media is video & from a private message */}
                              {media &&
                                 media?.message?.type?.includes('Files') &&
                                 videoFormats.some((v) => media?.message?.content.includes(v)) && (
                                    <div
                                       className="video-container"
                                       onClick={() => showVideoModal(media?.message)}
                                       onKeyDown={() => showVideoModal(media?.message)}
                                       aria-hidden="true">
                                       <RiVidiconFill
                                          style={{
                                             color: 'white',
                                             fontSize: '30px',
                                             position: 'absolute',
                                             left: '5px',
                                             cursor: 'pointer',
                                          }}
                                       />
                                       {!showVideoThumb ? (
                                          <div className="video-loader">video loading...</div>
                                       ) : (
                                          <CustomVideoThumbnail
                                             fsUrl={
                                                media?.message?.thumbnail_content
                                                   ? media?.message?.thumbnail_content
                                                   : media?.message?.content
                                             }
                                          />
                                       )}
                                    </div>
                                 )}

                              {/* if media is image & from a private message */}
                              {media &&
                                 media?.message?.type?.includes('Files') &&
                                 imageFormats.some((v) => media?.message?.content.includes(v)) && (
                                    <MessageMedia content={media?.message?.content} />
                                 )}

                              <div
                                 style={{
                                    background: '#f2f2f2',
                                    color: '#e5195f',
                                    position: 'absolute',
                                    bottom: '18px',
                                    right: '10px',
                                    fontSize: '26px',
                                    cursor: 'pointer',
                                    padding: '4px',
                                    borderRadius: '5px',
                                    height: '2.5rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '10px',
                                 }}>
                                 {/* Recover Media Button */}
                                 <RiDeviceRecoverLine
                                    onClick={() => handleRecoverMedia(media._id)}
                                    /* TODO: recover media */
                                 />

                                 {/* Delete Media Button */}
                                 {userRole === 'admin' && (
                                    <RiDeleteBinLine onClick={() => showModal(media?._id)} />
                                 )}
                              </div>
                           </div>
                           <Meta description={<ReadMore>{media}</ReadMore>} />
                        </Card>
                     </div>
                  ))}
            </div>

            {/* PAGINATION START */}
            {data && data?.totalPages ? (
               <div
                  className="pagination-container"
                  style={{ display: 'flex', justifyContent: 'center' }}>
                  <Form
                     onFinish={handleSubmitPage}
                     layout="inline"
                     style={{ alignItems: 'center' }}>
                     <Form.Item name="searchPage" style={{ marginRight: '10px', width: 100 }}>
                        <Input
                           placeholder="search page..."
                           value={pageNumber}
                           type="number"
                           max={data.totalPages}
                           min={1}
                           onChange={(e) => setPageNumber(e.target.value)}
                           allowClear
                        />
                     </Form.Item>
                     <Form.Item>
                        <Button type="primary" htmlType="submit">
                           Search Page
                        </Button>
                     </Form.Item>
                  </Form>
                  <ReactPaginate
                     forcePage={page - 1}
                     pageCount={data?.totalPages}
                     breakLabel="..."
                     previousLabel="<<"
                     nextLabel=">>"
                     containerClassName="pagination"
                     previousLinkClassName="pagination__link"
                     nextLinkClassName="pagination__link"
                     disabledClassName="pagination__link--disabled"
                     activeClassName="pagination__link--active"
                     marginPagesDisplayed={1}
                     pageRangeDisplayed={1}
                     onPageChange={handlePageClick}
                  />
               </div>
            ) : (
               ''
            )}
            {/* PAGINATION END */}

            {/* modal video player - start */}
            <Modal
               title="Video"
               visible={isVideoModalVisible}
               onCancel={handleCancelVideo}
               footer={[<Button onClick={handleCancelVideo}>Close</Button>]}>
               <VideoPlayer fsUrl={videoFileName} playing={play} />
            </Modal>

            {/* modal video player - end */}

            {/* modal delete media confirmation - start */}
            <Modal
               title="Delete media"
               visible={isModalVisible}
               onOk={handleOk}
               onCancel={handleCancel}
               footer={[
                  <Button
                     key="back"
                     type="primary"
                     loading={btnLoader}
                     onClick={() => handleDeleteMedia(deleteMediaId)}
                     style={{ background: 'green', color: 'white', borderStyle: 'none' }}>
                     Proceed
                  </Button>,
                  <Button
                     key="back"
                     onClick={handleOk}
                     type="primary"
                     style={{ background: 'red', color: 'white', borderStyle: 'none' }}>
                     Cancel
                  </Button>,
               ]}>
               <Alert
                  message="Warning"
                  description="You are about to delete this media. This will be permanently deleted."
                  type="warning"
                  showIcon
               />
            </Modal>
            {/* modal delete media confirmation - end */}
         </div>
      </>
   )
}
