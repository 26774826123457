import { useMutation, useQueryClient } from 'react-query'
import { IFormInput } from '../../../pages/NewsLetterEmail/EmailForm/EmailForm'
import { sendNewsletterEmail } from '../../../api/newsletter'

export default function useSendEmails() {
   const queryClient = useQueryClient()
   return useMutation(
      async (data: IFormInput) => {
         const response = await sendNewsletterEmail(data)
         return response
      },
      {
         onSuccess: () => {
            queryClient.invalidateQueries('live-medias')
         },
         onMutate: (sentEmail) => {
            return sentEmail
         },
         // If the mutation fails, use the value returned from onMutate to roll back
         onError: (error, variables, context: any) => {
            // An error happened!
            console.log(`rolling back optimistic update with id ${context?.id}`)
         },
         // Always refetch after error or success:
         onSettled: () => {
            // Error or success... doesn't matter!
            queryClient.invalidateQueries('live-medias')
         },
      }
   )
}
