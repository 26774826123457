import React, { useState } from 'react'
import { Modal } from 'antd'
import './styles.css'

interface IConfirmationModal {
   title: string
   description: string
   open: boolean
   setOpen: (open: boolean) => void
   data: any
   onConfirm: (data: any) => void
   type: string
}

const ConfirmationModal = (props: IConfirmationModal) => {
   const { title, description, open, setOpen, data, type } = props
   console.log('props', props)
   const [confirmLoading, setConfirmLoading] = useState(false)
   const handleOk = () => {
      setConfirmLoading(true)
      props.onConfirm(data)
   }

   const handleCancel = () => {
      setOpen(false)
   }

   return (
      <Modal
         className="confirmation-modal"
         title={title}
         visible={open}
         confirmLoading={confirmLoading}
         width={260}
         onOk={handleOk}
         closable={false}
         keyboard={false}
         onCancel={handleCancel}>
         <p>{description}</p>
      </Modal>
   )
}

export default ConfirmationModal
