import { initReactQueryAuth } from './react-query-auth'
import { getUserProfile, loginWithEmailAndPassword } from '../../api/auth'

import { LocalStorage } from '../../services'

export interface User {
   id: string
   email: string
   username?: string
   role?: string
}

export type LoginCredentials = {
   email: string
   password: string
}

export type RegisterCredentials = {
   email: string
   name: string
   password: string
}

async function handleUserResponse(data: any) {
   // eslint-disable-next-line camelcase
   const { access_token, user } = data
   LocalStorage.setToken(access_token)
   LocalStorage.setUser(user.id)
   LocalStorage.setRole(user.role)

   return user
}

async function loadUser() {
   let user = null
   if (LocalStorage.getToken()) {
      try {
         const data = await getUserProfile()
         if (data) {
            user = data?.user
         }
      } catch (error) {
         console.log('error loaduser', error)
      }
   }

   return user
}

async function loginFn(data: LoginCredentials) {
   const response = await loginWithEmailAndPassword(data)
   const user = await handleUserResponse(response)
   return user
}

// async function registerFn(data: RegisterCredentials) {
//    const response = await registerWithEmailAndPassword(data)
//    const user = await handleUserResponse(response)
//    return user
// }

function RemoveStorage() {
   LocalStorage.clearToken()
   LocalStorage.clearUser()
   LocalStorage.clearRole()
}

async function logoutFn() {
   await RemoveStorage()
}

type AuthConfig = {
   loadUser: (data: any) => Promise<User | any>
   loginFn: (data: any) => Promise<User>
   // registerFn: (data: any) => Promise<User>
   logoutFn: () => Promise<unknown>
}

const authConfig = {
   loadUser,
   loginFn,
   // registerFn,
   logoutFn,
} as AuthConfig

/* Initialize react-query-auth hook */
const { AuthProvider, AuthConsumer, useAuth } = initReactQueryAuth<
   User,
   any,
   LoginCredentials
   // RegisterCredentials
>(authConfig)

export { AuthProvider, AuthConsumer, useAuth, loadUser }
