import { useMutation, useQueryClient } from 'react-query'

import { moderateProfilePic } from '../../../api/media'

export default function useModerateProfilePic(key = 'moderation-photos') {
   const queryClient = useQueryClient()
   return useMutation(
      async (data: { mediaId: string; status: string }) => {
         const response = await moderateProfilePic(data.mediaId, { status: data.status })

         return response
      },
      {
         onSuccess: () => {
            // refetch live medias
            queryClient.invalidateQueries(key)
         },
         // If the mutation fails, use the value returned from onMutate to roll back
         onError: (error, variables, context: any) => {
            // An error happened!
            console.log(`rolling back optimistic update with id ${context?.id}`)
         },
         // Always refetch after error or success:
         onSettled: () => {
            // Error or success... doesn't matter!
            queryClient.invalidateQueries('moderation-photos')
         },
      }
   )
}
