import { axiosInstance, getAuthSession } from '../utils/axios-base'

export async function fetchReports(
   pageNum = 1,
   pageLimit = 10,
   searchName = '',
   mediaType = 'public',
   filterBy = ''
) {
   const response = await axiosInstance.get(`/admin/reports?`, {
      params: {
         page: pageNum,
         limit: pageLimit,
         q: searchName,
         sortBy: 'createdAt',
         orderBy: 'desc',
         type: mediaType,
         filterBy,
      },
   })
   return response.data
}

export async function deleteReportedMedia(userID: any) {
   const response = await axiosInstance.delete(`/admin/reports/report/${userID}/delete`)

   return response.data
}

export async function fetchWarnings(searchName = '') {
   const response = await axiosInstance.get(`/admin/warning/list?`, {
      params: {
         q: searchName,
      },
   })
   return response.data
}
