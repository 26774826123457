/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */

import React from 'react'
import { useInfiniteQuery, useQuery } from 'react-query'

import { VscWarning, VscTrash } from 'react-icons/vsc'
import { FcOk } from 'react-icons/fc'

import { axiosInstance } from '../../../utils/axios-base'
import useIntersectionObserver from '../../../hooks/useIntersectionObserver'

import { UserTable } from '../UserTable'

import { Table } from './styled'
import { fetchUsersStats } from '../../../api/users-api'

export const UserPage = () => {
   const { data: productData } = useQuery(['users-stats'], () => fetchUsersStats(), {
      keepPreviousData: true,
   })
   // const {
   //    status,
   //    data,
   //    error,
   //    isFetching,
   //    isFetchingNextPage,

   //    fetchNextPage,

   //    hasNextPage,
   // } = useInfiniteQuery(
   //    'users',
   //    async ({ pageParam = 0 }) => {
   //       /* Need to figure out how to pass a query string */

   //       // const res = await axiosInstance.get(`/admin/feety-users/?q=&cursor=${pageParam}`)
   //       const res = await axiosInstance.get(`/admin/feety-users/list`)

   //       return res.data
   //    },
   //    {
   //       getNextPageParam: (lastPage) => lastPage.nextCursor ?? false,
   //    }
   // )

   // const loadMoreButtonRef = React.useRef()

   // useIntersectionObserver({
   //    target: loadMoreButtonRef,
   //    onIntersect: fetchNextPage,
   //    enabled: hasNextPage,
   // })

   return (
      <>
         {/* {status === 'loading' && <p>Loading...</p>} */}

         {/* {status === 'error' && <span>Error: {error}</span>} */}

         <div className="content-wrapper">
            <UserTable />

            {/*                
            <Table>
               <tr>
                  <th>Pseudo</th>
                  <th>ID</th>
                  <th>Media ban</th>
                  <th>Reported</th>
                  <th>Reporting</th>
                  <th>Warning</th>
                  <th>ID Check</th>
                  <th>Mails</th>
                  <th>Actions</th>
               </tr>
               {data?.pages.map((page) => (
                  <React.Fragment key={page.nextCursor}>
                     {page.data.map((user: any) => (
                        <tr key={user._id}>
                           <td className="left-align">{user.username || user.email}</td>
                           <td>{user._id}</td>
                           <td>23</td>
                           <td>23</td>
                           <td>23</td>
                           <td>23</td>
                           <td>
                              <FcOk />
                           </td>
                           <td className="left-align">{user.email}</td>
                           <td>
                              <VscTrash />

                              <VscWarning />
                           </td>
                        </tr>
                     ))}
                  </React.Fragment>
               ))}
            </Table> */}

            {/* <div>
               <button
                  ref={loadMoreButtonRef as any}
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}>
                  {isFetchingNextPage
                     ? 'Loading more...'
                     : hasNextPage
                     ? 'Load More'
                     : 'Nothing more to load'}
               </button>
            </div>
            <div>{isFetching && !isFetchingNextPage ? 'Background Updating...' : null}</div> */}
         </div>
      </>
   )
}
