import {
   Alert,
   Button,
   Card,
   Col,
   Form,
   Input,
   Menu,
   Modal,
   notification,
   PageHeader,
   Row,
   Tag,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import moment from 'moment'
import { RiArrowRightLine, RiVidiconFill } from 'react-icons/ri'
import ReactPaginate from 'react-paginate'
import { fetchLiveMedias } from '../../api/media'
import { LoadingScreen } from '../../components/LoadingScreenComponent'
import { fallBackImageBase64 } from '../ModeratedMedias/Photos/constant'
import { MessageMedia } from '../../components/MessageMedia/MessageMedia'
import ReadMore from '../../components/ReadMore'
import ImageListModal from '../../components/CustomMedia/ImageListModal'
import useBanMedia from '../../hooks/media/mutation/useBanMedia'
import useApproveMedia from '../../hooks/media/mutation/useApproveMedia'
import useApproveAllMedia from '../../hooks/media/mutation/useApproveAllMedia'
import useBanAllMedia from '../../hooks/media/mutation/useBanAllMedia'
import { VideoPlayer } from '../../components/VideoPlayer/VideoPlayer'
import { CustomVideoThumbnail } from '../../components/VideoPlayer/VideoThumbnail'
import useRecoverMedia from '../../hooks/media/mutation/useRecoverMedia'
import useCustomMediaImages from '../../hooks/media/mutation/useCustomMediaImages'

const { Meta } = Card

const CustomMediasPage = () => {
   const [loader, setLoader] = useState(false)

   const [mediaStatus, setMediaStatus] = useState('pending')
   const [sortOrder, setSortOrder] = useState('desc')
   const [searchName, setSearchName] = useState('')
   const [pageNumber, setPageNumber] = useState(1) as any // for searching page
   const [page, setPage] = React.useState(0)
   const [limit, setPageLimit] = React.useState(10)
   const [mediaType, setMediaType] = useState('custom')

   const [backgroundColor, setBackgroundColor] = React.useState('#e9e8e8')
   const [openImageListModal, setImageListModal] = React.useState(false)
   const [selectedData, setSelectedData] = React.useState([])
   const [selectedCM, setSelectedCM] = React.useState([])
   const [selectedMediasArray, setSelectedMediasArray] = useState([]) as any
   const [isVideoModalVisible, setIsVideoModalVisible] = useState(false)
   const [showVideoThumb, setShowVideoThumb] = useState(false)
   const [videoFileName, setVideoFileName] = useState('')
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [deleteMediaId, setDeleteMediaId] = useState('')
   const [deleteMedias, setDeleteMedias] = useState([])
   const [deleteMediaType, setDeleteMediaType] = useState('')

   const [play, setPlay] = useState(false)

   const banMedia = useBanMedia()
   const approveMedia = useApproveMedia()
   const approveAllMedia = useApproveAllMedia()
   const banAllMedia = useBanAllMedia()

   const recoverMedia = useRecoverMedia('pending')
   const customMediaImages = useCustomMediaImages()
   // useQuery -> to populate data
   const { isLoading, status, isError, error, data, isFetching, isPreviousData, refetch } =
      useQuery(['live-medias', page, limit], () =>
         fetchLiveMedias(page, limit, searchName, mediaType, mediaStatus, sortOrder)
      )

   // const delayComponentRender = () => {
   //    setTimeout(() => setShowVideoThumb(true), 2500)
   // }

   useEffect(() => {
      setLoader(true)

      async function refetchData() {
         await refetch()
         setLoader(false)
      }

      // delayComponentRender()
      refetchData().then()
   }, [page, mediaStatus])

   // notification success approve
   const successNotificationApprove = () => {
      notification.success({
         message: 'Media approved',
      })
   }

   // notification success reject
   const successNotificationReject = () => {
      notification.success({
         message: 'Media rejected',
      })
   }

   const successNotificationRecovered = () => {
      notification.success({
         message: 'Media recovered',
      })
   }

   // notification error
   const errorNotification = () => {
      notification.error({
         message: 'Error. Something went wrong',
      })
   }

   // confirmation modal
   const showDeleteModal = (
      selectedMediaId: string,
      selectedIds: [],
      selectedMediaType: string
   ) => {
      setDeleteMediaId(selectedMediaId)
      setDeleteMediaType(selectedMediaType)
      setDeleteMedias(selectedIds)
      setIsModalVisible(true)
   }

   // individual ban
   const handleBanMedia = async (mediaId: string) => {
      setImageListModal(false)
      try {
         setLoader(true)
         const response = await banMedia.mutateAsync(mediaId)

         if (response.data === 'success') {
            successNotificationReject()
            refetch()
            setLoader(false)
            setIsModalVisible(false)
         }
      } catch (err) {
         console.error(err)
         // errorNotification()
         successNotificationReject()
         refetch()
         setLoader(false)
         setIsModalVisible(false)
      }
   }

   // individual approval
   const handleApproveMedia = async (mediaId: string) => {
      setImageListModal(false)
      try {
         setLoader(true)
         const response = await approveMedia.mutateAsync(mediaId)

         if (response.status === 200) {
            successNotificationApprove()
            refetch()
            setLoader(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
      }
   }
   // individual recovery
   const handleRecoverMedia = async (mediaId: string) => {
      setImageListModal(false)
      try {
         setLoader(true)
         const response = await recoverMedia.mutateAsync(mediaId)

         if (response.status === 200) {
            successNotificationRecovered()
            refetch()
            setLoader(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
      }
   }
   // bulk rejected
   const handleRejectAllMedia = async (selectedMedias: any) => {
      setImageListModal(false)
      try {
         setLoader(true)
         const response = await banAllMedia.mutateAsync(selectedMedias)

         if (response.status === 200) {
            successNotificationReject()
            refetch()
            setSelectedMediasArray([])
            setLoader(false)
            setIsModalVisible(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
         setIsModalVisible(false)
      }
   }

   // bulk approval
   const handleApproveAllMedia = async (selectedMedias: any) => {
      setImageListModal(false)
      try {
         setLoader(true)
         const response = await approveAllMedia.mutateAsync(selectedMedias)

         if (response.status === 200) {
            successNotificationApprove()
            refetch()
            setSelectedMediasArray([])
            setLoader(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
      }
   }

   // pagination function
   const handlePageClick = async (pgData: any) => {
      await setPage(pgData.selected + 1)

      async function refetchData() {
         await refetch()
         setLoader(false)
      }

      refetchData().then()
   }

   const handleChangeMediaStatus = (_mediaStatus: string) => {
      if (_mediaStatus === 'pending') {
         setSortOrder('desc')
      } else {
         setSortOrder('desc')
      }
      setMediaStatus(_mediaStatus)
      setPage(1)
   }

   const handleOk = () => {
      setImageListModal(false)
   }

   const handleSelect = async (imgData: number, media: any) => {
      try {
         setLoader(true)
         const response = await customMediaImages.mutateAsync(media.id)

         if (response.status === 200) {
            if (mediaStatus === 'pending')
               setSelectedData(
                  response.data.data.filter((img: any) => img.status.includes(mediaStatus))
               )
            else
               setSelectedData(
                  response.data.data.filter(
                     (img: any) =>
                        img.status.includes('approved') || img.status.includes('rejected')
                  )
               )
            setLoader(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
      }

      setSelectedCM(media)
      setImageListModal(true)
   }

   const handleSubmit = (values: any) => {
      setLoader(true)

      async function refetchData() {
         await refetch()
         setLoader(false)
      }

      refetchData().then()
   }

   // for search page
   const handleSubmitPage = (values: any) => {
      setPage(parseInt(values.searchPage, 10))
   }

   const showVideoModal = (media: any) => {
      setVideoFileName(media)
      setPlay(true)
      setIsVideoModalVisible(true)
   }

   const handleCancelVideo = async () => {
      await setPlay(false)
      setVideoFileName('')
      setIsVideoModalVisible(false)
   }

   const handleRatio = (images: number, total: number) => `${images}/${total}`

   // if input for search is empty, trigger submit
   useEffect(() => {
      if (searchName === '') {
         handleSubmit('')
      }
   }, [searchName])

   return (
      <div className="content-wrapper">
         <PageHeader className="site-page-header" title="Custom Medias" />
         <Row justify="space-around">
            <Col flex="500px">
               <Form className="search-form" onFinish={handleSubmit} layout="inline">
                  <Form.Item label="Search" name="searchName" style={{ marginRight: '10px' }}>
                     <Input
                        placeholder="search..."
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                        allowClear
                     />
                  </Form.Item>
                  <Form.Item>
                     <Button type="primary" htmlType="submit">
                        Search
                     </Button>
                  </Form.Item>
               </Form>
            </Col>
            <Col flex="auto">
               <div className="wall-menu-container">
                  <Menu
                     mode="horizontal"
                     defaultSelectedKeys={['/awaiting-custom-medias']}
                     className="menu-flex-end">
                     <Menu.Item key="/awaiting-custom-medias">
                        <button
                           type="button"
                           style={{
                              borderStyle: 'none',
                              backgroundColor: 'white',
                              height: '15px',
                              cursor: 'pointer',
                           }}
                           onClick={() => handleChangeMediaStatus('pending')}>
                           Awaiting Moderation
                        </button>
                     </Menu.Item>
                     <Menu.Item key="/moderated-medias">
                        <button
                           type="button"
                           style={{
                              borderStyle: 'none',
                              backgroundColor: 'white',
                              height: '15px',
                              cursor: 'pointer',
                           }}
                           onClick={() => handleChangeMediaStatus('approved,rejected')}>
                           Moderated
                        </button>
                     </Menu.Item>
                  </Menu>
               </div>
            </Col>
         </Row>

         {loader && <LoadingScreen />}

         {(!isLoading || !loader) && (
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
               {data &&
                  data.data
                     .filter((itm: any) => itm !== null)
                     .map((media: any, index: number) => {
                        return (
                           // eslint-disable-next-line no-underscore-dangle
                           <div key={media._id} id={`${index}`} className="moderation-card-wrapper">
                              <Card
                                 className="media-card"
                                 bordered={false}
                                 onClick={() => handleSelect(media.images, media)}
                                 style={{
                                    width: 250,
                                    height: 'auto',
                                    margin: '10px 20px',
                                    background: backgroundColor,
                                 }}>
                                 <div>
                                    <div
                                       style={{
                                          display: 'grid',
                                          gridAutoFlow: 'column',
                                          gridColumnGap: '10px',
                                          justifyContent: 'center',
                                       }}>
                                       <div>
                                          <Meta title={media?.from?.username} />
                                       </div>
                                       <div>
                                          <RiArrowRightLine
                                             style={{
                                                position: 'relative',
                                                fontSize: '24px',
                                             }}
                                          />
                                       </div>
                                       <div>
                                          <Meta title={media?.to?.username} />
                                       </div>
                                    </div>
                                    <div
                                       style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                       }}>
                                       {media && media.type?.includes('Custom') && (
                                          <Meta
                                             title={moment(media?.createdAt).format(
                                                'DD/MM/yyyy, HH:mm'
                                             )}
                                          />
                                       )}
                                    </div>
                                    <br />
                                    <div>
                                       <div>
                                          {/* eslint-disable-next-line no-underscore-dangle */}
                                          <Meta title="ID:" description={media._id} />
                                       </div>
                                    </div>
                                    <br />
                                 </div>
                                 <div
                                    style={{
                                       display: 'grid',
                                       gridAutoFlow: 'column',
                                       gridColumnGap: '50px',
                                    }}>
                                    <div>
                                       {media?.tickles}&nbsp;<Tag color="pink">Tickles</Tag>
                                    </div>
                                    <div>
                                       {handleRatio(media?.images, media?.unfiltered_images)}
                                       &nbsp;
                                       <Tag color="blue">&nbsp;</Tag>
                                    </div>
                                 </div>
                                 <div style={{ padding: '10px 0', position: 'relative' }}>
                                    {media &&
                                       media?.images?.length > 0 &&
                                       media?.images[0]?.customMediaType?.includes('video') && (
                                          <div className="video-container" aria-hidden="true">
                                             <RiVidiconFill
                                                style={{
                                                   color: 'white',
                                                   fontSize: '30px',
                                                   position: 'absolute',
                                                   left: '5px',
                                                   cursor: 'pointer',
                                                }}
                                             />
                                             {!showVideoThumb ? (
                                                <div className="video-loader">video loading...</div>
                                             ) : (
                                                <CustomVideoThumbnail
                                                   fsUrl={
                                                      media?.images[0]?.thumbnail_content
                                                         ? media?.images[0]?.thumbnail_content
                                                         : media?.images[0]?.content
                                                   }
                                                   pageType="custom-media"
                                                />
                                             )}
                                          </div>
                                       )}

                                    {media &&
                                       media?.images?.length > 0 &&
                                       !media?.images[0]?.customMediaType &&
                                       media?.images[0]?.type?.includes('Image') && (
                                          <MessageMedia
                                             fallback={fallBackImageBase64}
                                             content={media?.images[0]?.content}
                                             needPreview={false}
                                          />
                                       )}

                                    {media &&
                                       media?.images?.length > 0 &&
                                       media?.images[0]?.customMediaType &&
                                       media?.images[0]?.customMediaType?.includes('image') && (
                                          <MessageMedia
                                             fallback={fallBackImageBase64}
                                             content={media?.images[0]?.content}
                                             needPreview={false}
                                          />
                                       )}

                                    {media && media?.images?.length === 0 && (
                                       <MessageMedia content={media?.content} needPreview={false} />
                                    )}
                                 </div>
                                 <Meta description={<ReadMore>{media}</ReadMore>} />
                              </Card>
                           </div>
                        )
                     })}
            </div>
         )}
         {/* RiArrowRightLine */}
         {/* PAGINATION START */}
         {data && data?.totalPages ? (
            <div
               className="pagination-container"
               style={{ display: 'flex', justifyContent: 'center' }}>
               <Form onFinish={handleSubmitPage} layout="inline" style={{ alignItems: 'center' }}>
                  <Form.Item name="searchPage" style={{ marginRight: '10px', width: 100 }}>
                     <Input
                        placeholder="search page..."
                        value={pageNumber}
                        type="number"
                        max={data.totalPages}
                        min={1}
                        onChange={(e) => setPageNumber(e.target.value)}
                        allowClear
                     />
                  </Form.Item>
                  <Form.Item>
                     <Button type="primary" htmlType="submit">
                        Search Page
                     </Button>
                  </Form.Item>
               </Form>
               <ReactPaginate
                  forcePage={page - 1}
                  pageCount={data?.totalPages}
                  breakLabel="..."
                  previousLabel="<<"
                  nextLabel=">>"
                  containerClassName="pagination"
                  previousLinkClassName="pagination__link"
                  nextLinkClassName="pagination__link"
                  disabledClassName="pagination__link--disabled"
                  activeClassName="pagination__link--active"
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  onPageChange={handlePageClick}
               />
            </div>
         ) : (
            ''
         )}
         {/* PAGINATION END */}
         {/* Image List Modal  */}
         <ImageListModal
            data={selectedCM}
            isModalVisible={openImageListModal}
            handleOk={handleOk}
            setOpenCloseModal={setImageListModal}
            medias={selectedData}
            backgroundColor={backgroundColor}
            fnBulkApprove={handleApproveAllMedia}
            fnSingleApprove={handleApproveMedia}
            setSelectedMediasArray={setSelectedMediasArray}
            selectedMediasArray={selectedMediasArray}
            mediaStatus={mediaStatus}
            showVideoModal={showVideoModal}
            showVideoThumb={showVideoThumb}
            handleRecoverMedia={handleRecoverMedia}
            showDeleteModal={showDeleteModal}
         />
         {/* Image List Modal  */}
         {/* Video Preview Modal  */}
         <Modal
            title="Video"
            width={1000}
            visible={isVideoModalVisible}
            onCancel={handleCancelVideo}
            footer={[<Button onClick={handleCancelVideo}>Close</Button>]}>
            <VideoPlayer fsUrl={videoFileName} playing={play} pageType="custom-media" />
         </Modal>
         {/* Video Preview Modal  */}

         {/* modal delete media confirmation - start */}
         <Modal
            title="Reject media"
            visible={isModalVisible}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
            footer={[
               <Button
                  key="backOk"
                  type="primary"
                  loading={loader}
                  onClick={() => {
                     if (deleteMediaType === 'all') {
                        handleRejectAllMedia(deleteMedias)
                     } else {
                        handleBanMedia(deleteMediaId)
                     }
                  }}
                  style={{ background: 'green', color: 'white', borderStyle: 'none' }}>
                  Proceed
               </Button>,
               <Button
                  key="backCancel"
                  onClick={handleOk}
                  type="primary"
                  style={{ background: 'red', color: 'white', borderStyle: 'none' }}>
                  Cancel
               </Button>,
            ]}>
            <Alert
               message="Warning"
               description="You are about to reject selected media."
               type="warning"
               showIcon
            />
         </Modal>
         {/* modal delete media confirmation - end */}
      </div>
   )
}

export default CustomMediasPage
