/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
import React, { useMemo, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import ReactPlayer from 'react-player'
import moment from 'moment'
import {
   Card,
   Image,
   Pagination,
   Spin,
   Button,
   Input,
   Alert,
   Select,
   Form,
   Menu,
   Modal,
   notification,
   PageHeader,
   Row,
   Col,
   Switch,
   Tag,
} from 'antd'
import { RiDeleteBinLine, RiVidiconFill, RiArrowRightLine } from 'react-icons/ri'
import { Link, Outlet } from 'react-router-dom'
import ReactPaginate from 'react-paginate'

import {
   changePublicationMediaType,
   fetchLiveMedias,
   getMessageFileFromFileSystem,
} from '../../api/media'
import { fallBackImageBase64 } from './constant'
import ReadMore from '../../components/ReadMore'
import useBanMedia from '../../hooks/media/mutation/useBanMedia'
import { MessageMedia } from '../../components/MessageMedia/MessageMedia'
import { VideoPlayer } from '../../components/VideoPlayer/VideoPlayer'
import { CustomVideoThumbnail } from '../../components/VideoPlayer/VideoThumbnail'
import { LoadingScreen } from '../../components/LoadingScreenComponent'
import { axiosInstance } from '../../utils/axios-base'

import { LocalStorage } from '../../services'

const { Meta } = Card
const { Option } = Select

export interface IFormInput {
   searchName: string
   mediaType: string
}

export const LiveMediasPage = () => {
   // for useQuery
   const [page, setPage] = React.useState(1)
   const [limit, setPageLimit] = React.useState(10)
   const [mediaType, setMediaType] = useState('public')
   const [mediaStatus, setMediaStatus] = useState('approved')
   const [sortOrder, setSortOrder] = useState('desc')
   const [mediaFileType, setMediaFileType] = useState('')

   const [backgroundColor, setBackgroundColor] = React.useState('#e9e8e8')

   // for search
   const [searchName, setSearchName] = useState('')
   const [pageNumber, setPageNumber] = useState(1) as any // for searching page

   const [loader, setLoader] = useState(false)

   // for modals
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [isVideoModalVisible, setIsVideoModalVisible] = useState(false)

   // for ban/delete media
   const [deleteMediaId, setDeleteMediaId] = useState('')
   const banMedia = useBanMedia()
   const [btnLoader, setBtnLoader] = useState(false)

   // for video components
   const [videoFileName, setVideoFileName] = useState('')
   const [showVideoThumb, setShowVideoThumb] = useState(false)

   // for react-player
   const [play, setPlay] = useState(false)

   // to check user role, hide ban button if epoch user
   const userRole = LocalStorage.getRole()

   const videoFormats = ['.mp4', '.mov', '.wmv', '.avi', '.ogg', '.webm', '.mkv', '.mpeg']
   const imageFormats = ['.jpeg', '.png', '.jpg', '.svg']

   // populate data
   const { isLoading, data, refetch } = useQuery(
      ['live-medias', page, limit],
      () =>
         fetchLiveMedias(page, limit, searchName, mediaType, mediaStatus, sortOrder, mediaFileType),
      {
         enabled: false,
         keepPreviousData: false,
      }
   )

   const [checkedState, setCheckedState] = useState([false])

   useEffect(() => {
      if (data && data.data && data.data.length) {
         setCheckedState(new Array(data.data.length).fill(mediaType === 'private'))
      }
   }, [data, setCheckedState])

   // modal fucntions - start

   // confirmation modal
   const showModal = (selectedMediaId: string) => {
      setDeleteMediaId(selectedMediaId)
      setIsModalVisible(true)
   }

   // video modal
   const showVideoModal = (media: any) => {
      const fileUrl = media?.url?.split('/') || media?.content
      if (fileUrl.indexOf('storage.googleapis.com') > -1) {
         setVideoFileName(media?.url || media?.content)
      } else {
         setVideoFileName(media?.fsUrl || media?.content)
      }
      setPlay(true)
      setIsVideoModalVisible(true)
   }

   const handleOk = () => {
      setIsModalVisible(false)
   }

   const handleCancel = () => {
      setIsModalVisible(false)
   }

   const handleCancelVideo = async () => {
      await setPlay(false)
      setVideoFileName('')
      setIsVideoModalVisible(false)
   }
   // modal functions - end

   // notification success
   const successNotification = () => {
      notification.success({
         message: 'Media deleted',
      })
   }

   const handleBanMedia = async (mediaId: string) => {
      try {
         setBtnLoader(true)
         const response = await banMedia.mutateAsync(mediaId)
         console.log('response', response)
         if (response.data === 'success') {
            successNotification()
            setIsModalVisible(false)
            setBtnLoader(false)
            refetch()
         }
      } catch (err) {
         console.error(err)
      }
   }

   const delayComponentRender = () => {
      setTimeout(() => setShowVideoThumb(true), 2500)
   }

   useEffect(() => {
      setLoader(true)
      async function refetchData() {
         await refetch()
         setLoader(false)
      }
      delayComponentRender()
      refetchData().then()
   }, [page])

   // for search
   const handleSubmit = async (values: any) => {
      console.log('values of form: ', values)
      await setLoader(true)
      await setPage(1)
      async function refetchData() {
         await refetch()
         setLoader(false)
      }
      refetchData()
   }

   // for search page
   const handleSubmitPage = (values: any) => {
      setPage(parseInt(values.searchPage, 10))
   }

   // if input for search is empty, trigger submit
   useEffect(() => {
      if (searchName === '') {
         handleSubmit('')
      }
   }, [searchName])

   // pagination functions - start
   const handlePageClick = async (pgData: any) => {
      setLoader(true)
      await setPage(pgData.selected + 1)
      async function refetchData() {
         await refetch()
         setLoader(false)
      }
      refetchData()
   }

   const handleChangeMediaType = async (_mediaType: any) => {
      await setMediaType(_mediaType)
      setLoader(true)
      await setPage(1)
      async function refetchData() {
         await refetch()
         setLoader(false)
      }
      refetchData()
   }

   const handleSwitchPrivateOrPublic = async (media: any, position: number) => {
      // switching media from private to public is currently not allowed
      if (checkedState.find((_, index) => index === position) === true) {
         notification.error({ message: 'Switching from private to public is currently disable' })

         return
      }

      // update state for specific switch
      const updatedCheckedState = checkedState.map((item, index) =>
         index === position ? !item : item
      )

      setCheckedState(updatedCheckedState)

      try {
         // api call to change public media to private
         await changePublicationMediaType(media._id)

         notification.success({
            message: 'Media Type Changed Successfully',
         })
      } catch (error) {
         notification.error({ message: 'Failed Changing Media Type' })
         setCheckedState(new Array(data.data.length).fill(mediaType === 'private'))
      }
   }

   const handleMediaUrl = (media: any) => {
      const fileUrl = media?.url.split('/')
      let mediaUrl = ''
      if (fileUrl.indexOf('storage.googleapis.com') > -1) {
         mediaUrl = media?.url
      } else {
         mediaUrl = `${process.env.REACT_APP_API_URL}/admin/medias/filename/${media.filename}`
      }
      return mediaUrl
   }

   return (
      <>
         <div className="content-wrapper">
            <PageHeader className="site-page-header" title="Approved Medias" />
            <Row justify="space-around" style={{ marginBottom: '20px' }}>
               <Col flex="500px">
                  {/* search div - start */}
                  <Form className="search-form" onFinish={handleSubmit} layout="inline">
                     <Form.Item label="Search" name="searchName" style={{ marginRight: '10px' }}>
                        <Input
                           placeholder="search..."
                           value={searchName}
                           onChange={(e) => setSearchName(e.target.value)}
                           allowClear
                        />
                     </Form.Item>
                     <Form.Item>
                        <Button type="primary" htmlType="submit">
                           Search
                        </Button>
                     </Form.Item>
                  </Form>
                  {/* search div - end */}
               </Col>
               <Col flex="auto">
                  {/* sub menu items - start */}
                  <div className="wall-menu-container">
                     <Menu
                        mode="horizontal"
                        defaultSelectedKeys={['/live-medias']}
                        className="menu-flex-end">
                        <Menu.Item key="/live-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('public')}>
                              Public Wall
                           </button>
                        </Menu.Item>
                        <Menu.Item key="/private-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('private')}>
                              Private Wall
                           </button>
                        </Menu.Item>
                        <Menu.Item key="/chat-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('chat')}>
                              Chat
                           </button>
                        </Menu.Item>
                        <Menu.Item key="/profile-image">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={() => handleChangeMediaType('profile-image')}>
                              Profile Photos
                           </button>
                        </Menu.Item>
                     </Menu>
                  </div>
                  {/* sub menu items - end */}
               </Col>
            </Row>

            {loader && <LoadingScreen />}

            {(!isLoading || !loader) && (
               <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                  {data &&
                     data.data.map((media: any, index: any) => (
                        <div key={media._id} id={index} className="approved-card-wrapper">
                           <Card
                              bordered={false}
                              style={{
                                 width: 250,
                                 margin: '10px 20px',
                                 background: backgroundColor,
                              }}
                              className="media-card">
                              {/* card header, display username and createdAt */}
                              {media && media?.type?.includes('Files') ? (
                                 <>
                                    <div
                                       style={{
                                          display: 'grid',
                                          gridAutoFlow: 'column',
                                          gridColumnGap: '10px',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          marginBottom: '10px',
                                       }}>
                                       <div>
                                          <Meta
                                             title={media?.from?.username || media?.user?.username}
                                          />
                                       </div>
                                       <div
                                          style={{
                                             display: 'flex',
                                             alignItems: 'center',
                                          }}>
                                          <RiArrowRightLine
                                             style={{
                                                position: 'relative',
                                                fontSize: '24px',
                                             }}
                                          />
                                       </div>
                                       <div>
                                          <Meta title={media?.to?.username} />
                                       </div>
                                    </div>
                                    <div
                                       style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                       }}>
                                       <Meta
                                          description={moment(media?.createdAt).format(
                                             'DD/MM/yyyy, HH:mm'
                                          )}
                                       />
                                    </div>
                                 </>
                              ) : (
                                 <Meta
                                    title={media?.user?.username}
                                    description={moment(media?.createdAt).format(
                                       'DD/MM/yyyy, HH:mm'
                                    )}
                                 />
                              )}
                              <br />

                              {media && media?.convoId && <Tag color="blue">Chat</Tag>}
                              {media && media?.private && <Tag color="red">Private</Tag>}
                              {media && media?.private === false && <Tag color="green">Public</Tag>}

                              {media && media?.type?.includes('profile-image') && (
                                 <Tag color="pink">Profile Photo</Tag>
                              )}

                              {/* card content */}
                              <div style={{ padding: '10px 0', position: 'relative' }}>
                                 {/* video start */}
                                 {media && media?.type?.includes('video') && (
                                    <div
                                       className="video-container"
                                       onClick={() => showVideoModal(media)}
                                       onKeyDown={() => showVideoModal(media)}
                                       aria-hidden="true">
                                       <RiVidiconFill
                                          style={{
                                             color: 'white',
                                             fontSize: '30px',
                                             position: 'absolute',
                                             left: '5px',
                                             cursor: 'pointer',
                                          }}
                                       />

                                       {!showVideoThumb ? (
                                          <div className="video-loader">video loading...</div>
                                       ) : (
                                          <CustomVideoThumbnail
                                             fsUrl={
                                                media?.thumbnail_url || media?.thumbnail_content
                                                   ? media?.thumbnail_url ||
                                                     media?.thumbnail_content
                                                   : media?.url
                                             }
                                          />
                                       )}
                                    </div>
                                 )}
                                 {/* video end */}

                                 {/* image start */}
                                 {media && media?.type?.includes('image') && (
                                    <div className="img-wrapper">
                                       <Image
                                          src={handleMediaUrl(media)}
                                          fallback={fallBackImageBase64}
                                          placeholder={
                                             <div
                                                style={{
                                                   display: 'flex',
                                                   justifyContent: 'center',
                                                   alignItems: 'center',
                                                   height: '100%',
                                                }}>
                                                <Spin size="small" />
                                             </div>
                                          }
                                       />
                                    </div>
                                 )}
                                 {/* image end */}

                                 {/* if media is video & from a private message */}
                                 {media &&
                                    media?.type?.includes('Files') &&
                                    videoFormats.some((v) => media?.content.includes(v)) && (
                                       <div
                                          className="video-container"
                                          onClick={() => showVideoModal(media)}
                                          onKeyDown={() => showVideoModal(media)}
                                          aria-hidden="true">
                                          <RiVidiconFill
                                             style={{
                                                color: 'white',
                                                fontSize: '30px',
                                                position: 'absolute',
                                                left: '5px',
                                                cursor: 'pointer',
                                             }}
                                          />
                                          {!showVideoThumb ? (
                                             <div className="video-loader">video loading...</div>
                                          ) : (
                                             <CustomVideoThumbnail
                                                fsUrl={
                                                   media?.thumbnail_url || media?.thumbnail_content
                                                      ? media?.thumbnail_url ||
                                                        media?.thumbnail_content
                                                      : media?.content
                                                }
                                             />
                                          )}
                                       </div>
                                    )}

                                 {/* if media is image & from a private message */}
                                 {media &&
                                    media?.type?.includes('Files') &&
                                    imageFormats.some((v) => media?.content.includes(v)) && (
                                       <MessageMedia content={media?.content} />
                                    )}

                                 {/* Ban Media Button */}
                                 {userRole === 'admin' && (
                                    <div
                                       style={{
                                          background: '#f2f2f2',
                                          color: '#e5195f',
                                          position: 'absolute',
                                          bottom: '18px',
                                          right: '10px',
                                          fontSize: '26px',
                                          cursor: 'pointer',
                                          padding: '4px',
                                          borderRadius: '5px',
                                          height: '2.5rem',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          gap: '10px',
                                       }}>
                                       {mediaType === 'public' && (
                                          <Switch
                                             checkedChildren="Private"
                                             unCheckedChildren="Public"
                                             onClick={() =>
                                                handleSwitchPrivateOrPublic(media, index)
                                             }
                                             checked={checkedState[index]}
                                          />
                                       )}

                                       <RiDeleteBinLine onClick={() => showModal(media?._id)} />
                                    </div>
                                 )}
                              </div>
                              <Meta description={<ReadMore>{media}</ReadMore>} />
                           </Card>
                        </div>
                     ))}
               </div>
            )}

            {/* PAGINATION START */}
            {data && data?.totalPages ? (
               <div
                  className="pagination-container"
                  style={{ display: 'flex', justifyContent: 'center' }}>
                  <Form
                     onFinish={handleSubmitPage}
                     layout="inline"
                     style={{ alignItems: 'center' }}>
                     <Form.Item name="searchPage" style={{ marginRight: '10px', width: 100 }}>
                        <Input
                           placeholder="search page..."
                           value={pageNumber}
                           type="number"
                           max={data.totalPages}
                           min={1}
                           onChange={(e) => setPageNumber(e.target.value)}
                           allowClear
                        />
                     </Form.Item>
                     <Form.Item>
                        <Button type="primary" htmlType="submit">
                           Search Page
                        </Button>
                     </Form.Item>
                  </Form>
                  <ReactPaginate
                     forcePage={page - 1}
                     pageCount={data?.totalPages}
                     breakLabel="..."
                     previousLabel="<<"
                     nextLabel=">>"
                     containerClassName="pagination"
                     previousLinkClassName="pagination__link"
                     nextLinkClassName="pagination__link"
                     disabledClassName="pagination__link--disabled"
                     activeClassName="pagination__link--active"
                     marginPagesDisplayed={1}
                     pageRangeDisplayed={1}
                     onPageChange={handlePageClick}
                  />
               </div>
            ) : (
               ''
            )}
            {/* PAGINATION END */}

            {/* modal delete media confirmation - start */}
            <Modal
               title="Delete media"
               visible={isModalVisible}
               onOk={handleOk}
               onCancel={handleCancel}
               footer={[
                  <Button
                     key="back"
                     type="primary"
                     loading={btnLoader}
                     onClick={() => handleBanMedia(deleteMediaId)}
                     style={{ background: 'green', color: 'white', borderStyle: 'none' }}>
                     Proceed
                  </Button>,
                  <Button
                     key="back"
                     onClick={handleOk}
                     type="primary"
                     style={{ background: 'red', color: 'white', borderStyle: 'none' }}>
                     Cancel
                  </Button>,
               ]}>
               <Alert
                  message="Warning"
                  description="You are about to delete this media. This can be recovered in the deleted medias page"
                  type="warning"
                  showIcon
               />
            </Modal>
            {/* modal delete media confirmation - end */}

            {/* modal video player - start */}
            <Modal
               title="Video"
               width={1000}
               visible={isVideoModalVisible}
               onCancel={handleCancelVideo}
               footer={[<Button onClick={handleCancelVideo}>Close</Button>]}>
               <VideoPlayer fsUrl={videoFileName} playing={play} />
            </Modal>
            {/* modal video player - end */}
         </div>
      </>
   )
}
