import { useMutation, useQueryClient } from 'react-query'
import { axiosInstance } from '../../../utils/axios-base'

export default function useUpdateUser() {
   const queryClient = useQueryClient()
   return useMutation(
      async ({ user, data }: { user: string; data: any }) => {
         const response = await axiosInstance.get(`/admin/feet-model/${user}/process-id-check`, {
            params: data,
         })
         return response
      },
      {
         onSuccess: () => {
            // refetch live medias
            queryClient.invalidateQueries('users')
         },
         onMutate: (deletedUser) => {
            return deletedUser
         },
         // If the mutation fails, use the value returned from onMutate to roll back
         onError: (error, variables, context: any) => {
            // An error happened!
            console.log(`rolling back optimistic update with id ${context?.id}`)
         },
         // Always refetch after error or success:
         onSettled: () => {
            // Error or success... doesn't matter!
            queryClient.invalidateQueries('users')
         },
      }
   )
}
