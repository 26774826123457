import { axiosInstance, getAuthSession } from '../utils/axios-base'

// for fetching medias (approved medias, moderated medias (images, videos) )
export async function fetchLiveMedias(
   pageNum = 1,
   pageLimit = 10,
   searchName = '',
   mediaType = 'public',
   mediaStatus = '',
   sortOrder = '',
   mediaFileType = ''
) {
   const response = await axiosInstance.get(`/admin/medias?`, {
      params: {
         page: pageNum,
         limit: pageLimit,
         q: searchName,
         sortBy: 'createdAt',
         orderBy: sortOrder,
         type: mediaType,
         status: mediaStatus,
         media: mediaFileType,
      },
   })

   return response.data
}

// fetch deleted (banned) medias
export async function fetchDeletedMedias(
   pageNum = 0,
   pageLimit = 10,
   searchName = '',
   mediaType = 'public'
) {
   const response = await axiosInstance.get(`/admin/ban?`, {
      params: {
         page: pageNum,
         limit: pageLimit,
         q: searchName,
         sortBy:
            mediaType && mediaType.includes('chat') ? 'message.createdAt' : 'publication.createdAt',
         orderBy: 'desc',
         type: mediaType,
      },
   })

   return response.data
}

// get medias from private messages
export const getMessageFileFromFileSystem = async (encryptedFilename = '') => {
   const headers = {
      Authorization: `Bearer ${getAuthSession()}`,
   }

   const response = await axiosInstance.post(`/admin/medias/message_media/${encryptedFilename}`, {
      headers,
      responseType: 'Blob',
   })
   console.log('here', response)
   return response.data
}

export const getVideoFile = async (fileName = '') => {
   const headers = {
      Authorization: `Bearer ${getAuthSession()}`,
   }

   const { data } = await axiosInstance.post(`/admin/medias/publication_media/${fileName}`, {
      headers,
      responseType: 'blob',
   })

   return data
}

// individual approval
export async function approveMedia(mediaId = '') {
   const response = await axiosInstance.post(`/admin/medias/approve/${mediaId}`)

   return response
}

// bulk approval
export async function approveAllMedia(mediaIds = []) {
   const headers = {
      Authorization: `Bearer ${getAuthSession()}`,
   }
   const response = await axiosInstance.post(
      `/admin/medias/approve`,
      {
         mediaIds,
      },
      { headers }
   )

   return response
}
// bulkBan
export async function banAllMedia(mediaIds = []) {
   const headers = {
      Authorization: `Bearer ${getAuthSession()}`,
   }
   const response = await axiosInstance.post(
      `/admin/ban/selected`,
      {
         mediaIds,
      },
      { headers }
   )

   return response
}

export async function moderateProfilePic(mediaId = '', body = { status: '' }) {
   const response = await axiosInstance.post(
      `/admin/medias/${mediaId}/process-pending-profile-image`,
      body
   )

   return response
}

export const changePublicationMediaType = async (mediaId = '', status = 'pending') => {
   const body = {
      status,
   }
   const response = await axiosInstance.post(
      `/admin/medias/${mediaId}/process-publication-conversion`,
      body
   )

   return response
}
