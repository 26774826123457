import { axiosInstance } from '../utils/axios-base'

export async function deleteUser(userID: any) {
   const response = await axiosInstance.delete(`admin/feety-users/${userID}`)

   return response.data
}

export async function restoreUser(userID: any) {
   const response = await axiosInstance.post(`admin/feety-users/${userID}`)

   return response.data
}

export async function fetchUsers(
   pageNum = 1,
   pageLimit = 10,
   searchName = '',
   sortBy = 'createdAt',
   orderBy = 'desc',
   filter = {}
) {
   const response = await axiosInstance.get(`/admin/feety-users/list`, {
      params: {
         page: pageNum,
         limit: pageLimit,
         q: searchName,
         sortBy,
         orderBy,
         filter,
      },
   })
   return response.data
}

export async function fetchUsersStats() {
   const response = await axiosInstance.get(`/admin/feety-users/stats`, {})
   return response.data
}
