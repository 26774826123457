import React, { useState, useEffect } from 'react'

export const LoadingScreen: React.FC = () => {
   return (
      <div
         id="overlayLoader"
         style={{
            position: 'fixed',
            display: 'block',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0,0,0,0.8)',
            zIndex: 2,
         }}>
         <img
            style={{
               display: 'flex',
               flexWrap: 'wrap',
               justifyContent: 'center',
               position: 'absolute',
               top: '50%',
               left: '50%',
               color: 'white',
               transform: 'translate(-50%,-110%)',
               width: '200px',
            }}
            src="/logo512.png"
            alt="logo"
         />
         <h5
            style={{
               display: 'flex',
               flexWrap: 'wrap',
               justifyContent: 'center',
               position: 'absolute',
               top: '50%',
               left: '50%',
               fontSize: '15px',
               color: 'white',
               transform: 'translate(-50%,-50%)',
            }}>
            L O A D I N G . . .
         </h5>
      </div>
   )
}
