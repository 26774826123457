import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import moment from 'moment/moment'
import { Select } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useFilters, useTable } from 'react-table'
import { TableLayout } from '../../../../../components/TableLayout'

export const GainedTableInstance = ({
   tbData,
   setFilterData,
   setSortField,
   sortField,
   sort,
   setSort,
   fieldDateValue,
   totalsRowData,
   totalsAmountData,
   totalsTicklesData,
}: any) => {
   const [filterTypeValues, setFilterTypeValues] = useState([]) as any
   const [filterStatValues, setFilterStatValues] = useState([]) as any
   const [checked, setChecked] = useState(true)
   let filterPack: any = {}
   let packMessageValue: any[] = []
   let packPrivateValue: any[] = []
   let packTypeValue: any[] = []

   const optionCodeValues: any[] = [
      { label: 'Abonnement', value: 'Abonnement' },
      { label: 'Custom', value: 'Custom' },
      { label: 'Tips public wall', value: 'Tips public wall' },
      { label: 'Tips private wall', value: 'Tips private wall' },
      { label: 'Tips messagerie', value: 'Tips messagerie' },
   ]

   const finalFilterDate = () => {
      if (fieldDateValue && fieldDateValue[0] && fieldDateValue[1]) {
         const startDate = `${moment
            .utc(fieldDateValue[0].toDate())
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastDate = `${moment
            .utc(fieldDateValue[1].toDate())
            .format('yyyy-MM-DD')}T23:59:59.999Z`
         filterPack.createdAt = {
            $gte: moment.utc(startDate),
            $lte: moment.utc(lastDate),
         }
      } else {
         delete filterPack.createdAt
      }
   }

   const onChange = (checkedValues: CheckboxValueType[], type: string) => {
      setChecked(false)
      setFilterTypeValues([...checkedValues])
      if (checkedValues.length > 0) {
         if (type === 'type') {
            checkedValues.map((item) => {
               if (item.toString().includes('private')) {
                  packPrivateValue.push(true)
               } else if (item.toString().includes('public')) {
                  packPrivateValue.push(false)
               } else if (item.toString().includes('messagerie')) {
                  packMessageValue.push('Tickles')
               } else if (item.toString().includes('Custom')) {
                  packMessageValue.push('Custom-Media')
               } else if (item.toString().includes('Abonnement')) {
                  packTypeValue.push('subscriptions')
               }
               return null
            })

            if (filterStatValues.length > 0) {
               if (filterStatValues.toString() === 'Gain') {
                  filterPack = {
                     $or: [
                        { 'message.type': { $in: [...packMessageValue] } },
                        { 'publication.private': { $in: [...packPrivateValue] } },
                        { type: { $in: [...packTypeValue] } },
                     ],
                     status: { $in: [filterStatValues.toString().toLowerCase()] },
                  }
               } else if (filterStatValues.toString() === 'Withdrawn') {
                  filterPack = {
                     $or: [
                        { 'message.type': { $in: [...packMessageValue] } },
                        { 'publication.private': { $in: [...packPrivateValue] } },
                        { type: { $in: [...packTypeValue] } },
                     ],
                     status: { $in: [filterStatValues.toString().toLowerCase()] },
                  }
               } else {
                  filterPack = {
                     $or: [
                        { 'message.type': { $in: [...packMessageValue] } },
                        { 'publication.private': { $in: [...packPrivateValue] } },
                        { type: { $in: [...packTypeValue] } },
                     ],
                     status: { $in: ['gain', 'withdrawn'] },
                  }
               }
            } else {
               filterPack = {
                  $or: [
                     { 'message.type': { $in: [...packMessageValue] } },
                     { 'publication.private': { $in: [...packPrivateValue] } },
                     { type: { $in: [...packTypeValue] } },
                  ],
                  status: { $in: ['gain', 'withdrawn'] },
               }
            }
         }
      } else {
         packPrivateValue = []
         packMessageValue = []
         packTypeValue = []
         setChecked(true)
         setFilterTypeValues([])
         if (filterStatValues.length > 0) {
            if (filterStatValues.toString() === 'Gain') {
               filterPack = { status: { $in: [filterStatValues.toString().toLowerCase()] } }
            } else if (filterStatValues.toString() === 'Withdrawn') {
               filterPack = { status: { $in: [filterStatValues.toString().toLowerCase()] } }
            } else {
               filterPack = { status: { $in: ['gain', 'withdrawn'] } }
            }
         } else {
            filterPack = { status: { $in: ['gain', 'withdrawn'] } }
         }
      }
      finalFilterDate()
      setFilterData(filterPack)
   }
   const handleClear = (type: string) => {
      if (type === 'status') {
         if (filterTypeValues.length > 0) {
            filterTypeValues.map((item: any) => {
               if (item.toString().includes('private')) {
                  packPrivateValue.push(true)
               } else if (item.toString().includes('public')) {
                  packPrivateValue.push(false)
               } else if (item.toString().includes('messagerie')) {
                  packMessageValue.push('Tickles')
               } else if (item.toString().includes('Custom')) {
                  packMessageValue.push('Custom-Media')
               } else if (item.toString().includes('Abonnement')) {
                  packTypeValue.push('subscriptions')
               }
               return null
            })
            filterPack = {
               $or: [
                  { 'message.type': { $in: [...packMessageValue] } },
                  { 'publication.private': { $in: [...packPrivateValue] } },
                  { type: { $in: [...packTypeValue] } },
               ],
               status: { $in: ['gain', 'withdrawn'] },
            }
         } else {
            filterPack = { status: { $in: ['gain', 'withdrawn'] } }
         }
         setFilterStatValues([])
      } else if (type === 'types') {
         packPrivateValue = []
         packMessageValue = []
         packTypeValue = []
         setFilterTypeValues([])
         if (filterStatValues.length > 0) {
            if (filterStatValues.toString() === 'Gain') {
               filterPack = { status: { $in: [filterStatValues.toString().toLowerCase()] } }
            } else if (filterStatValues.toString() === 'Withdrawn') {
               filterPack = { status: { $in: [filterStatValues.toString().toLowerCase()] } }
            } else {
               filterPack = { status: { $in: ['gain', 'withdrawn'] } }
            }
         } else {
            filterPack = { status: { $in: ['gain', 'withdrawn'] } }
         }
      }
      finalFilterDate()
      setFilterData(filterPack)
      setChecked(true)
   }
   const handleTypeOfSell = (rowData: any) => {
      let results = ''
      switch (rowData.type) {
         case 'tips chat':
            results = 'Tips messagerie'
            break
         case 'tips custom media':
            results = rowData.message.type
            break
         case 'tips public wall':
            if (rowData.publication.type.includes('image')) results = 'Tips public photo'
            else if (rowData.publication.type.includes('video')) results = 'Tips public video'
            else results = ''
            break
         case 'tips private wall':
            if (rowData.publication.type.includes('image')) results = 'Tips private photo'
            else if (rowData.publication.type.includes('video')) results = 'Tips private video'
            else results = ''
            break
         case 'subscriptions':
            results = 'Abonnement'
            break
         case 'tickles':
            results = 'Tickles'
            break
         default:
            break
      }
      return results
   }
   const handleCalculations = (data: any) => {
      const total =
         data.original?.type && data.original?.type.includes('subscriptions')
            ? (data.original?.amount &&
                 Number(
                    data.original?.amount.toString()
                    //   .slice(0, data.original?.amount.toString().indexOf('.') + 3)
                 )) ||
              0.0
            : (data.original?.amountInEur &&
                 Number(
                    data.original?.amountInEur.toString()
                    //   .slice(0, data.original?.amountInEur.toString().indexOf('.') + 3)
                 )) ||
              0.0
      return Number(total.toString())
   }

   const handleChangeStatus = (checkedValues: any, type: string) => {
      setFilterStatValues([...checkedValues])

      if (filterTypeValues.length > 0) {
         filterTypeValues.map((item: any) => {
            if (item.toString().includes('private')) {
               packPrivateValue.push(true)
            } else if (item.toString().includes('public')) {
               packPrivateValue.push(false)
            } else if (item.toString().includes('messagerie')) {
               packMessageValue.push('Tickles')
            } else if (item.toString().includes('Custom')) {
               packMessageValue.push('Custom-Media')
            } else if (item.toString().includes('Abonnement')) {
               packTypeValue.push('subscriptions')
            }
            return null
         })
      }

      if (checkedValues.length > 0) {
         if (filterTypeValues.length > 0) {
            if (checkedValues.toString() === 'Gain') {
               filterPack = {
                  $or: [
                     { 'message.type': { $in: [...packMessageValue] } },
                     { 'publication.private': { $in: [...packPrivateValue] } },
                     { type: { $in: [...packTypeValue] } },
                  ],
                  status: { $in: [checkedValues.toString().toLowerCase()] },
               }
            } else if (checkedValues.toString() === 'Withdrawn') {
               filterPack = {
                  $or: [
                     { 'message.type': { $in: [...packMessageValue] } },
                     { 'publication.private': { $in: [...packPrivateValue] } },
                     { type: { $in: [...packTypeValue] } },
                  ],
                  status: { $in: [checkedValues.toString().toLowerCase()] },
               }
            } else {
               filterPack = {
                  $or: [
                     { 'message.type': { $in: [...packMessageValue] } },
                     { 'publication.private': { $in: [...packPrivateValue] } },
                     { type: { $in: [...packTypeValue] } },
                  ],
                  status: { $in: ['gain', 'withdrawn'] },
               }
            }
         } else if (filterTypeValues.length === 0 || !filterTypeValues) {
            if (type === 'status' && checkedValues.toString() === 'Gain') {
               filterPack = { status: { $in: [checkedValues.toString().toLowerCase()] } }
            } else if (type === 'status' && checkedValues.toString() === 'Withdrawn') {
               filterPack = { status: { $in: [checkedValues.toString().toLowerCase()] } }
            } else {
               filterPack = { status: { $in: ['gain', 'withdrawn'] } }
            }
         }
      } else {
         setChecked(true)
         setFilterStatValues([])
         if (filterTypeValues.length > 0) {
            filterPack = {
               $or: [
                  { 'message.type': { $in: [...packMessageValue] } },
                  { 'publication.private': { $in: [...packPrivateValue] } },
                  { type: { $in: [...packTypeValue] } },
               ],
               status: { $in: ['gain', 'withdrawn'] },
            }
         } else {
            filterPack = { status: { $in: ['gain', 'withdrawn'] } }
         }
      }
      finalFilterDate()
      setFilterData(filterPack)
   }

   const [columns, data, totalsRow, totalAmount, totalTickles] = useMemo(() => {
      const columnsData = [
         {
            Header: 'Pseudo Feetgirl',
            accessor: 'feetModel.username',
            Footer: (column: any) => (
               <span>
                  <span style={{ fontSize: '12px', display: 'flex' }}>Total</span>
               </span>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     <p style={{ color: '#FF1493', fontWeight: 'bolder' }}>
                        {cell.row.original?.feetModel?.username}
                     </p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Mail Feetgirl',
            accessor: 'feetModel.email',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.feetModel?.email}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Date Bank Transfer Request',
            accessor: 'datedRequested',
            Footer: (column: any) => (
               <div>
                  <span>
                     <br />
                     <p>
                        {Number(
                           _.sum(
                              _.map(column.rows, (d) =>
                                 d.original?.type &&
                                 d.original?.type.includes('withdrawals') &&
                                 d.original?.euro
                                    ? d.original?.euro
                                    : 0
                              )
                           ).toFixed(2)
                        )
                           .toLocaleString()
                           .replaceAll(',', "'")}
                        &euro;
                     </p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     <span>
                        {cell.row.original?.type &&
                        cell.row.original?.type.includes('withdrawals') ? (
                           <span>
                              {moment.utc(cell.row.original?.datedRequested).format('DD/MM/yyyy')}{' '}
                              {moment.utc(cell.row.original?.datedRequested).format('HH:mm:ss')}
                              <br />
                              {Number(cell.row.original?.euro).toLocaleString()}&euro;
                           </span>
                        ) : null}
                     </span>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Pseudo Feetlover',
            accessor: 'feetLover.username',
            Footer: (column: any) => (
               <div>
                  <span>
                     <br />
                     <p>
                        {
                           column.rows.filter(
                              (item: any) =>
                                 (item.original?.feetLover?.username &&
                                    item.original?.feetLover?.username !== '') ||
                                 (item.original?.feetLover?.email &&
                                    item.original?.feetLover?.email !== '')
                           ).length
                        }
                     </p>
                  </span>
                  <span>
                     <p>{totalsRow}</p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     <p style={{ color: '#0000CD', fontWeight: 'bolder' }}>
                        {cell.row.original?.feetLover?.username ||
                           cell.row.original?.feetLover?.email}
                     </p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'ID sell',
            accessor: 'idcounter',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.id}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Amount in €',
            accessor: 'tickles',
            Footer: (column: any) => (
               <div>
                  <span>
                     <br />
                     <p>
                        {Number(
                           (
                              Math.round(
                                 _.sum(
                                    _.map(column.rows, (d) =>
                                       d.original?.type && !d.original?.type.includes('withdrawals')
                                          ? handleCalculations(d)
                                          : 0
                                    )
                                 ) * 100
                              ) / 100
                           ).toFixed(2)
                        )
                           .toLocaleString()
                           .replaceAll(',', "'")}
                        &euro;
                     </p>
                  </span>
                  <span>
                     <p>
                        {Number((Math.round(totalAmount * 100) / 100).toFixed(2))
                           .toLocaleString()
                           .replaceAll(',', "'")}
                        &euro;
                     </p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     {cell.row.original?.type &&
                     !cell.row.original?.type.includes('withdrawals') ? (
                        <p>
                           {handleCalculations(cell.row)
                              .toFixed(2)
                              .toLocaleString()
                              .replaceAll(',', "'")}
                           &euro;
                        </p>
                     ) : null}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Type of sell',
            accessor: 'countertype',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{handleTypeOfSell(cell.row.original)}</p>
                  </div>
               )
            },
            Filter: (filter: any) => {
               return (
                  <>
                     <Select
                        mode="multiple"
                        allowClear
                        style={{
                           width: '100%',
                        }}
                        placeholder="Please select"
                        onChange={(checkedValue) => onChange(checkedValue, 'type')}
                        options={optionCodeValues}
                        defaultValue={filterTypeValues}
                        onClear={() => handleClear('types')}
                     />
                  </>
               )
            },
         },
         {
            Header: 'Amount in Tickles',
            accessor: 'counter.tickles',
            Footer: (column: any) => (
               <div>
                  <span>
                     <br />
                     <p>
                        {_.sum(_.map(column.rows, (d) => d.original?.tickles || 0))
                           .toLocaleString()
                           .replaceAll(',', "'")}
                     </p>
                  </span>
                  <span>
                     <p>{Number(totalTickles).toLocaleString().replaceAll(',', "'")}</p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.tickles}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Created Date of sell',
            accessor: 'createdAt',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.createdAt).format('DD/MM/yyyy')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Created Time of sell',
            accessor: 'createdtimeofsell',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.createdAt).format('HH:mm:ss')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Status',
            accessor: 'counterstatus',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>
                        {cell.row.original?.status &&
                           (cell.row.original?.status === 'gain' ? 'Gain' : 'Withdrawn')}
                     </p>
                  </div>
               )
            },
            Filter: (filter: any) => {
               return (
                  <>
                     <Select
                        mode="multiple"
                        allowClear
                        style={{
                           width: '100%',
                        }}
                        placeholder="Please select"
                        onChange={(checkedValue) => handleChangeStatus(checkedValue, 'status')}
                        options={[
                           { label: 'Gained', value: 'Gain' },
                           { label: 'Withdrawn', value: 'Withdrawn' },
                        ]}
                        defaultValue={filterStatValues}
                        onClear={() => handleClear('status')}
                     />
                  </>
               )
            },
         },
      ]
      return [columnsData, tbData, totalsRowData, totalsAmountData, totalsTicklesData]
   }, [tbData, totalsRowData, totalsAmountData, totalsTicklesData])

   const tableInstance = useTable(
      {
         columns,
         data,
         initialState: {
            hiddenColumns: [
               'accountSoftDeleted',
               'listOfMediaBans',
               'reported',
               'reporting',
               'warnings',
            ],
         },
      },
      useFilters
   )

   return (
      <TableLayout
         {...tableInstance}
         setSortField={setSortField}
         sortField={sortField}
         sort={sort}
         setSort={setSort}
      />
   )
}
