import { axiosInstance, getAuthSession } from '../utils/axios-base'

export async function recoverMedia(mediaId = '', stats = '') {
   const headers = {
      Authorization: `Bearer ${getAuthSession()}`,
   }

   const response = await axiosInstance.post(
      `/admin/ban/${mediaId}/recover`,
      { status: stats },
      { headers }
   )

   return response
}
