import axios from 'axios'

export const axiosInstance = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
})

axiosInstance.interceptors.request.use(function (config: any) {
   const token = localStorage.getItem('token')

   // eslint-disable-next-line no-param-reassign
   config.headers.Authorization = token ? `Bearer ${token}` : ''
   return config
})

export function getAuthSession() {
   const authData = window.sessionStorage.getItem('token')
   let parsedData = {}

   if (authData) {
      parsedData = JSON.parse(authData)
   }

   return parsedData
}
