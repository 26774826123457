import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import './index.css'
import './assets/css/responsive.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './context/authentication'
import { GlobalCSSReset } from './styled/GlobalCSSReset'

export const queryClient = new QueryClient()

ReactDOM.render(
   <React.StrictMode>
      <QueryClientProvider client={queryClient} contextSharing>
         <AuthProvider>
            <GlobalCSSReset />
            <App />
         </AuthProvider>
         <ReactQueryDevtools />
      </QueryClientProvider>
   </React.StrictMode>,
   document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
