import { useMutation, useQueryClient } from 'react-query'

import { approveMedia } from '../../../api/media'

export default function useApproveMedia(key = 'moderation-photos') {
   const queryClient = useQueryClient()
   return useMutation(
      async (mediaId: string) => {
         const response = await approveMedia(mediaId)

         return response
      },
      {
         onSuccess: () => {
            // refetch live medias
            queryClient.invalidateQueries(key)
         },
         //  onMutate: (deletedMedia) => {
         //     return deletedMedia
         //  },
         // onMutate: () => {},
         // If the mutation fails, use the value returned from onMutate to roll back
         onError: (error, variables, context: any) => {
            // An error happened!
            console.log(`rolling back optimistic update with id ${context?.id}`)
         },
         // Always refetch after error or success:
         onSettled: () => {
            // Error or success... doesn't matter!
            queryClient.invalidateQueries('moderation-photos')
         },
      }
   )
}
