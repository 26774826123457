import { axiosInstance } from '../utils/axios-base'

export async function fetchPurchaseList(
   pageNum = 1,
   pageLimit = 10,
   searchName = '',
   filterValue: any = {},
   sortField = 'createdAt',
   sortBy = -1
) {
   const response = await axiosInstance.get(`/admin/payment/list`, {
      params: {
         page: pageNum,
         limit: pageLimit,
         q: searchName,
         filter: filterValue,
         sort: { [sortField]: sortBy },
      },
   })
   return response.data
}

export async function fetchProductList(pageLimit = 100) {
   const response = await axiosInstance.get(`/admin/product/list`, {
      params: {
         limit: pageLimit,
      },
   })
   return response.data
}

export async function fetchSpentTicklesList(
   pageNum = 1,
   pageLimit = 10,
   searchName = '',
   hasFilter = true,
   filterValue: any = {},
   sortField = 'createdAt',
   sortBy = -1
) {
   const config = hasFilter
      ? {
           params: {
              page: pageNum,
              limit: pageLimit,
              q: searchName,
              filter: filterValue,
              sort: { [sortField]: sortBy },
           },
        }
      : {
           params: {
              page: pageNum,
              limit: pageLimit,
              q: searchName,
              sort: { [sortField]: sortBy },
           },
        }
   const response = await axiosInstance.get(`/admin/tickles/list`, config)
   return response.data
}

export async function fetchFeetGirlPendingList(
   pageNum = 1,
   pageLimit = 10,
   searchName = '',
   filterValue: any = {},
   sortField = 'createdAt',
   sortBy = -1
) {
   const response = await axiosInstance.get(`/admin/feet-model/get-pending-wallet-list`, {
      params: {
         page: pageNum,
         limit: pageLimit,
         q: searchName,
         filter: filterValue,
         sort: { [sortField]: sortBy },
      },
   })
   return response.data
}

export async function fetchCustomMediaMessages(
   pageNum = 1,
   pageLimit = 10,
   searchName = '',
   filterValue: any = {}
) {
   const response = await axiosInstance.get('admin/message/custom-media', {
      params: {
         page: pageNum,
         limit: pageLimit,
         q: searchName,
         filter: filterValue,
      },
   })
   return response.data.customMedia
}
export async function fetchDisputes(
   pageNum = 1,
   pageLimit = 10,
   searchName = '',
   filterValue: any = {}
) {
   const response = await axiosInstance.get('admin/disputes/list', {
      params: {
         page: pageNum,
         limit: pageLimit,
         q: searchName,
         filter: filterValue,
      },
   })
   return response.data
}

export async function fetchFeetGirlEarningList(
   pageNum = 1,
   pageLimit = 10,
   searchName = '',
   filterValue: any = {},
   sortField = 'customizedUpdatedAt',
   sortBy = -1
) {
   const response = await axiosInstance.get(`admin/feet-model/get-earned-wallet-list`, {
      params: {
         page: pageNum,
         limit: pageLimit,
         q: searchName,
         filter: filterValue,
         sort: { [sortField]: sortBy },
      },
   })
   return response.data
}

export async function fetchTransferRequestList(
   pageNum = 1,
   pageLimit = 10,
   searchName = '',
   filterValue: any = {}
) {
   const response = await axiosInstance.get(`admin/withdrawal/list`, {
      params: {
         page: pageNum,
         limit: pageLimit,
         q: searchName,
         filter: filterValue,
      },
   })
   return response.data
}

export async function fetchPurchasesTotals(searchName = '', filterValue: any = {}) {
   const response = await axiosInstance.get(`/admin/payment/total-count`, {
      params: {
         q: searchName,
         filter: filterValue,
      },
   })
   return response.data
}

export async function fetchSpentTotals(searchName = '', filterValue: any = {}) {
   const response = await axiosInstance.get(`/admin/tickles/total-count`, {
      params: {
         q: searchName,
         filter: filterValue,
      },
   })
   return response.data
}

export async function fetchPendingTotals(searchName = '', filterValue: any = {}) {
   const response = await axiosInstance.get(`/admin/feet-model/total-pending-count`, {
      params: {
         q: searchName,
         filter: filterValue,
      },
   })
   return response.data
}

export async function fetchEarningTotals(searchName = '', filterValue: any = {}) {
   const response = await axiosInstance.get(`/admin/feet-model/total-earnings-count`, {
      params: {
         q: searchName,
         filter: filterValue,
      },
   })
   return response.data
}

export async function fetchBankHistoryTotals(searchName = '', filterValue: any = {}) {
   const response = await axiosInstance.get(`/admin/withdrawal/total-amount-count`, {
      params: {
         q: searchName,
         filter: filterValue,
      },
   })
   return response.data
}

export async function fetchRefundedTotals(searchName = '', filterValue: any = {}) {
   const response = await axiosInstance.get(`/admin/payment/total-refunded-count`, {
      params: {
         q: searchName,
         filter: filterValue,
      },
   })
   return response.data
}
