import React, { useState } from 'react'
import { useQuery } from 'react-query'
import {
   fetchFeetGirlEarningList,
   fetchFeetGirlPendingList,
   fetchPurchaseList,
   fetchSpentTicklesList,
   fetchCustomMediaMessages,
   fetchDisputes,
   fetchTransferRequestList,
   fetchPurchasesTotals,
   fetchSpentTotals,
   fetchPendingTotals,
   fetchEarningTotals,
   fetchBankHistoryTotals,
   fetchRefundedTotals,
} from '../../api/business-api'

const useBusiness = (key: string) => {
   const [tableData, setTableData] = useState([]) as any
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [loader, setLoader] = useState(false)
   const [searchName, setSearchName] = useState('')
   const [pageNumber, setPageNumber] = useState(1) as any // for searching page
   const [page, setPage] = React.useState(1)
   const [limit, setPageLimit] = React.useState(25)
   const [sortField, setSortField] = useState('createdAt')
   const [sort, setSort] = React.useState(-1)

   const [pageType, setPageType] = useState(['/feet-lover', '/purchase-list'])
   const [hasFilter, setHasFilter] = useState(true)
   const [filterData, setFilterData] = useState([]) as any

   const [totalDataRows, setTotalDataRows] = React.useState(0)
   const [totalDataAmount, setTotalDataAmount] = React.useState(0)
   const [totalDataTickles, setTotalDataTickles] = React.useState(0)

   const handleApiFunctions = () => {
      switch (key) {
         case 'purchases':
            return fetchPurchaseList(page, Number(limit), searchName, filterData, sortField, sort)
         case 'spentTickles':
            return fetchSpentTicklesList(
               page,
               Number(limit),
               searchName,
               hasFilter,
               filterData,
               sortField,
               sort
            )
         case 'pendingTickles':
            return fetchFeetGirlPendingList(
               page,
               Number(limit),
               searchName,
               filterData,
               sortField,
               sort
            )
         case 'gainedTickles':
            return fetchFeetGirlEarningList(
               page,
               Number(limit),
               searchName,
               filterData,
               sortField,
               sort
            )
         case 'customMedia':
            return fetchDisputes(page, Number(limit), searchName, filterData)
         case 'requests':
            return fetchTransferRequestList(page, Number(limit), searchName, filterData)
         case 'history':
            return fetchTransferRequestList(page, Number(limit), searchName, filterData)
         case 'refundHistory':
            return fetchPurchaseList(page, Number(limit), searchName, filterData, sortField, sort)
         default:
            return fetchPurchaseList(page, Number(limit), searchName, filterData)
      }
   }

   const { isLoading, status, isError, error, data, refetch, isPreviousData } = useQuery(
      [key, page, Number(limit)],
      () => handleApiFunctions(),
      { keepPreviousData: true }
   )

   const handleTotalsApiFunctions = () => {
      switch (key) {
         case 'purchases':
            return fetchPurchasesTotals(searchName, filterData)
         case 'spentTickles':
            return fetchSpentTotals(searchName, filterData)
         case 'pendingTickles':
            return fetchPendingTotals(searchName, filterData)
         case 'gainedTickles':
            return fetchEarningTotals(searchName, filterData)
         case 'customMediaTotals':
            return fetchDisputes(page, Number(limit), searchName, filterData)
         case 'requestsTotals':
            return fetchTransferRequestList(page, Number(limit), searchName, filterData)
         case 'history':
            return fetchBankHistoryTotals(searchName, filterData)
         case 'refundHistory':
            return fetchRefundedTotals(searchName, filterData)
         default:
            return fetchPurchasesTotals(searchName, filterData)
      }
   }

   const {
      error: totalErrors,
      data: totals,
      refetch: totalRefetch,
   } = useQuery([`${key}-totals`], () => handleTotalsApiFunctions(), { keepPreviousData: true })

   return {
      data,
      refetch,
      error,
      filterData,
      setFilterData,
      pageType,
      setPageType,
      searchName,
      setSearchName,
      tableData,
      setTableData,
      setPage,
      page,
      loader,
      setLoader,
      setHasFilter,
      setSortField,
      sortField,
      sort,
      setSort,
      totalErrors,
      totals,
      totalRefetch,
      totalDataRows,
      setTotalDataRows,
      totalDataAmount,
      setTotalDataAmount,
      totalDataTickles,
      setTotalDataTickles,
      pageNumber,
      setPageNumber,
   }
}

export default useBusiness
