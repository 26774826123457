/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
import React, { useMemo, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import ReactPlayer from 'react-player'
import moment from 'moment'
import {
   Card,
   Image,
   Pagination,
   Spin,
   Button,
   Input,
   Alert,
   Select,
   Form,
   Menu,
   Modal,
   notification,
   PageHeader,
   Row,
   Col,
} from 'antd'
import { RiMessage2Line, RiDeleteBinLine } from 'react-icons/ri'
import { Link, Outlet } from 'react-router-dom'
import ReactPaginate from 'react-paginate'

import { fetchLiveMedias, getMessageFileFromFileSystem } from '../../api/media'
import { fallBackImageBase64 } from './constant'
import ReadMore from '../../components/ReadMore'
import useBanMedia from '../../hooks/media/mutation/useBanMedia'
import { MessageMedia } from '../../components/MessageMedia/MessageMedia'
import { VideoPlayer } from '../../components/VideoPlayer/VideoPlayer'
import { axiosInstance } from '../../utils/axios-base'
import { fetchReports } from '../../api/report'
import { ReportElement } from './ReportElement'
import { LoadingScreen } from '../../components/LoadingScreenComponent'
import { CustomVideoThumbnail } from '../../components/VideoPlayer/VideoThumbnail'

const { Meta } = Card
const { Option } = Select

export interface IFormInput {
   searchName: string
   mediaType: string
}

/* TODO:

 filter:
   - all medias
   - report
   - private medias

*/

export const ReportPage = () => {
   const [page, setPage] = React.useState(1)
   const [limit, setPageLimit] = React.useState(10)
   const [searchData, setSearchData] = useState([]) as any
   const [searchName, setSearchName] = useState('')
   const [pageNumber, setPageNumber] = useState(1) as any // for searching page
   const [reports, setReports] = useState([])
   const [mediaType, setMediaType] = useState('public')
   const [loader, setLoader] = useState(false)
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [isVideoModalVisible, setIsVideoModalVisible] = useState(false)
   const [deleteMediaId, setDeleteMediaId] = useState('')
   const [btnLoader, setBtnLoader] = useState(false)
   const [videoFileName, setVideoFileName] = useState('')

   // for react-player
   const [play, setPlay] = useState(false)

   const isInline = true

   const banMedia = useBanMedia()

   const {
      isLoading,
      dataUpdatedAt,
      status,
      isError,
      error,
      data,
      isFetching,
      isPreviousData,
      refetch,
   } = useQuery(['reports', page, limit], () => fetchReports(page, limit, searchName, mediaType), {
      enabled: false,
   })

   const handleCancelVideo = async () => {
      await setPlay(false)
      setVideoFileName('')
      setIsVideoModalVisible(false)
   }

   const showVideoModal = (fileName: any) => {
      setVideoFileName(fileName)
      setIsVideoModalVisible(true)
   }
   useEffect(() => {
      async function fetchData() {
         const response = await refetch()
         setReports(response.data.data)
      }
      fetchData()
   }, [])

   const deleteSuccessful = () => {
      setLoader(true)
      async function refetchData() {
         const response = await refetch()
         setReports(response.data.data)
         setLoader(false)
      }
      refetchData()
   }

   const handleSubmit = (values: any) => {
      setLoader(true)
      async function refetchData() {
         const response = await refetch()
         setReports(response.data.data)
         setLoader(false)
      }
      refetchData()
   }

   // for search page
   const handleSubmitPage = (values: any) => {
      setPage(parseInt(values.searchPage, 10))
   }

   // if input for search is empty, trigger submit
   useEffect(() => {
      if (searchName === '') {
         handleSubmit('')
      }
   }, [searchName])

   // if mediatype is changed (private=true or private=false) trigger refetch
   useEffect(() => {
      setLoader(true)
      async function refetchData() {
         const response = await refetch()
         setReports(response.data.data)
         setLoader(false)
      }
      refetchData()
   }, [page, mediaType])

   // pagination functions - start
   const handlePageClick = async (pgData: any) => {
      setPage(pgData.selected + 1)
      async function refetchData() {
         const response = await refetch()
         setReports(response.data.data)
         setLoader(false)
      }
      refetchData()
   }

   const handleChangeMediaType = async (_mediaType: any) => {
      await setMediaType(_mediaType)
      setLoader(true)
      await setPage(1)
      async function refetchData() {
         const response = await refetch()
         setReports(response.data.data)
         setLoader(false)
      }
      refetchData()
   }

   return (
      <>
         <div className="content-wrapper">
            <PageHeader className="site-page-header" title="Reports" />
            <Row justify="space-around" style={{ marginBottom: '20px' }}>
               <Col flex="500px">
                  {/* search div - start */}
                  <Form className="search-form" onFinish={handleSubmit} layout="inline">
                     <Form.Item label="Search" name="searchName" style={{ marginRight: '10px' }}>
                        <Input
                           placeholder="search..."
                           value={searchName}
                           onChange={(e) => setSearchName(e.target.value)}
                           allowClear
                        />
                     </Form.Item>
                     <Form.Item>
                        <Button type="primary" htmlType="submit">
                           Search
                        </Button>
                     </Form.Item>
                  </Form>
                  {/* search div - end */}
               </Col>
               <Col flex="auto">
                  {/* sub menu items - start */}
                  <div className="wall-menu-container">
                     <Menu
                        mode="horizontal"
                        defaultSelectedKeys={['/live-medias']}
                        className="menu-flex-end">
                        <Menu.Item key="/live-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('public')}>
                              Public Wall
                           </button>
                        </Menu.Item>
                        <Menu.Item key="/private-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('private')}>
                              Private Wall
                           </button>
                        </Menu.Item>
                        <Menu.Item key="/chat-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('chat')}>
                              Chat
                           </button>
                        </Menu.Item>
                     </Menu>
                  </div>
                  {/* sub menu items - end */}
               </Col>
            </Row>

            {/* loader - start */}
            {loader && <LoadingScreen />}
            {/* loader - end */}

            {/* mapped media component - start */}
            {(!isLoading || !loader) && (
               <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                  {reports.length > 0 &&
                     reports.map((report: any, index) => {
                        // eslint-disable-next-line react/no-array-index-key
                        return (
                           <ReportElement
                              data={report}
                              showVideoModal={showVideoModal}
                              deleteSuccessful={deleteSuccessful}
                           />
                        )
                     })}
               </div>
            )}
            {/* mapped media component - end */}
            {/* PAGINATION START */}
            {data && data?.totalPages ? (
               <div
                  className="pagination-container"
                  style={{ display: 'flex', justifyContent: 'center' }}>
                  <Form
                     onFinish={handleSubmitPage}
                     layout="inline"
                     style={{ alignItems: 'center' }}>
                     <Form.Item name="searchPage" style={{ marginRight: '10px', width: 100 }}>
                        <Input
                           placeholder="search page..."
                           value={pageNumber}
                           type="number"
                           max={data.totalPages}
                           min={1}
                           onChange={(e) => setPageNumber(e.target.value)}
                           allowClear
                        />
                     </Form.Item>
                     <Form.Item>
                        <Button type="primary" htmlType="submit">
                           Search Page
                        </Button>
                     </Form.Item>
                  </Form>
                  <ReactPaginate
                     forcePage={page - 1}
                     pageCount={data?.totalPages}
                     breakLabel="..."
                     previousLabel="<<"
                     nextLabel=">>"
                     containerClassName="pagination"
                     previousLinkClassName="pagination__link"
                     nextLinkClassName="pagination__link"
                     disabledClassName="pagination__link--disabled"
                     activeClassName="pagination__link--active"
                     marginPagesDisplayed={1}
                     pageRangeDisplayed={1}
                     onPageChange={handlePageClick}
                  />
               </div>
            ) : (
               ''
            )}
            {/* PAGINATION END */}
         </div>
         <Modal
            title="Video"
            visible={isVideoModalVisible}
            onCancel={handleCancelVideo}
            footer={[<Button onClick={handleCancelVideo}>Close</Button>]}>
            <VideoPlayer fsUrl={videoFileName} playing={play} />
         </Modal>
      </>
   )
}
