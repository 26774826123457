import React, { useState } from 'react'
import { Button, Form, Input, notification, PageHeader, Select } from 'antd'
import { ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import useSendEmails from '../../../hooks/email/mutation/useSendEmail'

export interface IFormInput {
   filter: any
   subject: string
   content: string
}

const { Option } = Select

function EmailForm() {
   const contentStates = ContentState.createFromText('')
   const raw = convertToRaw(contentStates)
   const [contentState, setContentState] = useState(raw)
   const [filters, setFilters] = useState<any>()

   const sendNewsletter = useSendEmails()

   const [form] = Form.useForm()

   const onOptionChange = (value: string) => {
      // eslint-disable-next-line default-case
      switch (value) {
         case 'all':
            setFilters({ $or: [{ userType: 'Lover' }, { userType: 'Model' }] })
            return
         case 'lover':
            setFilters({ $or: [{ userType: 'Lover' }] })
            return
         case 'model':
            setFilters({ $or: [{ userType: 'Model' }] })
      }
   }

   // notification success
   const successNotification = () => {
      notification.success({
         message: 'Email sent',
      })
   }

   // notification error
   const errorNotification = () => {
      notification.error({
         message: 'Error. Something went wrong when sending email.',
      })
   }

   const onFinish = async (values: any) => {
      if (values && filters) {
         try {
            const bodyParams: IFormInput = {
               filter: filters,
               subject: values.Subject,
               content: values.Body.blocks[0].text,
            }
            const response = await sendNewsletter.mutateAsync(bodyParams)

            if (response.data === 'message sent') {
               successNotification()
            }
         } catch (err) {
            console.error(err)
            errorNotification()
         }
      }
   }

   return (
      <div style={{ height: '100%' }}>
         <PageHeader className="site-page-header" title="Newsletter" />
         <div className="newsletter-form-container" style={{ display: 'flex' }}>
            <Form layout="vertical" form={form} name="control-hooks" onFinish={onFinish}>
               <Form.Item label="To" name="To" rules={[{ required: true }]}>
                  <Select
                     placeholder="Select a option for where do you want to send it"
                     onChange={onOptionChange}
                     allowClear>
                     <Option value="all">All</Option>
                     <Option value="lover">Lovers</Option>
                     <Option value="model">Models</Option>
                  </Select>
               </Form.Item>
               <Form.Item label="Subject" name="Subject" rules={[{ required: true }]}>
                  <Input placeholder="Write a subject..." allowClear />
               </Form.Item>
               <Form.Item label="Body" name="Body">
                  <Editor
                     defaultContentState={contentState}
                     onContentStateChange={setContentState}
                     toolbarClassName="toolbar-class"
                     wrapperClassName="wrapper-class"
                     editorClassName="editor-class"
                  />
               </Form.Item>
               <Form.Item>
                  <Button type="primary" htmlType="submit">
                     Send
                  </Button>
               </Form.Item>
            </Form>
         </div>
      </div>
   )
}

export default EmailForm
