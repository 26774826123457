import { axiosInstance, getAuthSession } from '../utils/axios-base'
import { IFormInput } from '../pages/NewsLetterEmail/EmailForm/EmailForm'

export async function sendNewsletterEmail(data: IFormInput) {
   const headers = {
      Authorization: `Bearer ${getAuthSession()}`,
   }

   const response = await axiosInstance.post(`/admin/newsletter`, data, { headers })

   return response
}
