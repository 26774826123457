import React, { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { Layout, Menu } from 'antd'

import AuthStatus from './AuthStatus'
import { useAuth } from '../context/authentication'

import { LocalStorage } from '../services'

const { SubMenu } = Menu
const { Header, Content, Footer } = Layout

function LayoutHeader() {
   const [current, setCurrent] = useState(
      window.location.pathname === '/' ? '/moderation-photos' : window.location.pathname
   )
   console.log('window location pathname: ', window.location.pathname)
   // eslint-disable-next-line no-console
   console.log('current: ', current)
   const { user } = useAuth()
   const userRole = LocalStorage.getRole()
   return (
      <div>
         {/* Header */}
         {user && (
            <Header>
               <Menu
                  defaultSelectedKeys={['/moderation-photos']}
                  selectedKeys={[current]}
                  mode="horizontal"
                  onClick={(e) => {
                     setCurrent(e.key)
                  }}
                  theme="dark"
                  style={{ color: 'white' }}>
                  {userRole === 'admin' && (
                     <Menu.Item key="/moderation-photos">
                        <Link to="/moderation-photos">Moderation Photos</Link>
                     </Menu.Item>
                  )}

                  {userRole === 'admin' && (
                     <Menu.Item key="/moderation-videos">
                        <Link to="/moderation-videos">Moderation Videos</Link>
                     </Menu.Item>
                  )}

                  <Menu.Item key="/live-medias" style={{ color: 'white' }}>
                     <Link to="/live-medias">Approved medias</Link>
                  </Menu.Item>

                  {userRole === 'admin' && (
                     <Menu.Item key="/custom-medias">
                        <Link to="/custom-medias">Custom Media</Link>
                     </Menu.Item>
                  )}

                  {userRole === 'admin' && (
                     <Menu.Item key="/users">
                        <Link to="/users">Users</Link>
                     </Menu.Item>
                  )}

                  {userRole === 'admin' && (
                     <Menu.Item key="/report">
                        <Link to="/report">Report</Link>
                     </Menu.Item>
                  )}

                  {userRole === 'admin' && (
                     <Menu.Item key="/newsletter-emails">
                        <Link to="/newsletter-emails">Newsletter</Link>
                     </Menu.Item>
                  )}

                  {userRole === 'admin' && (
                     <Menu.Item key="/deleted-medias">
                        <Link to="/deleted-medias">Media deleted</Link>
                     </Menu.Item>
                  )}

                  {userRole === 'admin' && (
                     <Menu.Item key="/business-page">
                        <Link to="/business-page">Business</Link>
                     </Menu.Item>
                  )}

                  <Menu.Item key="logout" style={{ marginRight: '0', marginLeft: 'auto' }}>
                     <AuthStatus />
                  </Menu.Item>
               </Menu>
            </Header>
         )}

         {!user && <AuthStatus />}

         {/* The <Outlet> element is used as a placeholder. In this case an <Outlet> enables the Users component to render its child routes. */}
         <Outlet />

         {/* Footer */}
         {/* <div>Footer</div> */}
      </div>
   )
}

export default LayoutHeader
