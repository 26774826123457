/* eslint-disable no-nested-ternary */
import React from 'react'

export const TableLayout = ({
   getTableProps,
   getTableBodyProps,
   headerGroups,
   footerGroups,
   rows,
   prepareRow,
   setSortField,
   sortField,
   sort,
   setSort,
   page,
}: any) => {
   return (
      <div className="table-layout-container">
         <table {...getTableProps()}>
            <thead>
               {headerGroups.map((headerGroup: any) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                     {headerGroup.headers.map((column: any) => (
                        <th
                           {...column.getHeaderProps()}
                           onClick={(e) => {
                              if (!page) {
                                 if (
                                    !column.id.includes('time') &&
                                    !column.id.includes('counter')
                                 ) {
                                    setSort(sort === 1 ? -1 : 1)
                                    if (column.id === 'counter.tickles') setSortField('createdAt')
                                    else if (column.id === 'amount.tickles') setSortField('tickles')
                                    else setSortField(column.id)
                                 }
                              } else {
                                 setSortField(column.id)
                                 setSort(sort.includes('asc') ? 'desc' : 'asc')
                              }
                              // column.sortBy({
                              //    id: column.id,
                              //    sort: column.isSorted
                              //       ? column.isSortedDesc
                              //          ? 'desc'
                              //          : 'asc'
                              //       : 'desc',
                              // })
                           }}>
                           {column.render('Header')}
                           {page && page.includes('user-page') ? (
                              <span>
                                 {column.id === sortField && sort.includes('asc')
                                    ? sort.includes('desc')
                                       ? ' 🔽'
                                       : ' 🔼'
                                    : ' 🔽'}
                              </span>
                           ) : (
                              !column.id.includes('time') &&
                              !column.id.includes('counter') && (
                                 <span>
                                    {(column.id === sortField ||
                                       (column.id === 'counter.tickles' &&
                                          sortField === 'createdAt')) &&
                                    sort === 1
                                       ? sort === -1
                                          ? ' 🔽'
                                          : ' 🔼'
                                       : '🔽'}
                                 </span>
                              )
                           )}
                           <div className="filterBox">
                              {column.canFilter ? column.render('Filter') : null}
                           </div>
                        </th>
                     ))}
                  </tr>
               ))}
            </thead>
            <tbody {...getTableBodyProps()}>
               {rows.map((row: any, i: any) => {
                  prepareRow(row)
                  return (
                     <tr {...row.getRowProps()}>
                        {row.cells.map((cell: any) => {
                           return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                     </tr>
                  )
               })}
            </tbody>
            <tfoot className="listFooter">
               {footerGroups.map((footerGroup: any) => (
                  <tr {...footerGroup.getFooterGroupProps()}>
                     {footerGroup.headers.map((column: any) => (
                        <td {...column.getFooterProps}>{column.render('Footer')}</td>
                     ))}
                  </tr>
               ))}
            </tfoot>
         </table>
      </div>
   )
}
