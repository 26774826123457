import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import moment from 'moment/moment'
import { Button, notification, Select, Tooltip } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useFilters, useTable } from 'react-table'
import { TableLayout } from '../../../../../components/TableLayout'
import './spent-tickles-table.css'
import ConfirmationModal from '../../../../../components/Modal/ConfirmationModal'
import useFreezeCustomMediaTickles from '../../../../../hooks/business/mutation/useFreezeCustomMediaTickles'
import useUnFreezeCustomMediaTickles from '../../../../../hooks/business/mutation/useUnfreezeCustomMediaTickles'

export const SpentTicklesTableInstance = ({
   tbData,
   handleUnFreezeCustomMedia,
   handleFreezeCustomMedia,
   setFilterData,
   setHasFilter,
   setSortField,
   sortField,
   sort,
   setSort,
   fieldDateValue,
   totalsRowData,
   totalsAmountData,
}: any) => {
   let filterPack: any = {}
   let packPrivateValue: any[] = []
   let packMessageValue: any[] = []
   const [filterTypeValues, setFilterTypeValues] = useState([]) as any
   const [checked, setChecked] = useState(true)

   const [open, setOpen] = useState(false)
   const [params, setParams] = useState(null)
   const [title, setTitle] = useState('')
   const [description, setDescription] = useState('')
   const [_type, setType] = useState('')

   const optionCodeValues: any[] = [
      { label: 'Custom', value: 'Custom' },
      { label: 'Tips public wall', value: 'Tips public wall' },
      { label: 'Tips private wall', value: 'Tips private wall' },
      { label: 'Tips chat', value: 'Tips chat' },
   ]

   const handleConfirmationModal = (data: any, actionType: string) => {
      setOpen(true)
      setParams(data)
      setTitle('Warning')
      setDescription(`Are you sure you want to ${actionType}?`)
      setType(actionType)
   }

   const onChange = (checkedValues: CheckboxValueType[], type: string) => {
      setChecked(false)
      setFilterTypeValues([...checkedValues])
      if (checkedValues.length > 0) {
         if (type === 'type') {
            checkedValues.map((item) => {
               if (item.toString().includes('private')) {
                  packPrivateValue.push(true)
               } else if (item.toString().includes('public')) {
                  packPrivateValue.push(false)
               } else if (item.toString().includes('chat')) {
                  packMessageValue.push('Tickles')
               } else if (item.toString().includes('Custom')) {
                  packMessageValue.push('Custom-Media')
               }
               return null
            })
            filterPack = {
               $or: [
                  { 'message.type': { $in: [...packMessageValue] } },
                  { 'publication.private': { $in: [...packPrivateValue] } },
               ],
            }
         }
         setHasFilter(true)
      } else {
         filterPack = {}
         packPrivateValue = []
         packMessageValue = []
         setHasFilter(false)
         setChecked(true)
         setFilterTypeValues([])
      }
      if (fieldDateValue && fieldDateValue.length > 0 && fieldDateValue[0] && fieldDateValue[1]) {
         const startDate = `${moment
            .utc(fieldDateValue[0].toDate())
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastDate = `${moment
            .utc(fieldDateValue[1].toDate())
            .format('yyyy-MM-DD')}T23:59:59.999Z`
         filterPack.createdAt = {
            $gte: moment.utc(startDate),
            $lte: moment.utc(lastDate),
         }
         setHasFilter(true)
      } else {
         delete filterPack.createdAt
      }
      setFilterData(filterPack)
   }

   const handleClear = () => {
      filterPack = {}
      packPrivateValue = []
      packMessageValue = []
      setHasFilter(false)
      setChecked(true)
      setFilterTypeValues([])
      if (fieldDateValue && fieldDateValue.length > 0 && fieldDateValue[0] && fieldDateValue[1]) {
         const startDate = `${moment
            .utc(fieldDateValue[0].toDate())
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastDate = `${moment
            .utc(fieldDateValue[1].toDate())
            .format('yyyy-MM-DD')}T23:59:59.999Z`
         filterPack.createdAt = {
            $gte: moment.utc(startDate),
            $lte: moment.utc(lastDate),
         }
         setHasFilter(true)
      } else {
         delete filterPack.createdAt
      }
      setFilterData(filterPack)
   }

   const [columns, data, totalsRow, totalAmount] = useMemo(() => {
      const columnsData = [
         {
            Header: 'Pseudo Feetlover',
            accessor: 'feetLover.username',
            Footer: (column: any) => (
               <span>
                  <span style={{ fontSize: '12px', display: 'flex' }}>Total</span>
               </span>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     <p style={{ color: '#0000CD', fontWeight: 'bolder' }}>
                        {cell.row.original?.feetLover?.username ||
                           cell.row.original?.feetLover?.email}
                     </p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Mail Feetlover',
            accessor: 'feetLover.email',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.feetLover?.email}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Amount Tickles',
            accessor: 'tickles',
            Footer: (column: any) => (
               <div>
                  <span>
                     <br />
                     <p>
                        {_.sum(_.map(column.rows, (d) => d.original?.tickles))
                           .toLocaleString()
                           .replaceAll(',', "'")}
                     </p>
                  </span>
                  <span>
                     <p>{Number(totalAmount.toFixed(2)).toLocaleString().replaceAll(',', "'")}</p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.tickles}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Pseudo Feetgirl',
            accessor: 'feetModel.username',
            Footer: (column: any) => (
               <div>
                  <span>
                     <br />
                     <p>
                        {
                           column.rows.filter(
                              (item: any) =>
                                 (item.original?.feetModel?.username &&
                                    item.original?.feetModel?.username !== '') ||
                                 (item.original?.feetModel?.email &&
                                    item.original?.feetModel?.email !== '')
                           ).length
                        }
                     </p>
                  </span>
                  <span>
                     <p>{totalsRow}</p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     <p style={{ color: '#FF1493', fontWeight: 'bolder' }}>
                        {cell.row.original?.feetModel?.username ||
                           cell.row.original?.feetModel?.email}
                     </p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'ID custom',
            accessor: 'message._id',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.idCustom}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Type of sell',
            accessor: 'counter.message.type',
            width: 130,
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.type}</p>
                  </div>
               )
            },
            Filter: (filter: any) => {
               return (
                  <>
                     <Select
                        mode="multiple"
                        allowClear
                        style={{
                           width: '100%',
                        }}
                        placeholder="Please select"
                        onChange={(checkedValue) => onChange(checkedValue, 'type')}
                        options={optionCodeValues}
                        defaultValue={filterTypeValues}
                        onClear={() => handleClear()}
                     />
                  </>
               )
            },
         },
         {
            Header: 'Date of sell',
            accessor: 'createdAt',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.createdAt).format('DD/MM/yyyy')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Time of sell',
            accessor: 'timeofsell',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.createdAt).format('HH:mm:ss')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Custom reception date',
            accessor: 'counter1',
            Cell: (cell: any) => {
               return null
            },
            disableFilters: true,
         },
         {
            Header: 'Tickles return status',
            accessor: 'counter2',
            Cell: (cell: any) => {
               return null
            },
            disableFilters: true,
         },
         {
            width: 300,
            Header: 'Action : Block',
            accessor: 'status',
            Cell: (cell: any) => (
               <div>
                  {cell.row.original?.status === 'Custom-Media-Freeze' ? (
                     <Tooltip title="Unblock">
                        <Button
                           // onClick={() => handleConfirmationModal(cell.row.original, 'unblock')}
                           onClick={() => handleUnFreezeCustomMedia(cell.row.original?.id)}
                           type="default"
                           style={{ backgroundColor: '#FF5768' }}
                           shape="round">
                           Unblock
                        </Button>
                     </Tooltip>
                  ) : null}
                  {cell.row.original?.status === 'pending' &&
                  cell.row.original?.message?.type.includes('Custom') ? (
                     <Tooltip title="Block">
                        <Button
                           onClick={() => handleConfirmationModal(cell.row.original, 'block')}
                           type="default"
                           shape="round">
                           Block
                        </Button>
                     </Tooltip>
                  ) : null}
               </div>
            ),
            disableFilters: true,
         },
      ]
      return [columnsData, tbData, totalsRowData, totalsAmountData]
   }, [tbData, totalsRowData, totalsAmountData])

   const tableInstance = useTable(
      {
         columns,
         data,
         initialState: {
            hiddenColumns: [
               'accountSoftDeleted',
               'listOfMediaBans',
               'reported',
               'reporting',
               'warnings',
            ],
         },
      },
      useFilters
   )

   return (
      <div>
         <ConfirmationModal
            title={title}
            description={description}
            open={open}
            setOpen={setOpen}
            onConfirm={async (elem) => {
               await handleFreezeCustomMedia(elem.id)
               notification.success({ message: 'froze custom media' })
               setOpen(false)
            }}
            data={params}
            type={_type}
         />
         <TableLayout
            {...tableInstance}
            setSortField={setSortField}
            sortField={sortField}
            sort={sort}
            setSort={setSort}
         />
      </div>
   )
}
