import { useMutation, useQueryClient } from 'react-query'
import { axiosInstance, getAuthSession } from '../../../utils/axios-base'

export default function useResetCustomMedia() {
   const queryClient = useQueryClient()
   return useMutation(
      async (customId: string) => {
         const headers = {
            Authorization: `Bearer ${getAuthSession()}`,
         }
         const response = await axiosInstance.post(
            `/admin/disputes/${customId}/reset-custom-media`,
            {},
            { headers }
         )
         return response
      },
      {
         onSuccess: () => {
            // refetch live medias
            queryClient.invalidateQueries('customMedia')
         },
         onMutate: (updatedPayment) => {
            return updatedPayment
         },
         // If the mutation fails, use the value returned from onMutate to roll back
         onError: (error, variables, context: any) => {
            // An error happened!
            console.log(`rolling back optimistic update with id ${context?.id}`)
         },
         // Always refetch after error or success:
         onSettled: () => {
            // Error or success... doesn't matter!
            queryClient.invalidateQueries('customMedia')
         },
      }
   )
}
