import { axiosInstance } from '../utils/axios-base'
import { IAuthResponse } from '../global/types'

import { LocalStorage } from '../services'

export async function handleApiResponse(response: any) {
   if (response.status === 200) {
      return {
         access_token: response.data.user.access_token,
         user: {
            // eslint-disable-next-line no-underscore-dangle
            id: response.data.user._id,
            email: response.data.user.email,
            username: response.data.user.username,
            role: response.data.user.role,
         },
      }
   }
   return Promise.reject(response)
}

export async function loginWithEmailAndPassword(data: any): Promise<IAuthResponse> {
   const response = await axiosInstance.post('/admin/auth/signin', data)
   console.log('response', response)

   return handleApiResponse(response)
}

export async function getUserProfile() {
   /* TODO: create an api endpoint to get user data base on access token; ex: /v1/users/me */

   let response = null

   try {
      response = await axiosInstance.get(`/v1/users/${LocalStorage.getUser()}`, {
         headers: {
            Authorization: ` Bearer ${LocalStorage.getToken()}`,
         },
      })
   } catch (error) {
      LocalStorage.clearToken()
      LocalStorage.clearUser()
      LocalStorage.clearRole()
   }

   return handleApiResponse(response)
}
