import React, { useState, useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VideoThumbnail from 'react-video-thumbnail'
import { fallBackImageBase64 } from '../../pages/ModeratedMedias/Photos/constant'
import { MessageMedia } from '../MessageMedia/MessageMedia'

export type Props = {
   fsUrl: any
   snapshotAtTime?: number
   pageType?: string
}

export const CustomVideoThumbnail: React.FC<Props> = React.forwardRef((props, ref) => {
   const { fsUrl, snapshotAtTime, pageType, ...rest } = props

   const [url, setUrl] = useState('')
   const [loader, setLoader] = useState(false)

   useEffect(() => {
      setLoader(true)
      const fileUrl = fsUrl?.split('/')
      const { length } = fileUrl
      const encryptedFilename = fileUrl[length - 1]
      if (fileUrl.indexOf('storage.googleapis.com') > -1) {
         setUrl(fsUrl)
      } else if (
         pageType &&
         pageType.includes('custom') &&
         fileUrl.indexOf('storage.googleapis.com') < -1
      ) {
         setUrl(`${process.env.REACT_APP_API_URL}/admin/medias/message_media/${encryptedFilename}`)
      } else {
         setUrl(
            `${process.env.REACT_APP_API_URL}/admin/medias/publication_media/${encryptedFilename}`
         )
      }
      // setLoader(false)
      // delay render
      setTimeout(() => setLoader(false), 1000)
   }, [fsUrl])

   return (
      <div className="video-wrapper">
         {!loader && (
            <MessageMedia fallback={fallBackImageBase64} content={url} needPreview={false} /> // <VideoThumbnail videoUrl={url} snapshotAtTime={2} />
         )}
      </div>
   )
})
