import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'

import { useAuth } from '../context/authentication'

function IsAuthenticated({ children }: { children: JSX.Element }) {
   const { user } = useAuth()
   const location = useLocation()

   if (user) {
      return <Navigate to="/moderation-photos" state={{ from: location }} />
   }

   return children
}

export default IsAuthenticated
