import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import moment from 'moment/moment'
import { Button, Checkbox, Col, Row, Select, Tooltip } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useFilters, useTable } from 'react-table'
import { TableLayout } from '../../../../../components/TableLayout'

export const PurchasesTableInstance = ({
   tbData,
   setFilterData,
   productCodeFilters,
   handleRefundPayment,
   setSortField,
   sortField,
   sort,
   setSort,
   fieldDateValue,
   totalsRowData,
   totalsAmountData,
}: any) => {
   let filterPack: any = {}
   const [checkCodeState, setCheckCodeState] = useState<CheckboxValueType[]>([]) as any
   const [checkRefundState, setCheckRefundState] = useState<CheckboxValueType[]>([]) as any
   const [filterCodeValues, setFilterCodeValues] = useState<CheckboxValueType[]>([]) as any
   const [filterRefundValues, setFilterRefundValues] = useState([]) as any
   const [optionCodeValues, setOptionCodeValues] = useState([]) as any
   const [checked, setChecked] = useState(true)

   const handleChangeIDPack = (checkedValues: any, type: string) => {
      setChecked(false)
      setCheckCodeState([...checkedValues])

      if (checkedValues.length > 0) {
         if (type === 'idPack' && filterRefundValues.length > 0) {
            filterPack = {
               'product.code': { $in: [...checkedValues] },
               status: { $in: [filterRefundValues.toString().toLowerCase()] },
            }
         } else {
            filterPack = {
               'product.code': { $in: [...checkedValues] },
            }
         }

         if (fieldDateValue && fieldDateValue[0] && fieldDateValue[1]) {
            const startDate = `${moment
               .utc(fieldDateValue[0].toDate())
               .format('yyyy-MM-DD')}T00:00:00.000Z`
            const lastDate = `${moment
               .utc(fieldDateValue[1].toDate())
               .format('yyyy-MM-DD')}T23:59:59.999Z`
            filterPack.createdAt = {
               $gte: moment.utc(startDate),
               $lte: moment.utc(lastDate),
            }
         } else {
            delete filterPack.createdAt
         }
         setFilterData(filterPack)
      }
   }

   const handleChangeRefund = (checkedValues: any, type: string) => {
      setChecked(false)
      setCheckRefundState([...checkedValues])

      if (checkedValues.length > 0) {
         if (checkedValues.includes('Refunded') && filterCodeValues.length > 0) {
            filterPack = {
               'product.code': { $in: [...filterCodeValues] },
               status: { $in: [checkedValues.toString().toLowerCase()] },
            }
         } else if (type === 'status' && checkedValues.toString() === 'Refunded') {
            filterPack = { status: { $in: [checkedValues.toString().toLowerCase()] } }
         } else if (type === 'status') {
            filterPack = { status: { $in: [] } }
         }

         if (fieldDateValue && fieldDateValue[0] && fieldDateValue[1]) {
            const startDate = `${moment
               .utc(fieldDateValue[0].toDate())
               .format('yyyy-MM-DD')}T00:00:00.000Z`
            const lastDate = `${moment
               .utc(fieldDateValue[1].toDate())
               .format('yyyy-MM-DD')}T23:59:59.999Z`
            filterPack.createdAt = {
               $gte: moment.utc(startDate),
               $lte: moment.utc(lastDate),
            }
         } else {
            delete filterPack.createdAt
         }
         setFilterData(filterPack)
      }
   }

   const handleClear = (type: string) => {
      if (type === 'idPack' && filterRefundValues.length > 0) {
         filterPack = { status: { $in: [filterRefundValues.toString().toLowerCase()] } }
      } else if (type === 'status' && filterCodeValues.length > 0) {
         filterPack = { 'product.code': { $in: [...filterCodeValues] } }
      } else {
         filterPack = {}
         setChecked(true)
         setCheckRefundState([])
         setCheckCodeState([])
      }

      if (fieldDateValue && fieldDateValue[0] && fieldDateValue[1]) {
         const startDate = `${moment
            .utc(fieldDateValue[0].toDate())
            .format('yyyy-MM-DD')}T00:00:00.000Z`
         const lastDate = `${moment
            .utc(fieldDateValue[1].toDate())
            .format('yyyy-MM-DD')}T23:59:59.999Z`
         filterPack.createdAt = {
            $gte: moment.utc(startDate),
            $lte: moment.utc(lastDate),
         }
      } else {
         delete filterPack.createdAt
      }
      setFilterData(filterPack)
   }

   const handleComputation = (data: any, type = '') => {
      let ht = 0
      let ttc = 0
      let tva = 0
      let total = 0
      if (data.original?.product?.type && data.original?.product?.type.includes('subscription')) {
         ht = data.original?.product?.amount
         ttc = ht * 1.2
         total = type === 'taxed' ? Number(ttc.toFixed(2)) : Number(ttc.toFixed(2))
      } else {
         ht = data.original?.product?.tickles / 100
         ttc = ht * 1.2
         tva = (ttc / 1.2) * 0.2
         total = data.original?.product?.amount + tva
      }
      return total
   }

   useEffect(() => {
      setFilterCodeValues([...checkCodeState])
   }, [checkCodeState])

   useEffect(() => {
      setFilterRefundValues([...checkRefundState])
   }, [checkRefundState])

   useEffect(() => {
      const option: any[] = []
      productCodeFilters
         .filter((itm: any) => itm?.active === true && itm?.code)
         .map((rowItem: any) => {
            option.push({
               label: rowItem?.code,
               value: rowItem?.code,
            })
            return null
         })
      setOptionCodeValues(option)
   }, [productCodeFilters, setOptionCodeValues])

   const [columns, dataTable, totalsRow, totalAmount] = useMemo(() => {
      const columnsData = [
         {
            Header: 'Pseudo Feetlover',
            accessor: 'user.username',
            Footer: (column: any) => (
               <div>
                  <span>
                     <span style={{ fontSize: '12px', display: 'flex' }}>Total</span>
                     <p>
                        {
                           column.rows.filter(
                              (item: any) =>
                                 (item.original?.user?.username &&
                                    item.original?.user?.username !== '' &&
                                    item.original.status !== 'refunded') ||
                                 (item.original?.user?.email &&
                                    item.original?.user?.email !== '' &&
                                    item.original.status !== 'refunded')
                           ).length
                        }
                     </p>
                  </span>
                  <span>
                     <p>{totalsRow}</p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     <p style={{ color: '#0000CD', fontWeight: 'bolder' }}>
                        {cell.row.original?.user?.username || cell.row.original?.user?.email}
                     </p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Mail',
            accessor: 'user.email',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.user?.email}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'ID Pack Tickles ID Abo',
            accessor: 'counter.product.code',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{cell.row.original?.product?.code} &#36;</p>
                  </div>
               )
            },
            Filter: (filter: any) => {
               return (
                  <>
                     <Select
                        mode="multiple"
                        allowClear
                        style={{
                           width: '100%',
                        }}
                        placeholder="Please select"
                        onChange={(checkedValue) => handleChangeIDPack(checkedValue, 'idPack')}
                        options={optionCodeValues}
                        defaultValue={filterCodeValues}
                        onClear={() => handleClear('idPack')}
                     />
                  </>
               )
            },
         },
         {
            Header: 'Epoch TransID',
            accessor: 'transactionId',
            Cell: (cell: any) => {
               return (
                  <div>
                     {cell.row.original?.transactionId ? (
                        <p>{cell.row.original?.transactionId}</p>
                     ) : null}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Pseudo Feetgirl',
            accessor: 'details.model.username',
            Cell: (cell: any) => {
               return (
                  <div>
                     {cell.row.original?.details && cell.row.original?.details.model?.username ? (
                        <p style={{ color: '#FF1493', fontWeight: 'bolder' }}>
                           {cell.row.original?.details.model.username}
                        </p>
                     ) : null}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Amount in €',
            accessor: 'product.amount',
            Footer: (column: any) => (
               <div>
                  <span>
                     <br />
                     <p>
                        {Number(
                           (
                              Math.round(
                                 _.sum(
                                    _.map(column.rows, (d) =>
                                       d.original?.status !== 'refunded'
                                          ? handleComputation(d, '')
                                          : 0
                                    )
                                 ) * 100
                              ) / 100
                           ).toFixed(2)
                        )
                           .toLocaleString()
                           .replaceAll(',', "'")}
                        &euro;
                     </p>
                  </span>
                  <span>
                     <p>
                        {Number((Math.round(totalAmount * 100) / 100).toFixed(2))
                           .toLocaleString()
                           .replaceAll(',', "'")}
                        &euro;
                     </p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div>
                     {cell.row.original?.status === 'refunded' ? (
                        <p style={{ backgroundColor: '#FF5768', borderRadius: '15px' }}>
                           {handleComputation(cell.row, 'taxed')} &euro;
                        </p>
                     ) : (
                        <p>{handleComputation(cell.row, 'taxed')} &euro;</p>
                     )}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Date of purchase',
            accessor: 'createdAt',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.createdAt).format('DD/MM/yyyy')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Time of purchase',
            accessor: 'timeofpurchase',
            Cell: (cell: any) => {
               return (
                  <div>
                     <p>{moment.utc(cell.row.original?.createdAt).format('HH:mm:ss')}</p>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Count Tickles pack <14 days',
            accessor: 'counter.tickles',
            Cell: (cell: any) => {
               const now = new Date()
               const dur = moment.duration({
                  from: moment.utc(cell.row.original?.createdAt),
                  to: now,
               })
               return (
                  <div>
                     {!cell.row.original?.product?.code.toString().toUpperCase().includes('ABO') &&
                     dur.asDays() >= 14 ? (
                        <p style={{ color: '#038F00' }}>{`> 14 days`}</p>
                     ) : null}
                     {!cell.row.original?.product?.code.toString().toUpperCase().includes('ABO') &&
                     dur.asDays() < 14 ? (
                        <p style={{ color: '#FF0000' }}>{`< 14 days`}</p>
                     ) : null}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Wallet Tickles',
            accessor: 'user.tickles',
            Cell: (cell: any) => {
               return (
                  <div>
                     <Button disabled type="default" shape="round">
                        <p style={{ color: '#000' }}>
                           {cell.row.original?.product?.code
                              .toString()
                              .toLowerCase()
                              .includes('abo')
                              ? 0
                              : cell.row.original?.user?.tickles}
                        </p>
                     </Button>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            width: 300,
            Header: 'Action : Refund',
            accessor: 'counterstatus',
            Cell: (cell: any) => (
               <div>
                  {cell.row.original?.status === 'refunded' ? (
                     <Tooltip title="Refunded">
                        <Button type="default" style={{ backgroundColor: '#FF5768' }} shape="round">
                           Refunded {moment.utc(cell.row.original?.updatedAt).format('DD/MM/yyyy')}
                        </Button>
                     </Tooltip>
                  ) : (
                     <Tooltip title="Refund">
                        <Button
                           onClick={() => handleRefundPayment(cell.row.original?.id)}
                           type="default"
                           disabled={cell.row.original?.product?.code.includes('ABO')}
                           shape="round">
                           Refund
                        </Button>
                     </Tooltip>
                  )}
               </div>
            ),
            Filter: (filter: any) => {
               return (
                  <>
                     {/* <Checkbox
                        checked={checked}
                        onChange={(checkAll) => onCheckAllChange(checkAll, 'status')}>
                        Show all
                     </Checkbox>{' '}
                     <Checkbox.Group
                        style={{ width: '100%' }}
                        options={['Refunded']}
                        value={filterRefundValues}
                        onChange={(checkedValue) => onChange(checkedValue, 'status')}
                     /> */}
                     <Select
                        mode="multiple"
                        allowClear
                        style={{
                           width: '100%',
                        }}
                        placeholder="Please select"
                        onChange={(checkedValue) => handleChangeRefund(checkedValue, 'status')}
                        options={[{ label: 'Refunded', value: 'Refunded' }]}
                        defaultValue={filterRefundValues}
                        onClear={() => handleClear('status')}
                     />
                  </>
               )
            },
         },
         {
            // to check if user is soft deleted
            Header: 'Is Deleted',
            accessor: 'accountSoftDeleted',
         },
         {
            // hidden
            Header: 'Media Ban Data',
            accessor: 'listOfMediaBans',
         },
         {
            // hidden
            Header: 'Reported Data',
            accessor: 'reported',
         },
         {
            // hidden
            Header: 'Reporting Data',
            accessor: 'reporting',
         },
         {
            // hidden
            Header: 'Warnings Data',
            accessor: 'warnings',
         },
      ]
      return [columnsData, tbData, totalsRowData, totalsAmountData]
   }, [tbData, totalsRowData, totalsAmountData])

   const tableInstance = useTable(
      {
         columns,
         data: dataTable,
         initialState: {
            hiddenColumns: [
               'accountSoftDeleted',
               'listOfMediaBans',
               'reported',
               'reporting',
               'warnings',
            ],
         },
      },
      useFilters
   )

   return (
      <TableLayout
         {...tableInstance}
         setSortField={setSortField}
         sortField={sortField}
         sort={sort}
         setSort={setSort}
      />
   )
}
