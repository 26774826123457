export const LocalStorage = {
   getToken: () => window.localStorage.getItem('token'),
   setToken: (token: string) => window.localStorage.setItem('token', token),
   clearToken: () => window.localStorage.removeItem('token'),
   setUser: (id: string) => window.localStorage.setItem('user', id),
   getUser: () => window.localStorage.getItem('user'),
   clearUser: () => window.localStorage.removeItem('user'),
   getRole: () => window.localStorage.getItem('role'),
   setRole: (role: string) => window.localStorage.setItem('role', role),
   clearRole: () => window.localStorage.removeItem('role'),
}
