import { useMutation, useQueryClient } from 'react-query'
import { axiosInstance } from '../../../utils/axios-base'

export default function useCustomMediaImages() {
   const queryClient = useQueryClient()
   return useMutation(
      async (mediaId: string) => {
         const response = await axiosInstance.get(
            `/admin/v2/medias/${mediaId}/get-custom-media-images`
         )
         return response
      },
      {
         onSuccess: () => {
            // refetch live medias
            queryClient.invalidateQueries('live-medias')
         },
         onMutate: (deletedMedia) => {
            return deletedMedia
         },
         // If the mutation fails, use the value returned from onMutate to roll back
         onError: (error, variables, context: any) => {
            // An error happened!
            console.log(`rolling back optimistic update with id ${context?.id}`)
         },
         // Always refetch after error or success:
         onSettled: () => {
            // Error or success... doesn't matter!
            queryClient.invalidateQueries('live-medias')
         },
      }
   )
}
