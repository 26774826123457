import React, { useMemo, useState, useEffect } from 'react'
import { Alert, Button, Card, Image, Modal, Select, Spin, notification } from 'antd'
import moment from 'moment'
import ReactPlayer from 'react-player'
import { RiDeleteBinLine, RiVidiconFill } from 'react-icons/ri'

import ReadMore from '../../components/ReadMore'
import { MessageMedia } from '../../components/MessageMedia/MessageMedia'
import { CustomVideoThumbnail } from '../../components/VideoPlayer/VideoThumbnail'

import { fallBackImageBase64 } from './constant'
import { LocalStorage } from '../../services'
import { deleteReportedMedia } from '../../api/report'

const { Meta } = Card
const { Option } = Select

export const ReportElement = ({
   data,
   showVideoModal,
   deleteSuccessful,
}: {
   data: any
   showVideoModal: any
   deleteSuccessful: any
}) => {
   // to check user role, hide ban button if epoch user
   const userRole = LocalStorage.getRole()
   const [deleteMediaId, setDeleteMediaId] = useState('')
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [btnLoader, setBtnLoader] = useState(false)
   // import { RiDeleteBinLine } from 'react-icons/ri'

   // confirmation modal
   const showModal = (selectedMediaId: string) => {
      setDeleteMediaId(selectedMediaId)
      setIsModalVisible(true)
   }

   // notification success
   const successNotification = () => {
      notification.success({
         message: 'Media deleted',
      })
   }

   const handleBanMedia = async (mediaId: string) => {
      try {
         setBtnLoader(true)
         const response = await deleteReportedMedia(mediaId)
         if (response.success) {
            successNotification()
            setIsModalVisible(false)
            setBtnLoader(false)
            deleteSuccessful()
         }
      } catch (err) {
         console.error(err)
      }
   }

   return (
      <>
         {data.status && data.status !== 'inactive' && (
            // eslint-disable-next-line no-underscore-dangle
            <div
               style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
               key={data._id}>
               {/* eslint-disable-next-line no-underscore-dangle */}
               <Card bordered={false} style={{ width: 250, margin: '0 20px' }}>
                  <Meta
                     title={data?.to?.username}
                     description={moment(data?.createdAt).format('DD/MM/yyyy, HH:mm')}
                  />

                  <Meta description={data.type} />
                  <Meta description={data.remarks} />

                  <div style={{ padding: '10px 0', position: 'relative' }}>
                     {data.type === 'publication' && data?.publication?.type?.includes('image') && (
                        <Image
                           width={250}
                           height={250}
                           src={`${process.env.REACT_APP_API_URL}/admin/medias/filename/${data.publication.filename}`}
                           fallback={fallBackImageBase64}
                           placeholder={
                              <div
                                 style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                 }}>
                                 <Spin size="small" />
                              </div>
                           }
                        />
                     )}
                     {data.type === 'publication' && data?.type?.includes('video') && (
                        <Card
                           style={{
                              width: '250px',
                              height: 'unset !important',
                              minHeight: '250px',
                              paddingBottom: '0!important',
                              marginTop: '0 !important',
                              maxHeight: '100%',
                              cursor: 'pointer',
                              textAlign: 'center',
                           }}
                           className="video-container"
                           onClick={() => showVideoModal(data.publication?.fsUrl)}>
                           <RiVidiconFill
                              style={{
                                 color: 'white',
                                 fontSize: '40px',
                                 position: 'absolute',
                                 marginLeft: '-140px',
                              }}
                           />

                           <CustomVideoThumbnail fsUrl={data.publication?.fsUrl} />
                        </Card>
                     )}

                     {/* if media is from a private message */}
                     {data.type === 'message' && data?.message?.type?.includes('Files') && (
                        <MessageMedia width={250} height={250} content={data?.message.content} />
                     )}

                     {/* Ban Media Button */}
                     {userRole === 'admin' && (
                        <div
                           style={{
                              background: '#f2f2f2',
                              color: '#e5195f',
                              position: 'absolute',
                              bottom: '18px',
                              right: '-35px',
                              fontSize: '26px',
                              cursor: 'pointer',
                              padding: '4px',
                              borderRadius: '5px',
                              height: '2.5rem',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: '10px',
                           }}>
                           <RiDeleteBinLine
                              onClick={() => {
                                 /* eslint no-underscore-dangle: 0 */
                                 showModal(data?._id)
                              }}
                           />
                        </div>
                     )}
                  </div>
               </Card>

               {/* modal delete media confirmation - start */}
               <Modal
                  title="Delete media"
                  visible={isModalVisible}
                  onOk={() => setIsModalVisible(false)}
                  onCancel={() => setIsModalVisible(false)}
                  footer={[
                     <Button
                        key="back"
                        type="primary"
                        loading={btnLoader}
                        onClick={() => handleBanMedia(deleteMediaId)}
                        style={{ background: 'green', color: 'white', borderStyle: 'none' }}>
                        Proceed
                     </Button>,
                     <Button
                        key="back"
                        onClick={() => setIsModalVisible(false)}
                        type="primary"
                        style={{ background: 'red', color: 'white', borderStyle: 'none' }}>
                        Cancel
                     </Button>,
                  ]}>
                  <Alert
                     message="Warning"
                     description="You are about to delete this media. This will be deleted permanently"
                     type="warning"
                     showIcon
                  />
               </Modal>
               {/* modal delete media confirmation - end */}
            </div>
         )}
      </>
   )
}
