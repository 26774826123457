import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { useFilters, useSortBy, useTable } from 'react-table'
import moment from 'moment/moment'

import { Button, notification } from 'antd'
import { TableLayout } from '../../../../components/TableLayout'
import ConfirmationModal from '../../../../components/Modal/ConfirmationModal'
import useUnFreezeCustomMediaTickles from '../../../../hooks/business/mutation/useUnfreezeCustomMediaTickles'
import useReturnCustomMediaTickles from '../../../../hooks/business/mutation/useReturnCustomMediaTickles'
import useResetCustomMedia from '../../../../hooks/business/mutation/useResetCustomMedia'

export const CustomMediaDispute = ({
   tbData,
   setFilterData,
   productCodeFilter,
   handleResetCustom,
   handleReturnTickle,
   handleUnblock,
}: any) => {
   console.log('table-data', tbData)

   const [open, setOpen] = useState(false)
   const [params, setParams] = useState(null)
   const [title, setTitle] = useState('')
   const [description, setDescription] = useState('')
   const [type, setType] = useState('')
   const unfreezeCustomMedia = useUnFreezeCustomMediaTickles()
   const returnTicklesCustomMedia = useReturnCustomMediaTickles()
   const resetCustomMedia = useResetCustomMedia()

   const handleConfirmationModal = (data: any, actionType: string) => {
      setOpen(true)
      setParams(data)
      setTitle('Warning')
      if (actionType.includes('unblock')) {
         setDescription(`Are you sure you want to ${actionType}?`)
      }
      if (actionType.includes('return')) {
         setDescription(`Are you sure you want to ${actionType} the tickles?`)
      }
      if (actionType.includes('reset')) {
         setDescription(`Are you sure you want to ${actionType} this custom media?`)
      }
      setType(actionType)
   }

   const [columns, dataTable] = useMemo(() => {
      const colorStatus = (status: string) => {
         let color = '#038F00'
         if (status === 'rejected') {
            color = '#FF0000'
         }
         if (status === 'pending') {
            color = '#0F46E5'
         }
         return color
      }

      const isModelDeleted = (user: any) =>
         user?.feetModel?.accountSoftDeleted === true || user?.feetModel?.suppress === true

      const columnsData = [
         {
            Header: 'Pseudo Feetlover',
            accessor: 'pseudofeetlover',
            Footer: (column: any) => (
               <span>
                  <span style={{ fontSize: '12px', display: 'flex' }}>Total</span>
               </span>
            ),
            Cell: (cell: any) => {
               console.log('cell.row.original: ', cell.row.original)
               return (
                  <div style={{ color: '#0000CD', fontWeight: 'bolder' }}>
                     {cell.row.original.feetLover.username}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Mail Feetlover',
            accessor: 'mainfeetlover',
            Cell: (cell: any) => {
               return <div>{cell.row.original.feetLover.email}</div>
            },
            disableFilters: true,
         },
         {
            Header: 'Pseudo feetgirl',
            accessor: 'pseudofeetgirl',
            Footer: (column: any) => (
               <div>
                  <span>
                     <br />
                     <p>
                        {
                           column.rows.filter(
                              (item: any) =>
                                 (item.original?.feetModel?.username &&
                                    item.original?.feetModel?.username !== '') ||
                                 (item.original?.feetModel?.email &&
                                    item.original?.feetModel?.email !== '')
                           ).length
                        }
                     </p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return (
                  <div style={{ color: '#FF1493', fontWeight: 'bolder' }}>
                     {cell.row.original.feetModel.username}
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Mail Feetgirl',
            accessor: 'mailfeetgirl',
            Cell: (cell: any) => {
               return <div>{cell.row.original.feetModel.email}</div>
            },
            disableFilters: true,
         },
         {
            Header: 'ID Custom',
            accessor: 'idCustom',
            Cell: (cell: any) => {
               return <div>{cell.row.original?.idCustom}</div>
            },
            disableFilters: true,
         },
         {
            Header: 'ID Sell',
            accessor: 'idSell',
            Cell: (cell: any) => {
               return <div>{cell.row.original.id}</div>
            },
            disableFilters: true,
         },
         {
            Header: 'Amount in Tickles',
            accessor: 'tickles',
            Footer: (column: any) => (
               <div>
                  <span>
                     <br />
                     <p>
                        {_.sum(_.map(column.rows, (d) => d.original?.tickles))
                           .toLocaleString()
                           .replaceAll(',', "'")}
                     </p>
                  </span>
               </div>
            ),
            Cell: (cell: any) => {
               return <div>{cell.row.original?.tickles}</div>
            },
            disableFilters: true,
         },
         {
            Header: 'Date of sell',
            accessor: 'dateOfSell',
            Cell: (cell: any) => {
               return <div>{moment(cell.row.original?.createdAt).format('DD/MM/yyyy')}</div>
            },
            disableFilters: true,
         },
         {
            Header: 'Time of sell',
            accessor: 'timeOfSell',
            Cell: (cell: any) => {
               return <div>{moment(cell.row.original?.createdAt).format('HH:mm:ss')}</div>
            },
            disableFilters: true,
         },
         {
            Header: 'Status of custom sent/not sent',
            accessor: 'statusOfCustom',
            Cell: (cell: any) => {
               return (
                  <div
                     style={{
                        textTransform: 'capitalize',
                        color: colorStatus(cell.row.original.status),
                     }}>
                     {cell.row.original.status}
                  </div>
               )
            },
            disableFilters: true,
         },
         // {
         //    Header: 'Date tickles returned to feetlover',
         //    accessor: 'dateReturned',
         //    Cell: (cell: any) => {
         //       return <div>{moment(cell.row.original?.updatedAt).format('DD/MM/yyyy')}</div>
         //    },
         //    disableFilters: true,
         // },
         {
            Header: 'Action unblocked',
            accessor: 'actionUnblocked',
            Cell: (cell: any) => {
               return (
                  <div>
                     <Button
                        type="default"
                        shape="round"
                        style={{ backgroundColor: '#FF5768' }}
                        onClick={() => handleConfirmationModal(cell.row.original, 'unblock')}>
                        Unblock
                     </Button>
                  </div>
               )
            },
            disableFilters: true,
         },
         {
            Header: 'Action return to 0 custom',
            accessor: 'resetCustom',
            Cell: (cell: any) => {
               return !isModelDeleted(cell.row.original) &&
                  cell.row.original.message?.customMediaStatus.includes('uploaded') ? (
                  <div>
                     {!cell.row.original.status.includes('refunded') ? (
                        <Button
                           disabled={cell.row.original.status === 'rejected'}
                           type="default"
                           shape="round"
                           onClick={() => handleConfirmationModal(cell.row.original, 'reset')}>
                           Reset Custom
                        </Button>
                     ) : (
                        cell.row.original?.returnAt &&
                        moment(cell.row.original?.returnAt).format('DD/MM/yyyy')
                     )}
                  </div>
               ) : null
            },
            disableFilters: true,
         },
         {
            Header: 'Action return Tickles',
            accessor: 'returnTickles',
            Cell: (cell: any) => {
               return (
                  <div>
                     {!cell.row.original.status.includes('refunded') ? (
                        <Button
                           disabled={cell.row.original.status === 'rejected'}
                           type="default"
                           shape="round"
                           onClick={() => handleConfirmationModal(cell.row.original, 'return')}>
                           Return Tickles
                        </Button>
                     ) : (
                        cell.row.original?.returnAt &&
                        moment(cell.row.original?.returnAt).format('DD/MM/yyyy')
                     )}
                  </div>
               )
            },
            disableFilters: true,
         },
      ]
      return [columnsData, tbData]
   }, [tbData])

   const tableInstance = useTable(
      {
         columns,
         data: dataTable,
         initialState: {
            hiddenColumns: [
               'accountSoftDeleted',
               'listOfMediaBans',
               'reported',
               'reporting',
               'warnings',
            ],
         },
      },
      useFilters,
      useSortBy
   )

   return (
      <div>
         <ConfirmationModal
            title={title}
            description={description}
            open={open}
            setOpen={setOpen}
            onConfirm={async (data) => {
               switch (type) {
                  case 'unblock':
                     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                     // @ts-ignore
                     await unfreezeCustomMedia.mutateAsync(params.id)
                     setOpen(false)
                     notification.success({ message: 'unfreeze custom media' })
                     break
                  case 'return':
                     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                     // @ts-ignore
                     await returnTicklesCustomMedia.mutateAsync(params.idCustom)
                     setOpen(false)
                     notification.success({ message: 'return custom media tickles' })
                     break
                  case 'reset':
                     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                     // @ts-ignore
                     await resetCustomMedia.mutateAsync(params.id)
                     setOpen(false)
                     notification.success({ message: 'reset custom media' })
                     break
                  default:
                     break
               }
            }}
            data={params}
            type={type}
         />
         <TableLayout {...tableInstance} />
      </div>
   )
}
