import React from 'react'
import styled from 'styled-components'

import { LoginForm } from '../LoginForm/LoginForm'

const Container = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100vh;
`

export const LoginPage = () => {
   return (
      <Container>
         <LoginForm />
      </Container>
   )
}
