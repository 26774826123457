import { useMutation, useQueryClient } from 'react-query'

import { recoverMedia } from '../../../api/ban'

export default function useRecoverMedia(status?: string) {
   const queryClient = useQueryClient()
   return useMutation(
      async (mediaId: string) => {
         const response = await recoverMedia(mediaId, status)

         return response
      },
      {
         onSuccess: () => {
            // refetch live medias
            queryClient.invalidateQueries('deleted-medias')
         },
         // onMutate: () => {},
         // If the mutation fails, use the value returned from onMutate to roll back
         onError: (error, variables, context: any) => {
            // An error happened!
            console.log(`rolling back optimistic update with id ${context?.id}`)
         },
         // Always refetch after error or success:
         onSettled: () => {
            // Error or success... doesn't matter!
            queryClient.invalidateQueries('deleted-medias')
         },
      }
   )
}
