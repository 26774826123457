/* eslint-disable react/button-has-type */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'

import { useAuth } from '../context/authentication'

function AuthStatus() {
   const { user, logout } = useAuth()
   const navigate = useNavigate()

   if (!user) {
      return (
         <div style={{ paddingTop: '10px', color: '#e5195f', textAlign: 'center' }}>
            <p>You are not logged in.</p>
         </div>
      )
   }

   return (
      <div style={{ height: '15px', display: 'flex', marginRight: '0', marginLeft: 'auto' }}>
         <p>
            Welcome! <b>{user.username}</b>{' '}
         </p>
         <Button
            className="sign-out-btn"
            style={{ marginLeft: '10px' }}
            onClick={async () => {
               await logout()

               navigate('/')
            }}>
            Sign out
         </Button>
      </div>
   )
}

export default AuthStatus
