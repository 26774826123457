/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
import React, { useMemo, useState, useEffect, useRef, createRef } from 'react'
import { useQuery } from 'react-query'
import ReactPlayer from 'react-player'
import moment from 'moment'
import {
   Card,
   Image,
   Spin,
   Button,
   Input,
   Alert,
   Select,
   Form,
   Menu,
   Modal,
   notification,
   PageHeader,
   Checkbox,
   Tag,
   Row,
   Col,
} from 'antd'
import { RiMessage2Line, RiDeleteBinLine, RiVideoLine, RiArrowRightLine } from 'react-icons/ri'
import { Link, Outlet } from 'react-router-dom'
import ReactPaginate from 'react-paginate'

import {
   changePublicationMediaType,
   fetchLiveMedias,
   getMessageFileFromFileSystem,
} from '../../../api/media'
import { fallBackImageBase64 } from './constant'
import ReadMore from '../../../components/ReadMore'
import useBanMedia from '../../../hooks/media/mutation/useBanMedia'
import useApproveMedia from '../../../hooks/media/mutation/useApproveMedia'
import useApproveAllMedia from '../../../hooks/media/mutation/useApproveAllMedia'
import { MessageMedia } from '../../../components/MessageMedia/MessageMedia'
import { VideoPlayer } from '../../../components/VideoPlayer/VideoPlayer'
import { LoadingScreen } from '../../../components/LoadingScreenComponent'

import { LocalStorage } from '../../../services'
import useBanAllMedia from '../../../hooks/media/mutation/useBanAllMedia'
import useModerateProfilePic from '../../../hooks/media/mutation/useModerateProfilePic'
import { ModeratorActions } from '../common/moderator.enum'

const { Meta } = Card
const { Option } = Select

export interface IFormInput {
   searchName: string
   mediaType: string
}

export const ModeratedPhotosPage = () => {
   const [loader, setLoader] = useState(false)

   const [isPublic, setIsPublic] = useState(false)

   // for modals
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [isVideoModalVisible, setIsVideoModalVisible] = useState(false)

   // for useQuery
   const [mediaStatus] = useState('pending')
   const [sortOrder] = useState('') // sort order set to blank for ascending order
   const [searchName, setSearchName] = useState('') // for searching
   const [pageNumber, setPageNumber] = useState(1) as any // for searching page
   const [mediaType, setMediaType] = useState('all') //
   const [limit] = useState(10)
   const [page, setPage] = useState(1)
   const [mediaFileType] = useState('image')

   // for checkboxes
   const [selectedMediasArray, setSelectedMediasArray] = useState([]) as any
   const [isCheckAll, setIsCheckAll] = useState(false)
   const [displayBulkActions, setDisplayBulkActions] = useState(false)
   const [selectMediaId, setSelectMediaId] = useState('')

   const [isSelectAllMedia, setIsSelectAllMedia] = useState(false)
   const [isUnselect, setIsUnselect] = useState(false)
   const [_, setIsChecked] = useState(false)
   const [deleteSelectedMediaId, setDeleteSelectedMediaId] = useState('')
   const [deleteSelectedMediaType, setDeleteSelectedMediaType] = useState('')

   // for react-player
   const [play, setPlay] = useState(false)

   const [deleteMediaId] = useState('')
   const [btnLoader] = useState(false)
   const [videoFileName, setVideoFileName] = useState('')
   const [backgroundColor] = React.useState('#e9e8e8')

   // approve or reject (ban) media
   const banMedia = useBanMedia()
   const approveMedia = useApproveMedia()
   const approveAllMedia = useApproveAllMedia()
   const banAllMedia = useBanAllMedia()

   const moderateProfilePic = useModerateProfilePic()

   // useQuery -> to populate data
   const { isLoading, data, refetch } = useQuery(
      ['live-medias', page, limit],
      () =>
         fetchLiveMedias(page, limit, searchName, mediaType, mediaStatus, sortOrder, mediaFileType),
      {
         enabled: false,
      }
   )

   // fix double fetch
   useEffect(() => {
      ;(async () => {
         setLoader(true)
         await refetch()
         setLoader(false)
      })()
   }, [page, mediaType])

   // video modal
   const showVideoModal = (media: any) => {
      const fileUrl = media?.url.split('/')
      if (fileUrl.indexOf('storage.googleapis.com') > -1) {
         setVideoFileName(media?.url)
      } else {
         setVideoFileName(media?.fsUrl)
      }
      setPlay(true)
      setIsVideoModalVisible(true)
   }

   const handleOk = () => {
      setIsModalVisible(false)
   }

   const handleChangeMediaType = async (_mediaType: any) => {
      setMediaType(_mediaType)
      setPage(1)
      setIsPublic(_mediaType === 'public')
   }

   const handleCancel = () => {
      setIsModalVisible(false)
   }

   const handleCancelVideo = () => {
      setPlay(false)
      setVideoFileName('')
      setIsVideoModalVisible(false)
   }
   // modal functions - end

   // notification success approve
   const successNotificationApprove = () => {
      notification.success({
         message: 'Media approved',
      })
   }

   // notification success reject
   const successNotificationReject = () => {
      notification.success({
         message: 'Media rejected',
      })
   }

   // notification error
   const errorNotification = () => {
      notification.error({
         message: 'Error. Something went wrong',
      })
   }

   const handleDeleteModal = async (userId: string, type: string) => {
      setDeleteSelectedMediaId(userId)
      setDeleteSelectedMediaType(type)
      setIsModalVisible(true)
   }

   // individual ban
   const handleBanMedia = async (mediaId: string) => {
      try {
         setLoader(true)
         const response = await banMedia.mutateAsync(mediaId)

         if (response.data === 'success') {
            successNotificationReject()
            refetch()
            setLoader(false)
            setIsModalVisible(false)
         }
      } catch (err) {
         console.error(err)
         // errorNotification()
         successNotificationReject()
         refetch()
         setLoader(false)
         setIsModalVisible(false)
      }
   }

   // individual approval
   const handleApproveMedia = async (mediaId: string) => {
      try {
         setLoader(true)
         const response = await approveMedia.mutateAsync(mediaId)

         if (response.status === 200) {
            successNotificationApprove()
            refetch()
            setLoader(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
      }
   }

   const handleSwitchMedia = async (mediaId: string) => {
      try {
         setLoader(true)
      } catch (err) {
         errorNotification()
         setLoader(false)
      }
   }

   const handleModerationOfProfilePic = async (mediaId: string, status: string) => {
      try {
         setLoader(true)

         const response = await moderateProfilePic.mutateAsync({ mediaId, status })

         if (response.status === 200) {
            if (status === 'approve') {
               successNotificationApprove()
            } else {
               successNotificationReject()
            }
            refetch()
            setLoader(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
      }
   }

   const handleRejectAllMedia = async () => {
      try {
         setLoader(true)
         const response = await banAllMedia.mutateAsync(selectedMediasArray)

         if (response.status === 200) {
            successNotificationReject()
            refetch()
            setLoader(false)
            setIsModalVisible(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
         setIsModalVisible(false)
      }
   }

   // bulk approval
   const handleApproveAllMedia = async () => {
      try {
         setLoader(true)
         const response = await approveAllMedia.mutateAsync(selectedMediasArray)

         if (response.status === 200) {
            successNotificationApprove()
            refetch()
            setLoader(false)
         }
      } catch (err) {
         console.error(err)
         errorNotification()
         setLoader(false)
      }
   }

   // bulk ban - TO DO
   // const handleBanAllMedia = async (mediaIdArray: []) => {}

   // for search
   const handleSubmit = (values: any) => {
      setLoader(true)
      async function refetchData() {
         await refetch()
         setLoader(false)
      }
      refetchData()
   }

   // for search page
   const handleSubmitPage = (values: any) => {
      setPage(parseInt(values.searchPage, 10))
   }

   // if input for search is empty, trigger submit
   useEffect(() => {
      if (searchName === '') {
         handleSubmit('')
      }
   }, [searchName])

   // pagination function
   const handlePageClick = async (pgData: any) => {
      setPage(pgData.selected + 1)
   }

   // bulk actions display listener
   const bulkActionsListener = () => {
      if (selectedMediasArray.length > 0) {
         setDisplayBulkActions(true)
      } else {
         setDisplayBulkActions(false)
      }
   }

   // checkbox function
   const checkedMedia = (target: any, mediaId: any) => {
      const arr = selectedMediasArray
      if (target.checked) {
         arr.push(mediaId)
      } else {
         const arrIndex = arr.indexOf(mediaId)
         arr.splice(arrIndex, 1)
      }
      setSelectedMediasArray(arr)

      setSelectMediaId(mediaId)
      if (target.checked) {
         setIsChecked(true)
      } else {
         setIsChecked(false)
      }

      bulkActionsListener()
   }

   // select all button
   const selectAllMedia = () => {
      setIsSelectAllMedia(true)
      let arr = data.data.map((media: any) => media._id)
      arr = Array.from(new Set(arr))
      setSelectedMediasArray(arr)
   }

   // unselect all button
   const unSelectAllMedia = () => {
      setIsUnselect(true)
      setIsCheckAll(false)
      setSelectedMediasArray([])
      bulkActionsListener()
   }

   // effect for select all or unselect all
   useEffect(() => {
      // let array = selectedMediasArray
      if (isSelectAllMedia === true) {
         setIsCheckAll(true)
         bulkActionsListener()
         selectAllMedia()
         setIsSelectAllMedia(false)
      }
      if (isUnselect === true) {
         unSelectAllMedia()
         setIsUnselect(false)
      }
   }, [selectedMediasArray])

   const handleSwitchToPrivate = async (mediaId: string) => {
      try {
         setLoader(true)
         await changePublicationMediaType(mediaId, 'approved')
         setIsPublic(false)
         await refetch()
         setLoader(false)
         setPage(page)
      } catch (err) {
         notification.error({ message: 'Failed switching media' })
      }
   }

   const handleCardActions = (ctx: string, media: any) => {
      if (ctx.toLowerCase() === 'approve') {
         if (media?.type === 'profile-image') {
            return handleModerationOfProfilePic(media._id, 'approve')
         }
         return handleApproveMedia(media._id)
      }
      if (ctx.toLowerCase() === 'switch' && media && !media?.private) {
         return handleSwitchToPrivate(media._id)
            .then(() =>
               notification.success({
                  message: 'Media type approved and switch to private successfully',
               })
            )
            .catch((e) => {
               notification.error({ message: 'Failed Switching media type' })
               console.log('Catch Exception: ', e)
            })
      }
      if (media && media?.type === 'profile-image') {
         return handleModerationOfProfilePic(media._id, 'reject')
      }
      return handleDeleteModal(media._id, 'one')
   }

   const handleCardButton = (media: any) => {
      const cardActions = Object.values(ModeratorActions).map((ctx: string) => {
         return (
            <Button
               onClick={() => handleCardActions(ctx, media)}
               style={{
                  background: ctx.toLowerCase() === 'reject' ? 'red' : 'green',
                  color: 'white',
                  borderStyle: 'none',
                  width: '100%',
               }}
               key={media._id}>
               {ctx}
            </Button>
         )
      })
      if (!isPublic && media && media?.private !== false) {
         cardActions.splice(1, 1)
      }
      return cardActions
   }

   const handleMediaUrl = (media: any) => {
      const fileUrl = media?.url.split('/')
      let mediaUrl = ''
      if (fileUrl.indexOf('storage.googleapis.com') > -1) {
         mediaUrl = media?.url
      } else {
         mediaUrl = `${process.env.REACT_APP_API_URL}/admin/medias/filename/${media.filename}`
      }
      return mediaUrl
   }

   return (
      <>
         <div className="content-wrapper">
            <PageHeader className="site-page-header" title="Photos" />
            <Row justify="space-around">
               <Col flex="500px">
                  {/* search div - start */}

                  {mediaType !== 'profile-image' && (
                     <Form className="search-form" onFinish={handleSubmit} layout="inline">
                        <Form.Item label="Search" name="searchName" style={{ marginRight: '10px' }}>
                           <Input
                              placeholder="search..."
                              value={searchName}
                              onChange={(e) => setSearchName(e.target.value)}
                              allowClear
                           />
                        </Form.Item>
                        <Form.Item>
                           <Button type="primary" htmlType="submit">
                              Search
                           </Button>
                        </Form.Item>
                     </Form>
                  )}

                  {/* search div - end */}
               </Col>
               <Col flex="auto">
                  {/* sub menu items - start */}
                  <div className="wall-menu-container">
                     <Menu
                        mode="horizontal"
                        defaultSelectedKeys={['/all-medias']}
                        className="menu-flex-end">
                        <Menu.Item key="/all-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={() => handleChangeMediaType('all')}>
                              All Wall
                           </button>
                        </Menu.Item>
                        <Menu.Item key="/profile-image">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={() => handleChangeMediaType('profile-image')}>
                              Profile Photos
                           </button>
                        </Menu.Item>
                        <Menu.Item key="/live-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('public')}>
                              Public Wall
                           </button>
                        </Menu.Item>
                        <Menu.Item key="/private-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('private')}>
                              Private Wall
                           </button>
                        </Menu.Item>
                        <Menu.Item key="/chat-medias">
                           <button
                              style={{
                                 borderStyle: 'none',
                                 backgroundColor: 'white',
                                 height: '15px',
                                 cursor: 'pointer',
                              }}
                              onClick={(e) => handleChangeMediaType('chat')}>
                              Chat
                           </button>
                        </Menu.Item>
                     </Menu>
                  </div>
                  {/* sub menu items - end */}
               </Col>
            </Row>

            {/* selections and bulk actions container - start */}

            {mediaType !== 'profile-image' && (
               <div
                  className="d-flex select-options-container"
                  style={{
                     padding: '20px 0',
                  }}>
                  <div
                     className="mod-media-select-actions"
                     style={{
                        marginRight: '10em',
                     }}>
                     <h4>
                        <strong>Select Options</strong>
                     </h4>
                     <Button
                        type="primary"
                        style={{
                           marginRight: '10px',
                        }}
                        onClick={() => selectAllMedia()}>
                        Select All
                     </Button>

                     <Button onClick={() => unSelectAllMedia()}>Unselect All</Button>

                     {/* uncomment this button for testing */}
                     {/* <Button onClick={() => displayLog()}> Display Log</Button> */}
                  </div>
                  {displayBulkActions && (
                     <div className="mod-media-bulk-actions-div">
                        <h4>
                           <strong>Bulk Actions</strong>
                        </h4>
                        <Button
                           type="primary"
                           onClick={() => handleApproveAllMedia()}
                           style={{
                              marginRight: '15px',
                              background: 'green',
                              color: 'white',
                              borderStyle: 'none',
                           }}>
                           Approve Selected
                        </Button>
                        <Button
                           style={{ background: 'red', color: 'white', borderStyle: 'none' }}
                           onClick={() => handleDeleteModal('', 'all')}>
                           Reject Selected
                        </Button>
                     </div>
                  )}
               </div>
            )}

            {/* selections and bulk actions container - end */}

            {loader && <LoadingScreen />}

            {(!isLoading || !loader) && (
               <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                  {data && data?.data.length ? (
                     data?.data.map((media: any, index: any) => (
                        <div key={media._id} id={index} className="moderation-card-wrapper">
                           <Card
                              className="media-card"
                              bordered={false}
                              style={{
                                 width: 250,
                                 margin: '10px 20px',
                                 background: backgroundColor,
                              }}
                              actions={handleCardButton(media)}>
                              <div>
                                 {media && media?.type?.includes('Files') ? (
                                    <>
                                       <div
                                          style={{
                                             display: 'grid',
                                             gridAutoFlow: 'column',
                                             gridColumnGap: '10px',
                                             justifyContent: 'center',
                                             alignItems: 'center',
                                             marginBottom: '10px',
                                          }}>
                                          <div>
                                             <Meta
                                                title={
                                                   media?.from?.username || media?.user?.username
                                                }
                                             />
                                          </div>
                                          <div
                                             style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                             }}>
                                             <RiArrowRightLine
                                                style={{
                                                   position: 'relative',
                                                   fontSize: '24px',
                                                }}
                                             />
                                          </div>
                                          <div>
                                             <Meta title={media?.to?.username} />
                                          </div>
                                       </div>
                                       <div
                                          style={{
                                             display: 'flex',
                                             justifyContent: 'center',
                                          }}>
                                          <Meta
                                             description={moment(media?.createdAt).format(
                                                'DD/MM/yyyy, HH:mm'
                                             )}
                                          />
                                       </div>
                                    </>
                                 ) : (
                                    <Meta
                                       title={media?.user?.username}
                                       description={moment(media?.createdAt).format(
                                          'DD/MM/yyyy, HH:mm'
                                       )}
                                    />
                                 )}
                                 <br />

                                 {media && media?.convoId && <Tag color="blue">Chat</Tag>}
                                 {media && media?.private && <Tag color="red">Private</Tag>}
                                 {media && media?.private === false && (
                                    <Tag color="green">Public</Tag>
                                 )}

                                 {media && media?.type?.includes('profile-image') && (
                                    <Tag color="pink">Profile Photo</Tag>
                                 )}
                                 {/* check box for profile picture */}
                                 <Checkbox
                                    key={media._id}
                                    id={`checkbox-${index}`}
                                    className="card-checkbox"
                                    style={{ float: 'right', transform: 'scale(1.5)' }}
                                    onClick={(e) => checkedMedia(e.target, media._id)}
                                    checked={selectedMediasArray.includes(media._id)}
                                 />
                              </div>

                              <div style={{ padding: '10px 0', position: 'relative' }}>
                                 {media && media?.type?.includes('video') && (
                                    <div>
                                       <Card
                                          className="video-container"
                                          onClick={() => showVideoModal(media)}>
                                          <RiVideoLine
                                             style={{
                                                color: 'white',
                                                fontSize: '90px',
                                                marginTop: '80px',
                                             }}
                                          />
                                       </Card>
                                    </div>
                                 )}
                                 {media && media?.type?.includes('image') && (
                                    <div className="img-wrapper">
                                       <Image
                                          src={handleMediaUrl(media)}
                                          fallback={fallBackImageBase64}
                                          placeholder={
                                             <div
                                                style={{
                                                   display: 'flex',
                                                   justifyContent: 'center',
                                                   alignItems: 'center',
                                                   height: '100%',
                                                }}>
                                                <Spin size="small" />
                                             </div>
                                          }
                                       />
                                    </div>
                                 )}
                                 {media && media?.type?.includes('Files') && (
                                    <MessageMedia content={media?.content} />
                                 )}
                              </div>
                              <Meta description={<ReadMore>{media}</ReadMore>} />
                           </Card>
                        </div>
                     ))
                  ) : (
                     <p>{isLoading ? 'Loading...' : 'No Data'}</p>
                  )}
               </div>
            )}

            {/* PAGINATION START */}

            {data && data?.totalPages ? (
               <div
                  className="pagination-container"
                  style={{ display: 'flex', justifyContent: 'center' }}>
                  <Form
                     onFinish={handleSubmitPage}
                     layout="inline"
                     style={{ alignItems: 'center' }}>
                     <Form.Item name="searchPage" style={{ marginRight: '10px', width: 100 }}>
                        <Input
                           placeholder="search page..."
                           value={pageNumber}
                           type="number"
                           max={data.totalPages}
                           min={1}
                           onChange={(e) => setPageNumber(e.target.value)}
                           allowClear
                        />
                     </Form.Item>
                     <Form.Item>
                        <Button type="primary" htmlType="submit">
                           Search Page
                        </Button>
                     </Form.Item>
                  </Form>
                  <ReactPaginate
                     forcePage={page - 1}
                     pageCount={data?.totalPages}
                     breakLabel="..."
                     previousLabel="<<"
                     nextLabel=">>"
                     containerClassName="pagination"
                     previousLinkClassName="pagination__link"
                     nextLinkClassName="pagination__link"
                     disabledClassName="pagination__link--disabled"
                     activeClassName="pagination__link--active"
                     marginPagesDisplayed={1}
                     pageRangeDisplayed={1}
                     onPageChange={handlePageClick}
                  />
               </div>
            ) : (
               ''
            )}

            {/* PAGINATION END */}

            {/* modal delete media confirmation - start */}
            <Modal
               title="Reject media"
               visible={isModalVisible}
               onOk={handleOk}
               onCancel={handleCancel}
               footer={[
                  <Button
                     key="back"
                     type="primary"
                     loading={loader}
                     onClick={() => {
                        if (deleteSelectedMediaType === 'all') {
                           handleRejectAllMedia()
                        } else {
                           handleBanMedia(deleteSelectedMediaId)
                        }
                     }}
                     style={{ background: 'green', color: 'white', borderStyle: 'none' }}>
                     Proceed
                  </Button>,
                  <Button
                     key="back"
                     onClick={handleOk}
                     type="primary"
                     style={{ background: 'red', color: 'white', borderStyle: 'none' }}>
                     Cancel
                  </Button>,
               ]}>
               <Alert
                  message="Warning"
                  description="You are about to reject selected media."
                  type="warning"
                  showIcon
               />
            </Modal>
            {/* modal delete media confirmation - end */}

            {/* modal video player - start */}
            <Modal
               title="Video"
               width={1000}
               visible={isVideoModalVisible}
               onCancel={handleCancelVideo}
               footer={[<Button onClick={handleCancelVideo}>Close</Button>]}>
               <VideoPlayer fsUrl={videoFileName} playing={play} />
            </Modal>
            {/* modal video player - end */}
         </div>
      </>
   )
}
