import React from 'react'
import styled from 'styled-components'
import EmailForm from '../EmailForm/EmailForm'

const Container = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100vh;
`

function NewsLetterPage() {
   return (
      <Container>
         <EmailForm />
      </Container>
   )
}

export default NewsLetterPage
