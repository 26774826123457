/* eslint-disable react/button-has-type */
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import './App.css'

import LayoutHeader from './components/LayoutHeader'
import IsAuthenticated from './components/IsAuthenticated'
import RequireAuth from './components/RequireAuth'

import { LoginPage } from './pages/Login/LoginPage/LoginPage'
import { UserPage } from './pages/User/UserPage'
import { LiveMediasPage } from './pages/LiveMedias'
import { DeletedMediasPage } from './pages/DeletedMedias'
import { ReportPage } from './pages/Report'
import { ModeratedPhotosPage } from './pages/ModeratedMedias/Photos'
import { ModeratedVideosPage } from './pages/ModeratedMedias/Videos'
import CustomMediasPage from './pages/CustomMedias'
import NewsLetterPage from './pages/NewsLetterEmail/NewsLetterPage/NewsLetterPage'
import { BusinessPage } from './pages/Business/BusinessPage'

function App() {
   return (
      <div>
         <Router>
            <Routes>
               <Route element={<LayoutHeader />}>
                  <Route
                     path="/"
                     element={
                        <IsAuthenticated>
                           <LoginPage />
                        </IsAuthenticated>
                     }
                  />
                  <Route
                     path="/login"
                     element={
                        <IsAuthenticated>
                           <LoginPage />
                        </IsAuthenticated>
                     }
                  />
                  <Route
                     path="/report"
                     element={
                        <RequireAuth>
                           <ReportPage />
                        </RequireAuth>
                     }
                  />
                  <Route
                     path="/live-medias"
                     element={
                        <RequireAuth>
                           <LiveMediasPage />
                        </RequireAuth>
                     }
                  />
                  <Route
                     path="/users"
                     element={
                        <RequireAuth>
                           <UserPage />
                        </RequireAuth>
                     }
                  />
                  <Route
                     path="/deleted-medias"
                     element={
                        <RequireAuth>
                           <DeletedMediasPage />
                        </RequireAuth>
                     }
                  />
                  <Route
                     path="/moderation-photos"
                     element={
                        <RequireAuth>
                           <ModeratedPhotosPage />
                        </RequireAuth>
                     }
                  />
                  <Route
                     path="/moderation-videos"
                     element={
                        <RequireAuth>
                           <ModeratedVideosPage />
                        </RequireAuth>
                     }
                  />
                  <Route
                     path="/custom-medias"
                     element={
                        <RequireAuth>
                           <CustomMediasPage />
                        </RequireAuth>
                     }
                  />
                  <Route
                     path="/newsletter-emails"
                     element={
                        <RequireAuth>
                           <NewsLetterPage />
                        </RequireAuth>
                     }
                  />
                  <Route
                     path="/business-page"
                     element={
                        <RequireAuth>
                           <BusinessPage />
                        </RequireAuth>
                     }
                  />
                  <Route
                     path="*"
                     element={
                        <main style={{ padding: '1rem' }}>
                           <p>Theres nothing here!</p>
                        </main>
                     }
                  />
               </Route>
            </Routes>
         </Router>
         <div className="page-footer" />
      </div>
   )
}

export default App
