import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { useForm, SubmitHandler } from 'react-hook-form'

import { useAuth } from '../../../context/authentication'
import { Card, Button, CardFieldset } from './styled'
import { TextInput } from '../../../components/forms/Input/Input'

export interface IFormInput {
   email: string
   password: string
}

export const LoginForm = () => {
   const navigate = useNavigate()
   const location = useLocation()
   const { login } = useAuth()

   const { register, handleSubmit } = useForm<IFormInput>({
      defaultValues: {
         email: '',
         password: '',
      },
   })

   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
   // @ts-ignore
   const from = location.state?.from?.pathname || '/'

   const onSubmit: SubmitHandler<IFormInput> = async (data) => {
      console.log('123')
      await login(data)

      // wait for login before navigate
      navigate('/', { replace: true })
   }

   return (
      <Card>
         <form onSubmit={handleSubmit(onSubmit)}>
            <CardFieldset>
               <TextInput placeholder="Email" {...register('email')} />
            </CardFieldset>

            <CardFieldset>
               <TextInput placeholder="Password" type="password" {...register('password')} />
            </CardFieldset>

            <CardFieldset>
               <Button type="submit" value="submit">
                  LOGIN
               </Button>
            </CardFieldset>
         </form>
      </Card>
   )
}
